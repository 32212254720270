import React, { useState, useEffect, Suspense, lazy } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  limit,
  collectionGroup,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";

import { db } from "../../firebase/config";
import BundleCard from "../../Components/Bundle/BundleCard";
import NewBundleCard from "../Bundle/NewBundleCard";
function Recents(props) {
  const { limitAmount } = props;

  const [bundles, setBundles] = useState([]);

  useEffect(() => {
    loadBundles();
  }, []);
  const loadBundles = async () => {
    const recents = query(
      collectionGroup(db, "bundles"),
      orderBy("updated", "desc"),
      limit(limitAmount)
    );

    const querySnapshot = await getDocs(recents);
    let bundleArr = [];
    querySnapshot.forEach((doc) => {
      bundleArr.push(doc.data());
    });
    setBundles(bundleArr);
  };

  return (
    <>
      <Row>
        
        {bundles.map((b) => {
          return (
            <Col key={b.bundleID} xs="12" md="6" xl="4">
              <NewBundleCard thisBundle={b} />
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default Recents;
