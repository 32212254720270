import React, { useState, useEffect } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { doc, setDoc, collection, getDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import Upload from "../Upload/Upload";

import Swal from "sweetalert2";

function NewCreatorForm() {
  let d = new Date();
  const [images, setImages] = useState([""]);
  const [tags, setTags] = useState([""]);
  const [nextYear, setNextYear] = useState(false);
  const [state, setState] = useState({
    creator: "",
    title: "",
    desc: "",
    thumb: "",
    patreon: "",
    mmf: "",
    website: "",
    date: d,
    price: '',
    month: d.getMonth() + 1,
  });

  const loadCreator = async () => {
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let creatorRef = doc(db, "creators", creatorUnderscored);
    const docSnap = await getDoc(creatorRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const { patreon, mmf, website,price } = data;
      setState({ ...state, patreon, mmf, website, price });
      console.log("Document data:", docSnap.data());
    } else {
      
        let oldDocRef = doc(db, "creators", state.creator.replace(" ", "_").toLowerCase()) 
        const old = await getDoc(oldDocRef);
        if (old.exists()) {
          let data = old.data();
          const { patreon, mmf, website, price } = data;
          setState({ ...state, patreon, mmf, website, price });
          console.log("Document data:", old.data());
        } else {
          console.log("No such document!");
        }
    }
  };

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
 
  const handleTagInput = (e, i) => {
    let tagArr = [...tags];
    let tag = e.target.value;
    tagArr[i] = tag;
    setTags(tagArr);
  };
  const deleteImage = (i) => {
    let imgArr = [...images];
    imgArr.splice(i, 1);
    setImages(imgArr);
  };
 
  
  const addCreator = async (e) => {
    e.preventDefault()
   
    
    // let creatorNoSpaces = state.creator.replace(" ", "");
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";

    
    
    
    let cleanedTags = tags.filter((i) => i !== "");
    
    let creatorsRef = collection(db, "creators");
    let creatorInfo = {
      creator: state.creator,
      patreon: state.patreon,
      mmf: state.mmf,
      website: state.website,
      price: state.price,
      tags: cleanedTags
    };
    await setDoc(doc(creatorsRef, creatorUnderscored), creatorInfo, {merge: true}).then(() => {
        Swal.fire({ icon: "success", timer: 1000, showConfirmButton: false });
        clearForm();
      });
    // console.log(bundleMonth, creatorNoSpaces, docId);
    
  };
  
  const clearForm = () => {
    setState({
      creator: "",
      title: "",
      desc: "",
      thumb: "",
      patreon: "",
      mmf: "",
      website: "",
      price: '',
      month: d.getMonth() + 1,
    });
    setImages([""]);
    setTags([""]);
  };
  

  return (
    <Form onSubmit={addCreator} className="bg-secondary p-3 text-light mt-2 rounded-3">
      <h5>Add a Creator</h5>
      <Form.Group>
        <Form.Label>Creator</Form.Label>
        <InputGroup>
          <Form.Control
            onChange={handleInput}
            value={state.creator}
            name="creator"
            type="text"
            placeholder="Capitalize First Letters"
            required
          />
          <InputGroup.Text>
            <Button onClick={() => loadCreator()}>Check</Button>
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.Label>Price</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.price}
          name="price"
          type="number"
          placeholder="0"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Patreon</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.patreon}
          name="patreon"
          type="text"
          placeholder="URL"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>My Mini Factory</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.mmf}
          name="mmf"
          type="text"
          placeholder="URL"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Website</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.website}
          name="website"
          type="text"
          placeholder="URL"
        />
      </Form.Group>
      <Form.Group className="border border-light rounded-3 p-1 my-1 ">
        <Form.Label>Tags</Form.Label>
        {tags.map((tag, i) => {
          return (
            <Form.Control
              onChange={(e) => handleTagInput(e, i)}
              className="my-1"
              key={i}
              type="text"
              value={tag}
              name={i}
              placeholder="tag"
            />
          );
        })}
        <Button
          variant="warning"
          className="btn-sm my-1"
          onClick={() => setTags([...tags, ""])}
        >
          Add Tag
        </Button>
      </Form.Group>
      
      <Button type='submit' className="m-2" >
        Add Creator
      </Button>
      <Button onClick={() => clearForm()} variant="danger" className="m-2">
        Clear
      </Button>
    </Form>
  );
}

export default NewCreatorForm;
