import React, { useEffect, useState } from "react";
import {
  Accordion,
  Container,
  Row,
  Col,
  Button,
  Navbar,
  Table,
  Alert,
} from "react-bootstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";
import TrackerBundles from "./TrackerBundles";

function Tracker(props) {
  const [creators, setCreators] = useState();
  const [cards, setCards] = useState(1);
  const [thisMonth, setThisMonth] = useState(0)
  const [nextMonth, setNextMonth] = useState(0)
  const [wp, setWP] = useState(0)
  const [show, setShow] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (props.publicMode && !creators) {
      loadCreators();
    }
  }, []);

  const loadCreators = async () => {
    setLoaded(false);
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];
    snapShot.forEach((doc) => {
      creatorArr.push(doc.data());
    });

    setCreators(creatorArr);
    setLoaded(true);
  };

  return (
    <div>
      {!props.publicMode ? (
        <Button onClick={() => loadCreators()}>Load</Button>
      ) : null}
      <Table striped bordered hover responsive size="sm" className="mt-3 mb-5">
        <tr>
          <th>Creator</th>
          <th>Welcome Pack </th>
          <th>This Month </th> 
          <th>Next Month </th>
        </tr>
        <tbody>
          {creators &&
            creators.map((c, i) => {
              return <TrackerBundles key={c.creatorID} setWP={setWP} wp={wp} tM={thisMonth} nM={nextMonth} setThisMonth={setThisMonth} setNextMonth={setNextMonth} creator={c} />;
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default Tracker;
