import React, { useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import ReactMarkdown from "react-markdown";
import ImageViewer from "react-simple-image-viewer";
function MMFPost(props) {
  const [expand, setExpand] = useState(false);
  const [viewer, setViewer] = useState(false);

  const { post } = props;
  console.log("post: ", post);
  let images
  if(post.extras && post.extras.gallery){

    images = post.extras.gallery.map(i =>  i.url)
  }
  return (
    <ListGroup.Item className="my-1 shadow rounded-3 p-3">
      {post.extras && post.extras.gallery ? (
        <img
        onClick={() => {
          setViewer(true)}}
          className="tribe-img"
          src={post.extras.gallery[0].url}
          alt="thumb"
          loading="lazy"
        />
      ) : null}
      {viewer ? (
          <ImageViewer
            className="image-viewer"
            src={images}
            closeOnClickOutside={true}
            onClose={() => setViewer(false)}
          />
        ) : null}
      <a target="_blank" rel="noreferrer" href={post.direct_link}>
        <h5 className="text-center">{post.title}</h5>
      </a>
      {post.body ? (
        <div className="d-flex flex-column align-items-center">
          <p className={expand ? "tribe-body-expanded" : "tribe-body"}>
            <ReactMarkdown>{post.body}</ReactMarkdown>
          </p>
          <Badge bg="info" className="btn" onClick={() => setExpand(!expand)}>
            {expand ? "Minimize" : "Expand"}
          </Badge>{" "}
        </div>
      ) : null}
    </ListGroup.Item>
  );
}

export default MMFPost;
