import React from "react";
import Container from "react-bootstrap/Container";

import {
  doc,
  setDoc,
  query,
  getDocs,
  where,
  collection,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { useState, useEffect } from "react";
function PageBanner(props) {
  const [banner, setBanner] = useState();

  useEffect(() => {
    loadBanner();
  }, []);

  const loadBanner = async () => {
    let bannerRef = collection(db, "banners");

    const bannerDoc = query(
      bannerRef,
      where("bannerNum", "==", props.bannerNum)
    );
    const querySnapshot = await getDocs(bannerDoc);
    let docData;
    querySnapshot.forEach((doc) => {
      docData = doc.data();
    });
    if (docData) {
      setBanner(docData);
    }
  };
  const logClick = async () => {
    let bannerRef = doc(db, "banners", banner.id);
    await setDoc(bannerRef, { clicks: (banner.clicks += 1) }, { merge: true });
  };
  return (
    <Container className="page-banner-cont">
      {banner ? (
        <a
          onClick={() => logClick()}
          href={banner.link}
          target="_blank"
          rel="noreferrer"
        >
          <img className="page-banner-img" src={banner.img} alt={banner.id} />
          <span className="text-light page-banner-text">
            {banner.creatorText}
          </span>
        </a>
      ) : null}
    </Container>
  );
}

export default PageBanner;
