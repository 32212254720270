import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";
import TagCreatorForm from "./TagCreatorForm";
function TagCreators() {
  const [creators, setCreators] = useState([]);
  const getCreators = async () => {
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];
    snapShot.forEach((doc) => {
      creatorArr.push(doc.data());
    });
    setCreators(creatorArr);
  };
  console.log("creators", creators);
  return (
    <Container>
      <Button onClick={() => getCreators()} variant="success" className="my-2">
        Tag Creators
      </Button>
      {creators.length > 0 &&
        creators.map((c) => {
            return (

                <TagCreatorForm key={c.creatorID} creator={c} />
                )
        })}
    </Container>
  );
}

export default TagCreators;
