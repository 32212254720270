import React, {useState, useContext} from 'react'
const UserContext = React.createContext()

export function useUser(){
    return useContext(UserContext)
}


function UserProvider({children}) {
    const [currentUser, setCurrentUser] = useState(
         {}
    )
    const [loading, setLoading] = useState(true)
        
    let value = {currentUser, setCurrentUser,loading,setLoading};

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider
