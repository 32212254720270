import React, { useEffect, useState } from "react";
import { Container, Spinner, Row, Accordion, Button } from "react-bootstrap";
import NewBundleForm from "./NewBundleForm";
import NewCreatorForm from "./NewCreatorForm";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../../firebase/config";
import CreatorBundleForm from "../Account/CreatorBundleForm";
import BatchScraper from "./BatchScraper";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Tracker from "../Tracker/Tracker";
import MyMiniFactoryScraper from "./MyMiniFactoryScraper";
import MMFCrawler from "./MMFCrawler";
import Crawler from "./Crawler";
import CoolStats from "./CoolStats";
import SocialMedia from "./SocialMedia";
import TagCreators from "./TagCreators";
import MergeCreators from "./MergeCreators";
function Admin() {
  const [authorized, setAuthorized] = useState(false);
  useEffect(() => {
    checkUser();
  }, []);
  let auth = getAuth();
  let navigate = useNavigate();
  const checkUser = () => {
    let usersRef = collection(db, "users");
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = query(usersRef, where("uid", "==", `${user.uid}`));
        const querySnapshot = await getDocs(userDoc);
        let docData;
        querySnapshot.forEach((doc) => {
          docData = doc.data();
        });
        if (docData.role !== "admin") {
          navigate("/");
        } else {
          setAuthorized(true);
        }
      } else {
        navigate("/");
      }
    });
  };
  const scroll = () => {
    window.scrollTo(0,0)
  }
  return (
    <Container className='position-relative'>
      {authorized ? (
        <>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>New Bundle</Accordion.Header>
              <Accordion.Body>
                <NewBundleForm />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Tracker</Accordion.Header>

              <Accordion.Body>
                <Tracker />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>New Creator</Accordion.Header>
              <Accordion.Body>
                <NewCreatorForm />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Creator Form</Accordion.Header>
              <Accordion.Body>
                <CreatorBundleForm creatorMode={true} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>MMF</Accordion.Header>
              <Accordion.Body>
                <MyMiniFactoryScraper />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header> Patreon Crawler</Accordion.Header>
              <Accordion.Body>
                <Crawler />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header> MMF Crawler</Accordion.Header>
              <Accordion.Body>
                <MMFCrawler />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Cool Stats</Accordion.Header>
              <Accordion.Body>
                <CoolStats />
                
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Batch Scraper</Accordion.Header>
              <Accordion.Body>
                <BatchScraper />
                
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>More Tools</Accordion.Header>
              <Accordion.Body>
                <SocialMedia />
                <TagCreators />
                <MergeCreators />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      ) : (
        <Row>
          <Spinner className="mx-auto" animation="grow" />
        </Row>
      )}
      <Button onClick={() => scroll()} className='fixed-bottom btn-orange m-2'>Top</Button>
    </Container>
  );
}

export default Admin;
