import React, { useState, useEffect } from "react";
import "./BundleCard.css";
import Container from "react-bootstrap/Container";
import ImageViewer from "react-simple-image-viewer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { doc, setDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useUser } from "../../UserContext";
import Swal from "sweetalert2";
import { getAnalytics, logEvent } from "firebase/analytics";

import { Link } from "react-router-dom";
let analytics = getAnalytics();
function BundleCard(props) {
  let user = useUser();
  const { thisBundle, creatorMode, searchMode, promoMode } = props;
  useEffect(() => {
    if (user.currentUser.saved) {
      if (user.currentUser.saved.includes(thisBundle.bundleID)) {
        setSaved(true);
      }
    }
    if (props.previewMode) {
      setLoaded(true);
    }
  }, []);
  const [descClicked, setDescClicked] = useState(false);
  const [viewer, setViewer] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [bundleSaves, setBundleSaves] = useState(thisBundle.saves || 0);
  const [bundlePClicks, setBundlePClicks] = useState(
    thisBundle.patreon_clicks || 0
  );
  const [bundleMClicks, setBundleMClicks] = useState(thisBundle.m_clicks || 0);
  const [bundleWClicks, setBundleWClicks] = useState(thisBundle.w_clicks || 0);
  const [bundlePreviewClicks, setBundlePreviewClicks] = useState(
    thisBundle.w_clicks || 0
  );
  const [saved, setSaved] = useState(false);
  let viewerImages = [];

  thisBundle.images.map((img) => {
    viewerImages.push({ src: img });
  });

  const saveBundle = async () => {
    if (user.currentUser.saved) {
      if (user.currentUser.saved.length < 1) {
        Swal.fire({
          position: "top-end",
          text: 'See your saved bundles by clicking "Saved Bundles".',
          timer: 2000,
          showConfirmButton: false,
        });
      }
      setSaved(true);
      const usersRef = collection(db, "users");
      let savedArr = user.currentUser.saved;
      savedArr = [...savedArr, thisBundle.bundleID];

      await setDoc(doc(usersRef, user.currentUser.uid), {
        ...user.currentUser,
        saved: savedArr,
      })
        .then(() => {
          user.setCurrentUser({ ...user.currentUser, saved: savedArr });
        })
        .catch((err) => {
          console.log(err);
          setSaved(false);
        });
      addSave();
    } else {
      Swal.fire({
        position: "top-end",
        text: "Sign in to save bundles for later.",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };
  const addSave = async () => {
    logEvent(analytics, "bundle_save", {
      creator: thisBundle.creator,
      bundleID: thisBundle.bundleID,
    });
    let creatorUnderscored = thisBundle.creator
      ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let saves = bundleSaves;
    saves += 1;
    let subCol = thisBundle.welcomePack ? "welcome_packs" : "bundles";

    const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
    const bundleData = {
      saves,
    };
    //
    await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
      merge: true,
    });
    setBundleSaves(saves);
  };

  const unSaveBundle = async () => {
    if (user.currentUser.saved) {
      setSaved(false);
      const usersRef = collection(db, "users");
      let savedArr = user.currentUser.saved;
      savedArr = savedArr.filter((b) => b !== thisBundle.bundleID);

      await setDoc(doc(usersRef, user.currentUser.uid), {
        ...user.currentUser,
        saved: savedArr,
      })
        .then(() => {
          user.setCurrentUser({ ...user.currentUser, saved: savedArr });
        })
        .catch((err) => {
          setSaved(true);
          console.log(err);
        });
      subtractSave();
    } else {
      Swal.fire({
        position: "top-end",
        text: "Sign in to save bundles for later.",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };
  const subtractSave = async () => {
    let creatorUnderscored = thisBundle.creator
      ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let saves = bundleSaves;
    if (saves != 0) {
      saves -= 1;
    }
    let subCol = thisBundle.welcomePack ? "welcome_packs" : "bundles";

    const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
    const bundleData = {
      saves,
    };
    setBundleSaves(saves);

    //
    await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
      merge: true,
    });
  };
  const addLinkClick = (site) => {
    logEvent(analytics, "link_click", {
      creator: thisBundle.creator,
      site: site,
      month: thisBundle.month,
      bundleID: thisBundle.bundleID,
    });
  };

  const addPClick = async () => {
    let creatorUnderscored = thisBundle.creator
      ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let patreon_clicks = bundlePClicks;
    patreon_clicks += 1;
    let subCol =
      thisBundle.welcomePack || thisBundle.wp ? "welcome_packs" : "bundles";

    const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
    const bundleData = {
      patreon_clicks,
    };
    //
    await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
      merge: true,
    });
    setBundlePClicks(patreon_clicks);
  };
  const addMClick = async () => {
    let creatorUnderscored = thisBundle.creator
      ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let m_clicks = bundleMClicks;
    m_clicks += 1;
    let subCol =
      thisBundle.welcomePack || thisBundle.wp ? "welcome_packs" : "bundles";

    const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
    const bundleData = {
      m_clicks,
    };
    //
    await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
      merge: true,
    });
    setBundleMClicks(m_clicks);
  };
  const addWClick = async () => {
    let creatorUnderscored = thisBundle.creator
      ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let w_clicks = bundleWClicks;
    w_clicks += 1;
    let subCol =
      thisBundle.welcomePack || thisBundle.wp ? "welcome_packs" : "bundles";

    const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
    const bundleData = {
      w_clicks,
    };
    //
    await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
      merge: true,
    });
    setBundleWClicks(w_clicks);
  };
  const previewClicked = async () => {
    if (!props.previewMode) {
      let creatorUnderscored = thisBundle.creator
        ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
        : "default";
      let preview_clicks = bundlePreviewClicks;
      preview_clicks += 1;
      let subCol =
        thisBundle.welcomePack || thisBundle.wp ? "welcome_packs" : "bundles";

      const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
      const bundleData = {
        preview_clicks,
      };
      logEvent(analytics, "preview_click", {
        creator: thisBundle.creator,
        month: thisBundle.month,
        bundleID: thisBundle.bundleID,
        date: new Date(),
      });
      //
      await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
        merge: true,
      });
      setBundlePreviewClicks(preview_clicks);
    }
  };

  return (
    <Container className=" text-center bundle-card border shadow rounded-3 p-3 my-2 d-flex flex-column justify-content-between">
      {thisBundle.promoted && promoMode && (
        // <div className="ribbon ribbon-top-left">
        <span>Promoted</span>
      ) }
      <img
        onClick={() => {
          previewClicked();
          setViewer(true);
        }}
        className={`carousel-img ${!loaded ? "invisible" : ""}`}
        src={thisBundle.thumb}
        onLoad={() => setLoaded(true)}
        loading="lazy"
        alt=""
        height="300px"
        width="300px"
      />
      {!loaded && (
        <div className="skeleton-1 rounded-3 skeleton-img"></div>
      ) }
      <>
        
        <div className="w-100 d-flex justify-content-between">
          {viewerImages.length > 1 ? (
            <u className="see-pics  " onClick={() => setViewer(true)}>
              See {viewerImages.length - 1} more high-res images
            </u>
          ) : viewerImages.length === 1 ? (
            <u className="see-pics  " onClick={() => setViewer(true)}>
              See high-res version
            </u>
          ) : null}
          {thisBundle.video ? (
            <u>
              <a href={thisBundle.video} target="_blank" rel="noreferrer">
                See Video
              </a>{" "}
            </u>
          ) : null}
          {!props.previewMode && !thisBundle.wp ? (
            saved ? (
              <i
                onClick={() => unSaveBundle()}
                className="bi bi-bookmark-star-fill h5 text-secondary save-button"
              ></i>
            ) : (
              !thisBundle.wp && (
                <i
                  onClick={() => saveBundle()}
                  className="bi bi-bookmark-star h5 text-secondary save-button"
                ></i>
              )
            )
          ) : null}
        </div>

        {viewer && (
          <ImageViewer
            className="image-viewer"
            src={thisBundle.images}
            closeOnClickOutside={true}
            onClose={() => setViewer(false)}
          />
        )}
        <Container className="my-1">
          <h3>
            <strong className="">
              {" "}
              <Link to={`/creator/${thisBundle.creator.replaceAll(" ", "_")}`}>
                {thisBundle.creator}
              </Link>
            </strong>
          </h3>
          <h4>
            <em>{thisBundle.title}</em>
          </h4>

          <p
            onClick={() => setDescClicked(!descClicked)}
            className={`body-text ${
              descClicked ? "card-desc-clicked" : "card-desc"
            }  `}
          >
            {thisBundle.desc}
          </p>
          {(thisBundle.date || thisBundle.updated) &&
          !props.previewMode &&
          (thisBundle.wp || thisBundle.welcomePack) ? (
            <p>
              As of:{" "}
              <em className="text-secondary">
                {thisBundle.date
                  ? thisBundle.date.toDate().toLocaleDateString()
                  : thisBundle.updated
                  ? thisBundle.updated.toDate().toLocaleDateString()
                  : thisBundle.month.replaceAll("_", "/")}
              </em>
            </p>
          ) : (
            ""
          )}
          {searchMode ? (
            <Row>
              <Col>
                {thisBundle.month
                  ? `Available: ${thisBundle.month.replace("_", "/")}`
                  : "Available as a welcome pack with a subscription."}
              </Col>
            </Row>
          ) : null}

          {creatorMode ? (
            <Row>
              <Col>
                {thisBundle.month
                  ? thisBundle.month.replace("_", "/")
                  : "Welcome Pack"}
              </Col>
            </Row>
          ) : (
            <>
              <Row className="border-top py-2">
                {thisBundle.price ? (
                  <h5 className="text-center">${thisBundle.price}</h5>
                ) : null}
                {thisBundle.patreon ? (
                  <Col className="p-0 m-0 ">
                    <a
                      onClick={() => {
                        addPClick();
                        addLinkClick("patreon");
                      }}
                      rel="noreferrer"
                      target="_blank"
                      href={thisBundle.patreon}
                    >
                      <Button
                        // onClick={() => addPClick()}
                        className="btn-sm btn-orange "
                      >
                        Patreon
                      </Button>
                    </a>
                  </Col>
                ) : null}
                {thisBundle.mmf ? (
                  <Col xs="6" className="p-0 m-0">
                    <a
                      onClick={() => {
                        addMClick();
                        addLinkClick("mmf");
                      }}
                      rel="noreferrer"
                      target="_blank"
                      href={thisBundle.mmf}
                    >
                      <Button className="btn-sm  btn-mmf ">
                        My Mini Factory
                      </Button>
                    </a>
                  </Col>
                ) : null}
                {thisBundle.website ? (
                  <Col className="p-0 m-0">
                    <a
                      onClick={() => {
                        addWClick();
                        addLinkClick("website");
                      }}
                      rel="noreferrer"
                      target="_blank"
                      href={thisBundle.website}
                    >
                      <Button className="btn-sm  btn-website ">Website</Button>
                    </a>
                  </Col>
                ) : null}
              </Row>
            </>
          )}
        </Container>
      </>
    </Container>
  );
}

export default BundleCard;
