import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {
  doc,
  setDoc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";

function GetCampaigns() {
  const loadCreators = async () => {
    // setLoaded(false);
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];
    snapShot.forEach((doc) => {
      creatorArr.push(doc.data());
    });
    let patreonsOnly = creatorArr.filter(
      (c) => c.patreon && c.patreon.length > 0
    );
    console.log("creators", creatorArr);
    console.log(creatorArr.length, "only patreons", patreonsOnly.length);
    // setCreators(patreonsOnly);

    getAllPosts(patreonsOnly);
    // setLoaded(true);
  };
  const getAllPosts = (arr) => {
    arr.map((c) => {
      getCampaign(c.creator);
    });
  };
  const getCampaign = (creator) => {
     axios.defaults.baseURL = 'https://dallinpoulson.com';

    axios.post("/scraper/campaign", { creator: creator }).then(async (res) => {
      console.log(res.data);
      if (res.data.data[0] && res.data.data[0].id) {
        let campaign = res.data.data[0].id.slice(9);
        console.log(campaign);
        let underscored = creator.toLowerCase().replaceAll(" ", "_");
        let creatorRef = doc(db, "creators", underscored);
        let docData = {
          campaign,
        };
        await setDoc(creatorRef, docData, { merge: true });
      }
    });
  };
  const getPosts = (campaign) => {
      let url = `https://www.patreon.com/api/posts?filter[campaign_id]=${campaign}&filter[contains_exclusive_posts]=false&sort=-published_at&json-api-version=1.0`
   
    return axios(url, {
        method: 'GET',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: 'same-origin',
      }).then(response => {
          console.log(response)
      })

    // axios.post("/scraper/posts", { campaign }).then((res) => {});
  };
  return (
    <div>
      <Button className="m-1" onClick={() => loadCreators()}>
        Get Campaigns
      </Button>
      <Button onClick={() => getPosts("4935605")}>Get Posts by Campaign</Button>
    </div>
  );
}

export default GetCampaigns;
