import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { getAnalytics, logEvent } from "firebase/analytics";

import {
  collection,
  doc,
  collectionGroup,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import BundleCard from "../Bundle/BundleCard";
import NewBundleCard from "../Bundle/NewBundleCard";

function LastMonth() {
  let analytics = getAnalytics()
  const d = new Date();
  let month = d.getMonth();
  let year = d.getFullYear();
  if (month === 0) {
    month = 12;
    year = 2021;
  }
  let queryMonth = month.toString() + "_" + year.toString();

  const [bundles, setBundles] = useState([]);
  const [show, setShow] = useState(false);
  useEffect(() => {
    loadBundles();
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'last-month'
    });
  }, []);
  const loadBundles = async () => {
    const lastMonth = query(
      collectionGroup(db, "bundles"),
      where("month", "==", queryMonth)
    );
  
    const querySnapshot = await getDocs(lastMonth);
    let bundleArr = [];
    querySnapshot.forEach((doc) => {
      bundleArr.push(doc.data());
    });
    setBundles(bundleArr);
    console.log('Number of Bundles: ', bundleArr.length)

  };

  return (
    <Container>
       <Row>
        {bundles.length > 0 ? (
          <h3 className=" text-center my-3">
            <b className="text-orange">{bundles.length} </b>bundle previews for
            last month!
          </h3>
        ) : null}
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs="12">
          {show ? (
            <Alert variant="info" dismissible onClose={() => setShow(false)}>
              <p>Some images may appear to be loading indefinitely. These images contain expired Patreon links and will not show here. </p>
              <p>We now upload our own photos and as of January 2022 there will be no more issues with expired images.</p>
            </Alert>
          ) : null}
        </Col>
      </Row>
      <Row>
        {bundles.map((b) => {
          return (
            <Col key={b.creator} xs="12" md="6" xl="4">
              <NewBundleCard thisBundle={b} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default LastMonth;
