import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
function MergeCreators() {
  const [loading, setLoading] = useState(false);
  const [creators, setCreators] = useState([]);
  const [keeper, setKeeper] = useState({ creator: "" });
  const [tosser, setTosser] = useState({ creator: "" });
  const updateCreatorID = async (creatorID) => {
    console.log("updating creator id", creatorID);
    await setDoc(
      doc(db, "creators", creatorID),
      { creatorID, creator: creatorID.replaceAll("_", " ") },
      { merge: true }
    );
  };
  const addNow = (creatorID) =>{
    let added = new Date()
    setDoc(doc(db,"creators",creatorID),{added}, {merge: true})
  }
  const capitalizeFirstLetters = (creatorInfo) => {
    let { creator } = creatorInfo;
    let split = creator.split(" ");
    let capped = split.map((word) => {
      if (!word) {
        return "";
      }
      let newWord = word;
      newWord = newWord[0].toUpperCase() + newWord.slice(1, word.length);
      return newWord;
    });
    console.log("capped", capped);
    let newCreator = capped.join(" ");
    setDoc(
      doc(db, "creators", creatorInfo.creatorID),
      { creator: newCreator },
      { merge: true }
    );
    console.log("Updated with: ", newCreator)
  };
  const loadCreators = async () => {
    setLoading(true);
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);

    let creatorArr = [];
    snapShot.forEach((c) => {
      console.log("c", c.id);
      if (!c.data().creatorID) {
        updateCreatorID(c.id);
      }
      let creatorInfo = { creatorID: c.id, ...c.data() };
    //   capitalizeFirstLetters(creatorInfo);
    // addNow(c.id)
      creatorArr.push(creatorInfo);
    });
    // let mmfOnly = creatorArr.filter((c) => c.mmf && c.mmf.length > 0);
    console.log("creators", creatorArr);
    setCreators(creatorArr);

    setLoading(false);
  };

  const merge = async () => {
    let keeperRef = doc(db, "creators", keeper.creatorID);
    let tosserRef = doc(db, "creators", tosser.creatorID);
    await setDoc(
      keeperRef,
      { ...tosser, creatorID: keeper.creatorID, creator: keeper.creator },
      { merge: true }
    );
    await deleteDoc(tosserRef);
    console.log("Updated");
    setKeeper({creator: ''})
    setTosser({creator: ''})
  };
  console.log("Keeper: ", keeper, "\n", "Tosser: ", tosser);
  return (
    <div>
      <u className="pointer" onClick={() => loadCreators()}>
        Merge Creators
      </u>
      {loading && <p>Getting Creators...</p>}
      <hr />
      <Button onClick={() => merge()}>Make Merge</Button>
      <Row>
        <Col>
          Creator to Keep: <b>{keeper.creator || keeper.mmfUsername}</b>
          <ListGroup style={{ height: "50vh", overflowY: "scroll" }}>
            {creators?.length > 0 &&
              creators.map((creator, index) => {
                return (
                  <ListGroup.Item
                    onClick={() => setKeeper(creator)}
                    className={
                      keeper === creator
                        ? "bg-primary text-light pointer"
                        : "pointer"
                    }
                    key={creator.creator_id || index}
                  >
                    {creator.creator
                      ? creator.creator
                      : "☠️" + creator.mmfUsername}
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Col>
        <Col>
          Creator to Get Rid Of: <b>{tosser.creator || tosser.mmfUsername}</b>
          <ListGroup style={{ height: "50vh", overflowY: "scroll" }}>
            {creators?.length > 0 &&
              creators.map((creator, index) => {
                return (
                  <ListGroup.Item
                    onClick={() => setTosser(creator)}
                    className={
                      tosser === creator ? "bg-info pointer" : "pointer"
                    }
                    key={creator.creator_id || index}
                  >
                    {creator.creator
                      ? creator.creator
                      : "☠️" + creator.mmfUsername}
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
}

export default MergeCreators;
