import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, Row, Col } from "react-bootstrap";
import { doc, setDoc, collection, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";
import Upload from "../Upload/Upload";
import UploadMultiple from "../Upload/UploadMultiple";
import Swal from "sweetalert2";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { useUser } from "../../UserContext";
import BundleCard from "../Bundle/BundleCard";

function CreatorBundleForm(props) {
  const { editMode, bundle, creator } = props;
  let d = new Date();
  let user = useUser();
  const [images, setImages] = useState([""]);
  const [tags, setTags] = useState(["fantasy"]);
  const [nextYear, setNextYear] = useState(false);
  const [bundleYear, setBundleYear] = useState(2022);
  const [state, setState] = useState({
    creator: "",
    title: "",
    desc: "",
    thumb: "",
    patreon: "",
    mmf: "",
    website: "",
    price: "",
    wp: false,
    month: d.getMonth() + 1,
  });
  useEffect(() => {
    if (props.creator) {
      setState({ ...state, creator: props.creator });
    }
    if (editMode) {
      let { creator, desc, title, month, patreon, mmf, price, thumb, website } =
        bundle;
      if (month) {
        let index = month.indexOf("_");
        let year = month.slice(index + 1);
        month = month.slice(0, index);
        setBundleYear(parseInt(year));
        setState({
          ...state,
          creator,
          desc,
          title,
          month,
          patreon,
          mmf,
          price,
          thumb,
          website,
         
        });
      } else {
        setState({
          ...state,
          creator,
          desc,
          title,
          patreon,
          mmf,
          price,
          thumb,
          website,
          wp: true
        });
      }
      setImages(bundle.images);
      setTags(bundle.tags);
    } else {
      loadCreator(props.creator);
    }
  }, []);
  const addUploadedImage = (url) => {
    if (images.length === 1) {
      setImages([url, ""]);
    } else {
      setImages([...images, url]);
    }
  };
  const loadCreator = async (creator) => {
    // if(props.creator){
    //   let creatorUnderscored = props.creator
    //   ? props.creator.replaceAll(" ", "_").toLowerCase()
    //   : "default";
    // } else {
    //   let creatorUnderscored = state.creator
    //   ? state.creator.replaceAll(" ", "_").toLowerCase()
    //   : "default";
    // }

    let creatorUnderscored = creator
      ? creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let creatorRef = doc(db, "creators", creatorUnderscored);
    const docSnap = await getDoc(creatorRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const { creator, patreon, mmf, website, price } = data;
      setState({ ...state, creator, patreon, mmf, website, price });
      console.log("Document data:", docSnap.data());
    } else {
      console.log("No such document.");
    }
  };

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleImageInput = (e, i) => {
    let imgArr = [...images];
    let img = e.target.value;
    imgArr[i] = img;
    setImages([...imgArr]);
  };

  const deleteImage = (i) => {
    let imgArr = [...images];
    imgArr.splice(i, 1);
    setImages(imgArr);
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleSubmit = (e) => {
    e.preventDefault();

    addBundle();
  };

  const addBundle = async () => {
    let bundleMonth = state.month.toString() + "_" + bundleYear.toString();
    // let creatorNoSpaces = state.creator.replace(" ", "");
    let creatorUnderscored = props.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";

    let docId;
    if (state.wp) {
      docId = creatorUnderscored + "_welcome_pack";
    } else {
      docId = creatorUnderscored + "_" + bundleMonth;
    }
    const bundlesRef = collection(
      db,
      "creator_bundle_requests",
      creatorUnderscored,
      "bundle_requests"
    );

    let cleanedImgs = images.filter((i) => i !== "");
    let cleanedTags = tags.filter((i) => i !== "");
    const bundleData = {
      ...state,
      month: bundleMonth,
      images: cleanedImgs,
      tags: cleanedTags,
      bundleID: docId,
      welcomePack: state.wp
      // updated: new Date(),
    };
    let creatorsRef = collection(db, "creators");
    let creatorInfo = {
      creator: state.creator,
      patreon: state.patreon,
      mmf: state.mmf,
      website: state.website,
      price: state.price,
    };

    await setDoc(doc(creatorsRef, creatorUnderscored), creatorInfo, {merge: true});
    //
    await setDoc(doc(bundlesRef, docId), bundleData, { merge: true }).then(
      () => {
        Swal.fire({
          icon: "success",
          timer: 5000,
          text: "Check back in a day or two to see the approved bundle.",
          showConfirmButton: true,
        });
        if (props.setModal) {
          props.setModal(false);
        }
        clearForm();
      }
    );
  };
  const setThumbUrl = (url) => {
    setState({ ...state, thumb: url });
  };
  const clearForm = () => {
    setState({
      ...state,
      title: "",
      desc: "",
      thumb: "",
      price: "",
      month: d.getMonth() + 1,
    });
    setImages([""]);
    setTags(["fantasy"]);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      className="bg-dark p-3 text-light mt-2 rounded-3"
    >
      <Row>
        <Form.Group as={Col} xs="12">
          <Form.Label>Creator</Form.Label>
          <InputGroup>
            <Form.Control
              disabled
              onChange={handleInput}
              value={state.creator}
              name="creator"
              type="text"
              placeholder="Capitalize First Letters"
              required
            />
          </InputGroup>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} xs="6">
          <Form.Label>
            Price - Lowest Tier for 3D files (not $1 general support tiers.)
          </Form.Label>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              required
              onChange={handleInput}
              value={state.price}
              name="price"
              type="text"
              
              placeholder="0 or more."
            />
          </InputGroup>
        </Form.Group>
      </Row>
      <Row>
        <Col className=" border border-light rounded-3 p-2 my-3 mx-2">
          <p>
            <em className="text-info">
              Links where users can sign up or get more information.
            </em>
          </p>
          <Form.Group>
            <Form.Label>Patreon URL</Form.Label>
            <Form.Control
              onChange={handleInput}
              value={state.patreon}
              name="patreon"
              type="text"
              placeholder="Must start with https://"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>My Mini Factory URL</Form.Label>
            <Form.Control
              onChange={handleInput}
              value={state.mmf}
              name="mmf"
              type="text"
              placeholder="Must start with https://"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Website or Other Useful Link URL</Form.Label>
            <Form.Control
              onChange={handleInput}
              value={state.website}
              name="website"
              type="text"
              placeholder="Must start with https://"
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <Form.Label>Welcome Pack {state.wp ? " - Yes" : " - No"}</Form.Label>
        <Form.Check
          name="wp"
          value={state.wp}
          checked={state.wp}
          onChange={() => setState({ ...state, wp: !state.wp })}
          placeholder=""
        />
      </Form.Group>
      <Row>
        <Form.Group as={Col} xs="6">
          <Form.Label className={state.wp ? "text-secondary" : ""}>
            Bundle Release Month
          </Form.Label>
          <Form.Select
            disabled={state.wp}
            onChange={handleInput}
            value={state.wp ? "--" : state.month}
            name="month"
            className={state.wp ? "bg-secondary border-secondary" : ""}
          >
            {months.map((m, i) => {
              return <option value={i + 1}>{m}</option>;
            })}
          </Form.Select>
        </Form.Group>
      </Row>
      <Form.Group>
        <Form.Label className={`my-1 me-3 ${state.wp ? "text-secondary" : ""}`}>
          Bundle Year
        </Form.Label>
        <Form.Check
          label="2021"
          disabled={state.wp}
          inline
          value="2021"
          className={state.wp ? "text-secondary" : ""}
          type="radio"
          checked={bundleYear === 2021 ? true : false}
          onClick={() => setBundleYear(2021)}
        />
        <Form.Check
          disabled={state.wp}
          label="2022"
          inline
          className={state.wp ? "text-secondary" : ""}
          value="2022"
          type="radio"
          checked={bundleYear === 2022 ? true : false}
          onClick={() => setBundleYear(2022)}
        />
        <Form.Check
          label="2023"
          disabled={state.wp}
          className={state.wp ? "text-secondary" : ""}
          inline
          value="2023"
          type="radio"
          checked={bundleYear === 2023 ? true : false}
          onClick={() => setBundleYear(2023)}
        />
      </Form.Group>

      <Form.Group className="border border-light rounded-3 p-1 ">

        <Form.Group>
          <Form.Label>Thumbnail</Form.Label>
          <p>
            <em className="text-info">Upload or paste image URL.</em>
          </p>
          <Row>
            <Col xs='6'>
          <Form.Control
           
           onChange={handleInput}
           className="my-1"
           type="text"
           value={state.thumb}
           name="thumb"
           required
           placeholder="https://img.url/here "
           />
           </Col>
            <Col xs='3'>
            <img className="img-preview" src={state.thumb === "" ? "http://www.proedsolutions.com/wp-content/themes/micron/images/placeholders/placeholder_small.jpg" : state.thumb} alt="" />

            </Col>
            </Row>
        </Form.Group>
        or
        <Upload setUrl={setThumbUrl} />
      </Form.Group>
      <Form.Group className="border border-light rounded-3 p-1 my-1 ">
        <Form.Label>Images</Form.Label>
        <p>
          <em className="text-info">
            Paste image URLs or upload them in a batch.
          </em>
        </p>
        <p>
          <em className="text-warning">
            Add as many images as you would like. They will appear in the order
            you paste/upload them in.
          </em>
        </p>
        <h5>
          <em
            className={
              images.length > 0 && images[0] !== ""
                ? "text-success"
                : "text-danger"
            }
          >
            The first one should match the thumbnail and be higher resolution if
            possible.
          </em>
        </h5>

        {images.map((img, i) => {
          return (
            <Row className="d-flex align-items-center">
              <Col xs="6">
                <Form.Control
                  onChange={(e) => handleImageInput(e, i)}
                  className="my-1"
                  key={i}
                  type="text"
                  value={img}
                  name={i}
                  placeholder="https://img.url/here"
                />
              </Col>
              <Col xs="3">
                <img className="img-preview" src={img === "" ? "http://www.proedsolutions.com/wp-content/themes/micron/images/placeholders/placeholder_small.jpg" : img} alt="" />
              </Col>
              <Col xs="3">
                <Button variant="danger" onClick={() => deleteImage(i)}>
                  X
                </Button>
              </Col>
            </Row>
          );
        })}

        <Button
          variant="warning"
          className="btn-sm my-1"
          onClick={() => setImages([...images, ""])}
        >
          Add Another Image
        </Button>
        <Form.Group>
          <Form.Label>Upload Images</Form.Label>
          <UploadMultiple
            creator={state.creator}
            month={state.month}
            setImages={setImages}
            images={images}
            setUrl={addUploadedImage}
          />
        </Form.Group>
      </Form.Group>

      <Form.Group className="border border-light rounded-3 p-1 my-1 ">
        <Form.Label>Tags</Form.Label>
        <p>
          <em className="text-info">
            Write down some tags to help users know what this bundle contains.
          </em>
        </p>
        <p>
          <em className="text-info">Make the word plural when possible.</em>
        </p>
        <p>
          <span className="text-warning">
            Examples: humans (press enter), orcs, halflings, terrain
          </span>
        </p>
        <Form.Group>
          <Form.Label className="me-3">Fantasy or Scifi: </Form.Label>
          <Form.Check
            label="Fantasy"
            inline
            value="2021"
            type="checkbox"
            checked={tags.includes("fantasy")}
            onClick={() => {
              if (!tags.includes("fantasy")) {
                setTags(["fantasy", ...tags]);
              } else {
                let tagArr = [...tags];
                let index = tagArr.indexOf("fantasy");
                tagArr.splice(index, 1);
                setTags(tagArr);
              }
            }}
          />
          <Form.Check
            label="Sci-Fi"
            inline
            value="2022"
            type="checkbox"
            checked={tags.includes("scifi")}
            onClick={() => {
              if (!tags.includes("scifi")) {
                setTags(["scifi", ...tags]);
              } else {
                let tagArr = [...tags];
                let index = tagArr.indexOf("scifi");
                tagArr.splice(index, 1);
                setTags(tagArr);
              }
            }}
          />
        </Form.Group>
        <ReactTagInput tags={tags} onChange={(newTags) => setTags(newTags)} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Title</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.title}
          name="title"
          type="text"
          required
          placeholder="Title of Bundle Here"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.desc}
          name="desc"
          as="textarea"
          placeholder="Optional Description"
          rows="2"
        />
      </Form.Group>
      <Button type="submit" className="m-2">
        Submit for Review
      </Button>
      <Button onClick={() => clearForm()} variant="danger" className="m-2">
        Clear
      </Button>
      <h4 className="text-center">Bundle Preview</h4>
      <Row className="d-flex justify-content-center">
        <Col xs="12" sm="9" className="bg-light">
          <BundleCard
            thisBundle={{ ...state, images, tags }}
            previewMode={true}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default CreatorBundleForm;
