import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../../firebase/config";
import { useNavigate } from "react-router-dom";
import { useUser } from "../.../../../UserContext";
import BundleCard from "../Bundle/BundleCard";

import {
  collection,
  collectionGroup,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import CreatorBundleForm from "./CreatorBundleForm";
import { Accordion, Modal } from "react-bootstrap";
import { getAnalytics, logEvent } from "firebase/analytics";

function Account() {
  let analytics = getAnalytics()
  // const [authorized, setAuthorized] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [bundles, setBundles] = useState();
  const [creatorBundles, setCreatorBundles] = useState();
  const [loaded, setLoaded] = useState(false);
  let userState = useUser();
  useEffect(() => {
    checkUser();
    loadBundles();
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'account'
    });
  }, []);
  useEffect(() => {
    loadBundles();
    if (userState.currentUser.creator) {
      loadCreatorBundles(userState.currentUser.creator);
    }
  }, [userState.currentUser]);

  let auth = getAuth();
  let user = useUser();
  let navigate = useNavigate();
  const loadCreatorBundles = async (creator) => {
    setLoaded(false);
    let creatorUnderscored = creator.replaceAll(" ", "_").toLowerCase();
    let bundlesRef = collection(db, "creators", creatorUnderscored, "bundles");
    let snapShot = await getDocs(bundlesRef);
    let bundleArr = [];
    snapShot.forEach((doc) => {
      bundleArr.push(doc.data());
    });
    let wpRef = collection(db, "creators", creatorUnderscored, "welcome_packs");
    let wSnapShot = await getDocs(wpRef);
    let wpBundleArr = [];
    wSnapShot.forEach((doc) => {
      wpBundleArr.push(doc.data());
    });
    setCreatorBundles([...bundleArr, ...wpBundleArr]);
    setLoaded(true);
  };
  const checkUser = () => {
    let usersRef = collection(db, "users");
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = query(usersRef, where("uid", "==", `${user.uid}`));
        const querySnapshot = await getDocs(userDoc);
        let docData;
        querySnapshot.forEach((doc) => {
          docData = doc.data();
        });
        if (docData) {
          userState.setCurrentUser(docData);
        }
        if (docData.role === "admin") {
          navigate("/admin");
        }
      } else {
        navigate("/");
      }
    });
  };
  const loadBundles = async () => {
    setLoaded(false);
    if (userState.currentUser.saved && userState.currentUser.saved.length > 0) {
      const savedBundles = query(
        collectionGroup(db, "bundles"),
        where("bundleID", "in", userState.currentUser.saved)
      );

      const querySnapshot = await getDocs(savedBundles);
      let bundleArr = [];
      querySnapshot.forEach((doc) => {
        bundleArr.push(doc.data());
      });
      setBundles(bundleArr);
      setLoaded(true);
    } else {
      console.log("No user.");
    }
  };
  const handleEditModal = (b) => {
    setSelectedBundle(b);
    setEditModal(true);
  };
  return (
    <Container>
      <Row className=" my-2 d-flex justify-content-between">
        <Col xs="12" sm="6">
          <h2 className="mt-4">
            Welcome, <b>{user.currentUser.displayName}</b>.
          </h2>
        </Col>
      </Row>
      
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>My Saved Bundles</Accordion.Header>
            <Accordion.Body>
              <Row>

              {loaded &&
                bundles &&
                bundles.map((b) => {
                  return (
                    <Col key={b.creator} xs="12" md="6" lg='4' >
                      <BundleCard thisBundle={b} />
                    </Col>
                  );
                })}
                </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
    
      {userState.currentUser.creator ? (
        <>
          <h3 className="my-3">
            You manage <b>{userState.currentUser.creator}</b>
          </h3>
          <Row>
            <Col>
              <Button onClick={() => setAddModal(true)}>Add Bundle</Button>
            </Col>
          </Row>
          <Modal show={addModal} onHide={() => setAddModal(false)}>
            <Modal.Header closeButton>
              <h5>Add a Bundle</h5>
            </Modal.Header>
            <CreatorBundleForm
              setModal={setAddModal}
              creator={userState.currentUser.creator}
            />
            <Modal.Footer>
              <Button variant="danger" onClick={() => setAddModal(false)}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <Row>
            <Col xs="12">
              <h4 className="my-3">Your current bundles:</h4>
            </Col>
            {creatorBundles &&
              creatorBundles.map((b) => {
                return (

                  <Col xs="12" md="6" xl="4" className="border rounded-3 p-2">
                    <div>

                    <Button
                    className='w-100 bg-light text-dark border-success '
                    onClick={() => handleEditModal(b)}
                   
                    >
                      Edit
                    </Button>
                      </div>
  
                    <BundleCard thisBundle={b} />
                  </Col>
                );
              })}
          </Row>
          <Modal show={editModal} onHide={() => setEditModal(false)}>
            <Modal.Header closeButton>
              <h5>Edit Bundle</h5>
            </Modal.Header>
            <CreatorBundleForm
              setModal={setEditModal}
              editMode={true}
              bundle={selectedBundle}
              creator={userState.currentUser.creator}
            />
            <Modal.Footer>
              <Button variant="danger" onClick={() => setEditModal(false)}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : null}
    </Container>
  );
}

export default Account;
