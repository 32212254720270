import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { db } from "../../firebase/config";
import { doc, setDoc } from "firebase/firestore";
import Swal from "sweetalert2";
function TagCreatorForm({ creator }) {
  const [tags, setTags] = useState([]);
  const [change, setChange ] = useState(false)
   const [dom, setDOM] = useState('') 
  console.log("creator form", creator.creator);
  useEffect(() => {
    if (creator.tags?.length > 0) {
      setTags(creator.tags);
    }
  }, [creator.creator]);
  let firstRender = useRef(true)
  useEffect(() => {
    if(change){
        submitTags()
    } else {

        console.log('not submitting')
    }

        //   submitTags()
        
  },[tags.length])
  const handleTagClick = (tag) => {
      if(!change){
          setChange(true)
      }
    let newTags = [...tags];
    if (tags.includes(tag)) {
      newTags = newTags.filter((t) => t !== tag);
    } else {
      newTags = [...newTags, tag];
    }
    setTags(newTags);
  };
const submitDOM = async (value) => {
  let creatorRef = doc(db, "creators", creator.creatorID)
  await setDoc(creatorRef, {approxDOM: value}, {merge: true}).then(res => {
      Swal.fire({icon: 'success', title: "",text: "", timer: 300, showConfirmButton: false })
      
  })
}
  const submitTags = async() => {
    let creatorRef = doc(db, "creators", creator.creatorID)
    await setDoc(creatorRef, {tags}, {merge: true}).then(res => {
        Swal.fire({icon: 'success', title: "",text: "", timer: 300, showConfirmButton: false })
        
    })
  }
  return (
    <div>
      <h6>{creator.creator}</h6>
      <div className="">
        <Badge
          className="mx-2 btn btn-sm"
          bg={tags.includes("fantasy") ? "success" : "secondary"}
          onClick={() => handleTagClick("fantasy")}
        >
          Fantasy
        </Badge>
        <Badge
          className="mx-2 btn btn-sm"
          bg={tags.includes("scifi") ? "success" : "secondary"}
          onClick={() => handleTagClick("scifi")}
        >
          Scifi
        </Badge>
        <Badge
          className="mx-2 btn btn-sm"
          bg={tags.includes("15mm or smaller") ? "success" : "secondary"}
          onClick={() => handleTagClick("15mm or smaller")}
        >
          15mm or smaller
        </Badge>
        <Badge
          className="mx-2 btn btn-sm"
          bg={tags.includes("terrain") ? "success" : "secondary"}
          onClick={() => handleTagClick("terrain")}
        >
          Terrain
        </Badge>
        <Badge
          className="mx-2 btn btn-sm"
          bg={tags.includes("historical") ? "success" : "secondary"}
          onClick={() => handleTagClick("historical")}
        >
          Historical
        </Badge>
        <Badge
          className="mx-2 btn btn-sm"
          bg={tags.includes("busts") ? "success" : "secondary"}
          onClick={() => handleTagClick("busts")}
        >
          Busts
        </Badge>
        <Badge
          className="mx-2 btn btn-sm"
          bg={tags.includes("presupported") ? "success" : "secondary"}
          onClick={() => handleTagClick("presupported")}
        >
          Presupported
        </Badge>
        <Badge
          className="mx-2 btn btn-sm"
          bg={tags.includes("support-free") ? "success" : "secondary"}
          onClick={() => handleTagClick("support-free")}
        >
          Support Free
        </Badge>
        <Badge
          className="mx-2 btn btn-sm"
          bg={tags.includes("free") ? "success" : "secondary"}
          onClick={() => handleTagClick("free")}
        >
          Free Option
        </Badge>
        <Badge
          className="mx-2 btn btn-sm"
          bg={tags.includes("backlog") ? "success" : "secondary"}
          onClick={() => handleTagClick("backlog")}
        >
          Whole Backlog
        </Badge>
        <Badge
          className="mx-2 btn btn-sm"
          bg={tags.includes("merchant") ? "success" : "secondary"}
          onClick={() => handleTagClick("merchant")}
        >
          Merchant Tier
        </Badge>
        <Form.Group>
         <Form.Label>DOM</Form.Label>
        <Form.Control style={{width: '50px'}}  type='text' name='dom' value={dom} onChange={(e) => setDOM(e.target.value)} onBlur={e => submitDOM(e.target.value)} placeholder=''/>
         </Form.Group>
      </div>
      <div>
        Tags:
        {tags.map((t) => {
          return <span key={t}>{t},</span>;
        })}
      </div>
      {/* <Button className="btn-sm" onClick={() => submitTags()}>Submit {creator.creator} tags</Button> */}
    </div>
  );
}

export default TagCreatorForm;
