import React, { useState } from "react";
import Progress from "./Progress";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function Upload(props) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const resetFile = (e) => {
    setFile(null)
  }
  const handleUpload = (e) => {
    let selected = e.target.files[0];
    if(selected){
        setFile(selected)
    } else {
        resetFile()
    }
  };

  return (
    <>
      <Form.Control
        // disabled={props.isDisabled}
        type="file"
        onChange={handleUpload}
      />
      {/* <Button onClick={handleUpload} className='bg-secondary mx-1'>UPLOAD</Button> */}
      <div className="output">
        {error && <div className="error">{error}</div>}
        {file && <div className="file">{file.name}</div>}
        {file && (
          <Progress resetFile={resetFile} setFileFn={setFile} setUrl={props.setUrl} file={file} />
        )}
      </div>
    </>
  );
}

export default Upload;
