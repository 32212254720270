import React, {useState, useEffect} from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import axios from 'axios'

function PatreonScrapeForm(props) {
     const [url, setUrl] = useState('') 
     const [waiting, setWaiting] = useState(false)
    //  axios.defaults.baseURL = 'https://dallinpoulson.com';
     useEffect(() => {
      if(props.url){
          setUrl(props.url)
      } 
      }, [])
     axios.defaults.baseURL = 'http://localhost:6543'
     const handleRequest = (e) => {
         e.preventDefault()
         setWaiting(true)
         let post = url
         axios.post('/scraper/patreon/post/data', {post, creatorName: props.creator} ).then(res => {
             props.setPatreon(res.data)
            console.log(props.creator, 'images added')
             setWaiting(false)
            }).catch(err => {
             setWaiting(false)
             console.log('err',props.creator, props.url )
            })
        }
    //  console.log('url', url)
    return (
        <Form onSubmit={handleRequest}>
            <Form.Group>
             <Form.Label>Post URL</Form.Label>
            <Form.Control  type='text' name='url' value={url} onChange={(e) => setUrl(e.target.value)} placeholder='https://patreon.com/post/123'/>
            {waiting ? <Spinner animation='grow' /> : null}
             </Form.Group>
             <Row>

             <a target='_blank' rel='noreferrer' href={url}>Go to Post</a>
             </Row>
             <Button className='me-4 my-2' variant='dark' type='submit'>Get Data</Button>
             <Button onClick={() => props.setAddImages(!props.addImages)} variant={props.addImages ? 'info' : 'warning'} >{props.addImages ? "Don't Append Images" : " Append Images"}</Button>
        </Form>
    )
}

export default PatreonScrapeForm
