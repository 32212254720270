import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import axios from "axios";
import {
  doc,
  setDoc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import MMFPost from "./MMFPost";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";

function MMFPosts(props) {
  useEffect(() => {
    if (props.tribeID) {
      getPosts(props.tribeID);
    }
  }, []);
  //   console.log(props)
  const [posts, setPosts] = useState([]);
  const getPosts = (tribeID) => {
    axios
      .get(`https://www.myminifactory.com/tribes/${tribeID}/posts`)
      .then((res) => {
        setPosts(res.data);
      }).catch(err => {
        console.log('No tribe for: ', tribeID)
        console.log('err: ', err)
      })
  };
  return (
    <Container>
      <h5 className=" mmf-posts text-light rounded-3 d-flex justify-content-center align-items-center my-1" >
        Tribes Posts
      </h5>
      {posts.length > 0 ? (
       posts
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .map((p) => {
          return (
            <ListGroup key={p.id}>
                <MMFPost post={p} />
              </ListGroup>
            );
          })
         
          ) : (

            <Container className='d-flex justify-content-center'><Spinner  animation='grow'/></Container> 
            )}
    </Container>
  );
}

export default MMFPosts;
