import React, { useEffect, useRef, useState } from "react";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";

import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";
import CreatorBundles from "./CreatorBundles";
import { Link } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import CreatorPage from "./CreatorPage";
import PageBanner from "../Banner/PageBanner";
import "./Creators.css";
function Creators() {
  const [creators, setCreators] = useState();
  const [filteredCreators, setFilteredCreators] = useState([]);
  const [cards, setCards] = useState(1);
  const [show, setShow] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [search, setSearch] = useState("");
  const analytics = getAnalytics();
  const [activeCreator, setActiveCreator] = useState("");
  const [sortMethod, setSortMethod] = useState("sup-hi");
  const [coolStats, setCoolStats] = useState();
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    loadCreators();
    loadCoolStats();
    logEvent(analytics, "screen_view", {
      firebase_screen: "creators",
    });
  }, []);
  useEffect(() => {
    if (creators) {
      filterBySearch();
    }
  }, [search, creators]);

  // console.log("filterd", filteredCreators);
  const filterByTag = () => {
    if (selectedTags.length > 0 && creators) {
      let filtered = creators.filter((b) => {
        console.log('b',b)
        return selectedTags.every((t) => {
          console.log('t',t)
          return b.tags.includes(t.toLowerCase());
        });
      });
      if (filtered.length < 1) {
        setSelectedTags([]);
        setFilteredCreators(creators);
        return;
      }
      setFilteredCreators(filtered);
    } else {
      setFilteredCreators(creators);
      // setFilteredBundles([]);
    }
  };

  const handleTagClick = (tag) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      let removed = selectedTags.filter((st) => st !== tag);
      setSelectedTags(removed);
    }
  };
  let firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      filterByTag();
    }
  }, [selectedTags.length, creators]);
  useEffect(() => {
    if (creators && filteredCreators && filteredCreators?.length > 0) {
      sort();
      // console.log("sorting");
    }
  }, [sortMethod, filteredCreators?.length]);
  const sort = () => {
    // console.log(sortMethod);
    let sortArr = [...filteredCreators];
    switch (sortMethod) {
      case "sup-hi":
        sortArr = sortArr.sort((a, b) => b.subscribers - a.subscribers);

        setFilteredCreators(sortArr);
        break;
      case "sup-low":
        sortArr = sortArr.sort((a, b) => a.subscribers - b.subscribers);

        setFilteredCreators(sortArr);
        break;
      case "a":
        sortArr = sortArr.sort((a, b) => a.creator?.localeCompare(b.creator));

        setFilteredCreators(sortArr);
        break;
      case "z":
        sortArr = sortArr.sort((a, b) => b.creator?.localeCompare(a.creator));

        setFilteredCreators(sortArr);
        break;
      case "low":
        let undef = sortArr.filter((s) => (s.price ? false : true));

        sortArr = sortArr
          .filter((s) => (s.price ? true : false))
          .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

        setFilteredCreators([...sortArr, ...undef]);

        break;
      case "high":
        sortArr = sortArr.sort(
          (a, b) => parseFloat(b.price) - parseFloat(a.price)
        );

        setFilteredCreators(sortArr);
        break;

      default:
        sortArr = sortArr.sort((a, b) => a.creator?.localeCompare(b.creator));

        setFilteredCreators(sortArr);
        break;
    }
  };
  const loadCoolStats = async () => {
    const docSnap = await getDoc(doc(db, "cool_stats", "cool_stats"));
    if (docSnap.exists()) {
      let data = docSnap.data();
      setCoolStats(data);
    } else {
      console.log("No such document!");
    }
  };
  const loadCreators = async () => {
    setLoaded(false);
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];
    snapShot.forEach((doc) => {
      let c = doc.data();
      let total = 0;

      if (c.patrons) {
        total = c.patrons;
        if (c.tribeData && c.tribeData.total_tribe_subscriptions) {
          total += c.tribeData.total_tribe_subscriptions;
        }
      } else if (c.tribeData && c.tribeData.total_tribe_subscriptions) {
        total = c.tribeData.total_tribe_subscriptions;
      }
      let price;
      if (!c.price) {
        price = -1;
      } else {
        price = c.price;
      }
      creatorArr.push({ ...doc.data(), subscribers: total, price: price });
    });
    creatorArr = creatorArr.sort((a, b) => b.subscribers - a.subscribers);
    setCreators(creatorArr);
    console.log(
      "Number of Creators: ",
      creatorArr.length,
      creatorArr,
      creatorArr.filter((c) => !c.creator),
      creatorArr.filter((c) => !c.creatorID)
    );
    filterByTag();
    setLoaded(true);
  };
  console.log(selectedTags);
  const filterBySearch = () => {
    let filterArr = [...creators];
    if (
      creators &&
      filteredCreators &&
      filteredCreators.length < 1 &&
      search.length < 1
    ) {
      setFilteredCreators(creators);
    } else {
      let filtered = filterArr.filter((c) => {
        if (c.creator) {
          return c.creator.toLowerCase().includes(search.toLowerCase());
        }
        console.log("no creator", c);
        return false;
      });
      setFilteredCreators(filtered);
    }
  };
  const handleClick = (c) => {
    if (activeCreator !== c.creator) {
      setActiveCreator(c.creator);
    } else {
      setActiveCreator();
    }
  };

  const SkeletonArr = () => {
    let skelArr = [];
    for (let index = 0; index < 20; index++) {
      skelArr.push(
        <Row key={index} className="skeleton-row my-1 mx-0 rounded-3">
          <Col>
            <div className="skeleton-bar my-3 ms-3 rounded-3"></div>
          </Col>
          <Col>
            <div className="text-end h-100 d-flex justify-content-end align-items-center">
              <i className="bi bi-chevron-down chevron"></i>
            </div>
          </Col>
        </Row>
      );
    }
    return skelArr;
  };

  return (
    <Container>
      {" "}
      <Row className="d-flex justify-content-center">
        <PageBanner bannerNum={1} />
      </Row>
      <Row>
        <Col xs="12" className="d-flex justify-content-end my-2">
          <h5 className="mx-2">
            <Link to="/creator">Are you a creator?</Link>
          </h5>
          <h5>
            <Link to="/suggest">Suggest a creator.</Link>
          </h5>
        </Col>
      </Row>
      {coolStats && (
        <Row>
          {/* <Col>
            <h4 className="text-center">
              <b className="text-success">
                {Math.floor(coolStats.market_cap).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                })}
                +
              </b>{" "}
              spent on bundles this month through{" "}
              <b className="text-primary">
                {Math.floor(coolStats.sub_count).toLocaleString()}
              </b>{" "}
              subscriptions.
            </h4>
          </Col> */}
          <Col className='text-center ' style={{fontSize: '20pt'}}><b>Subscriber counts are no longer accurate due to Patreon counting non-paying followers as Patrons.</b></Col>
        </Row>
      )}
      <Row className="my-2 d-flex justify-content-center">
        <Col className="text-center" xs="12" lg="4">
          {creators ? (
            <h3 className=" my-0">
              <b className="text-orange">{creators.length} </b>creators and
              counting!
            </h3>
          ) : null}
        </Col>

        <Form.Group as={Col} xs="10" sm="7" lg="4" className="mb-2">
          <Form.Control
            autoComplete="off"
            type="text"
            name="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Type a creator's name"
          />
        </Form.Group>
        <Col xs="10" sm="7" lg="4" className="">
          <Form.Select
            value={sortMethod}
            onChange={(e) => setSortMethod(e.target.value)}
          >
            <option value="a">A - Z</option>
            <option value="z">Z - A</option>
            <option value="sup-hi">Subscribers - Most to Least</option>
            <option value="sup-low">Subscribers - Least to Most</option>
            <option value="low">$ Low to High</option>
            <option value="high">$ High to Low</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="justify-content-between">
        <h5>Filter by Tag:</h5>
        <Col xs="12" lg="auto">
          <Button
            className={` btn ${
              selectedTags.includes("fantasy")
                ? "bg-primary text-light"
                : "bg-light text-dark"
            }  border m-1  px-5`}
            onClick={() => handleTagClick("fantasy")}
          >
            FANTASY
          </Button>
        </Col>
        <Col xs="12" lg="auto">
          <Button
            className={` btn ${
              selectedTags.includes("scifi")
                ? "bg-primary text-light"
                : "bg-light text-dark"
            }  border m-1 px-5`}
            onClick={() => handleTagClick("scifi")}
          >
            SCI-FI
          </Button>
        </Col>

        <Col xs="12" lg="auto">
          <Button
            className={` btn ${
              selectedTags.includes("15mm or smaller")
                ? "bg-primary text-light"
                : "bg-light text-dark"
            }  border m-1 px-5`}
            onClick={() => handleTagClick("15mm or smaller")}
          >
            15MM OR SMALLER
          </Button>
        </Col>
        <Col xs="12" lg="auto">
          <Button
            className={` btn ${
              selectedTags.includes("terrain")
                ? "bg-primary text-light"
                : "bg-light text-dark"
            }  border m-1  px-5`}
            onClick={() => handleTagClick("terrain")}
          >
            TERRAIN
          </Button>
        </Col>
        <Col xs="12" lg="auto">
          <Button
            className={` btn ${
              selectedTags.includes("historical")
                ? "bg-primary text-light"
                : "bg-light text-dark"
            }  border m-1  px-5`}
            onClick={() => handleTagClick("historical")}
          >
            Historical
          </Button>
        </Col>
      </Row>
      {loaded && creators[0] && filteredCreators.length > 0 ? (
        filteredCreators.map((c, i) => {
          return (
            <div key={c.creatorID || i} className="p-2 border rounded-3 pointer">
              <Row
                creator={c}
                onClick={(e) => {
                  handleClick(c);
                }}
              >
                <Col
                  xs="2"
                  lg="1"
                  className="d-flex align-items-center justify-content-center"
                >
                  <img
                    src={
                      c.smallLogo
                        ? c.smallLogo
                        : c.patreonData &&
                          c.patreonData.attributes &&
                          c.patreonData.attributes.avatar_photo_url
                        ? c.patreonData.attributes.avatar_photo_url
                        : ""
                    }
                    alt=""
                    className="creator-thumb"
                    loading="lazy"
                  />
                </Col>
                <Col>
                  <Row>
                    <h4 className="m-0">
                      {" "}
                      <Link to={`/creator/${c.creatorID?.toUpperCase()}/`}>
                        {c.creator}{" "}
                      </Link>
                    </h4>

                    <Col>
                      {c.subscribers < 1 ? (
                        "--"
                      ) : (
                        <b>{c.subscribers} subscribers</b>
                      )}
                      <p className="m-0">
                        {c.price > -1 ? (
                          <b className="  text-success ">${c.price}</b>
                        ) : (
                          <b className="text-success"> $ coming soon</b>
                        )}
                      </p>
                    </Col>
                    <Col xs="3" className="d-flex flex-column align-items-end">
                      {" "}
                      {c.patreon && (
                        <img
                          height="20"
                          width="20"
                          className="ms-2 mb-1 "
                          src="/images/patreonlogo.png"
                          alt=""
                          loading="lazy"
                        />
                      )}{" "}
                      {c.tribeID && (
                        <img
                          height="20"
                          width="60"
                          className="ms-2  "
                          src="/images/TribesLogo.png"
                          alt=""
                          loading="lazy"
                        />
                      )}
                    </Col>
                    <Col xs="2" lg="1">
                      <div className="text-end h-100 d-flex justify-content-end align-items-center">
                        <i
                          className={`bi bi-chevron-${
                            activeCreator === c.creator ? "up" : "down"
                          } chevron`}
                        ></i>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Collapse in={activeCreator === c.creator}>
                <Container>
                  {activeCreator === c.creator ? (
                    <CreatorBundles creator={c} />
                  ) : null}
                </Container>
              </Collapse>
            </div>
          );
        })
      ) : (
        <SkeletonArr />
      )}
    </Container>
  );
}

export default Creators;
