import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, Row, Col, Badge } from "react-bootstrap";
import {
  doc,
  setDoc,
  collection,
  getDoc,
  getDocs,
  collectionGroup,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import Upload from "../Upload/Upload";
import UploadMultiple from "../Upload/UploadMultiple";
import Swal from "sweetalert2";
import BundleCard from "../Bundle/BundleCard";
import PatreonScrapeForm from "./PatreonScrapeForm";
import MyMiniFactoryScraper from "./MyMiniFactoryScraper";
import ReactTagInput from "@pathofdev/react-tag-input";
import ProgressMultiple from "../Upload/ProgressMultiple";

import axios from "axios";
function NewBundleForm(props) {
  let d = new Date();
  const [images, setImages] = useState([""]);
  const [tags, setTags] = useState(["fantasy"]);
  const [bundleYear, setBundleYear] = useState(2022);
  const [nextYear, setNextYear] = useState(false);
  const [bundles, setBundles] = useState([]);
  const [patreon, setPatreon] = useState({ images: [] });
  const [addImages, setAddImages] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const [state, setState] = useState({
    creator: "",
    title: "",
    desc: "",
    thumb: "",
    patreon: "",
    mmf: "",
    website: "",
    video: "",
    price: "",
    date: d,
    month: d.getMonth() + 1,
    campaign: "",
    tribeID: "",
  });
  useEffect(() => {
    if (patreon.images.length > 0) {
      if (addImages) {
        let newImgs = [];
        patreon.images.map((i) => {
          if (!images.includes(i) && i !== "") {
            newImgs.push(i);
          }
        });
        let imgArr = [...images, ...newImgs];
        setImages(imgArr);
      } else {
        setImages(patreon.images);
        const { title, desc, thumbNail, patreonLink, video, videoHTML } =
          patreon;
        setState({
          ...state,
          title,
          // desc,
          video,

          thumb: thumbNail,
          // patreon: patreonLink,
        });

        // loadCreator(creator)
      }
    } else {
      const { title, desc, thumbNail, patreonLink } = patreon;
      setState({
        ...state,
        title,
        // desc,

        thumb: thumbNail,
        // patreon: patreonLink,
      });
    }
  }, [patreon]);

  useEffect(() => {
    if (props.bundle) {
      setState({ ...state, creator: props.bundle.creator });
      if (props.callScrape) {
        // scrapePost(props.bundle)
      }
    }
    if (props.mBundle) {
      let b = props.mBundle;
      if (b.extras && b.extras.gallery) {
        let mImages = b.extras.gallery.map((img) => img.url);
        setImages(mImages);
      }

      setState({
        ...state,
        title: b.title,
        creator: b.creator.replaceAll("%20", " "),
        thumb:
          b.extras && b.extras.gallery
            ? `https://images.myminifactory.com/image/height=500,v=3/${b.extras.gallery[0].url}`
            : "",
      });
    }
  }, []);
  const scrapePost = (b) => {
    const { url, creator } = b;
    setWaiting(true);
    axios
      .post("/scraper/patreon/post", { url, creatorName: creator })
      .then((res) => {
        props.setPatreon(res.data);
        console.log(props.creator, "images added");
        setWaiting(false);
      })
      .catch((err) => {
        console.log("err", props.creator, props.url);
      });
  };
  console.log("state", state);
  const loadCreator = async (creator) => {
    if (state.creator.length < 1 || undefined || null) {
      creator = "default";
    } else if (creator.length < 1 || undefined || null) {
      creator = state.creator;
    }

    let creatorUnderscored = creator.replaceAll(" ", "_").toLowerCase();

    let creatorRef = doc(db, "creators", creatorUnderscored);
    console.log("creatorunderscored", creatorUnderscored);
    const docSnap = await getDoc(creatorRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const { patreon, mmf, website, price, campaign, tribeID, creatorID } =
        data;
      setState({
        ...state,
        patreon: patreon || "",
        mmf: mmf || "",
        website: website || "",
        price: price || "",
        campaign: campaign || "",
        tribeID: tribeID || "",
        creatorID: creatorID || creator.toLowerCase().replaceAll(" ", "_"),
      });
      console.log("Document data:", docSnap.data());
    }
    //  else {
    //   let oldUnderscore = state.creator.replace(" ", "_").toLowerCase();

    //   let oldDocRef = doc(db, "creators", oldUnderscore);
    //   const old = await getDoc(oldDocRef);
    //   if (old.exists()) {
    //     let data = old.data();
    //     const { patreon, mmf, website, price } = data;
    //     setState({ ...state, patreon, mmf, website, price });
    //   } else {
    //     console.log("No such document!");
    //   }
    // }
  };
  const resetPatreon = () => {
    setPatreon({ images: [] });
  };
  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleImageInput = (e, i) => {
    let imgArr = [...images];
    let img = e.target.value;
    imgArr[i] = img;
    setImages([...imgArr, ""]);
  };
  const handleTagInput = (e, i) => {
    let tagArr = [...tags];
    let tag = e.target.value;
    tagArr[i] = tag;
    setTags(tagArr);
  };
  const deleteImage = (i) => {
    let imgArr = [...images];
    imgArr.splice(i, 1);
    setImages(imgArr);
  };
  let year = d.getFullYear();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const addBundle = async (e) => {
    e.preventDefault();

    let bundleMonth = state.month.toString() + "_" + bundleYear.toString();
    // let creatorNoSpaces = state.creator.replace(" ", "");
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase().trim()
      : "default";

    let docId;
    let bundlesRef;
    if (state.wp) {
      docId = creatorUnderscored + "_welcome_pack";
      bundlesRef = collection(
        db,
        "creators",
        creatorUnderscored,
        "welcome_packs"
      );
    } else {
      docId = creatorUnderscored + "_" + bundleMonth;
      bundlesRef = collection(db, "creators", creatorUnderscored, "bundles");
    }
    // let thisMonth = false;
    // let nextMonth = false;
    // if(state.month === d.getMonth() + 1){
    //   thisMonth = true;
    // }
    // if(state.month === d.getMonth() + 2){
    //   thisMonth = true;
    // }
    // if(state.month === d.getMonth() + 1){
    //   thisMonth = true;
    // }
    let cleanedImgs = images.filter((i) => i !== "");
    let cleanedTags = tags.filter((i) => i !== "");
    const bundleData = {
      ...state,

      month: bundleMonth,
      images: cleanedImgs,
      tags: cleanedTags,
      bundleID: docId,
      updated: new Date(),
      desc: ' '
    };
    let creatorsRef = collection(db, "creators");
    let creatorInfo = {
      creator: state.creator,
      patreon: state.patreon,
      mmf: state.mmf,
      website: state.website,
      price: state.price,
      creatorID:
        state.creatorID || state.creator.toLowerCase().replaceAll(" ", "_"),
      tags: tags || [],
    };
    if (state.campaign && state.tribeID) {
      creatorInfo = {
        ...creatorInfo,
        campaign: state.campaign,
        tribeID: state.tribeID,
      };
    } else if (state.campaign && !state.tribeID) {
      creatorInfo = { ...creatorInfo, campaign: state.campaign };
    } else if (state.tribeID && !state.campaign) {
      creatorInfo = { ...creatorInfo, tribeID: state.tribeID };
    }
    await setDoc(doc(creatorsRef, creatorUnderscored), creatorInfo, {
      merge: true,
    });
    // console.log(bundleMonth, creatorNoSpaces, docId);
    await setDoc(doc(bundlesRef, docId), bundleData, { merge: true }).then(
      () => {
        Swal.fire({ icon: "success", timer: 1000, showConfirmButton: false });
        if (props.removeUrl) {
          if (props.bundle) {
            props.removeUrl(props.bundle);
          }
          if (props.mBundle) {
            props.removeUrl(props.mBundle);
          }
        }
        clearForm();
      }
    );
  };
  const addUploadedImage = (url) => {
    if (images.length === 1) {
      setImages([url, ""]);
    } else {
      setImages([...images, url]);
    }
  };
  const clearForm = () => {
    setState({
      creator: "",
      title: "",
      desc: "",
      thumb: "",
      patreon: "",
      mmf: "",
      video: "",
      website: "",
      price: "",
      month: d.getMonth() + 1,
      campaign: "",
      tribeID: "",
    });
    resetPatreon();
    setImages([""]);
    setTags([""]);
  };
  const setThumbUrl = (url) => {
    setState({ ...state, thumb: url });
  };
  const retreiveBundle = async () => {
    // if (nextYear) {
    //   year += 1;
    // }
    let bundleMonth = state.month.toString() + "_" + bundleYear.toString();
    console.log("bundllemonth", bundleMonth);
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";

    let docId = creatorUnderscored + "_" + bundleMonth;
    let bundleRef = doc(db, "creators", creatorUnderscored, "bundles", docId);
    const docSnap = await getDoc(bundleRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const {
        creator,
        patreon,
        mmf,
        website,
        desc,
        title,
        thumb,
        images,
        tags,
        price,
        video,
      } = data;
      setState({
        ...state,
        patreon,
        mmf,
        website: website ? website : "",
        desc,
        title,
        thumb,
        video: video || "",
        price,
      });
      setImages(images);
      setTags(tags);
      console.log("Document data:", docSnap.data());
    } else {
      let oldCreatorUnderscored = state.creator.replace(" ", "_").toLowerCase();
      let oldDocId = oldCreatorUnderscored + "_" + bundleMonth;
      let oldDocRef = doc(
        db,
        "creators",
        oldCreatorUnderscored,
        "bundles",
        oldDocId
      );
      const old = await getDoc(oldDocRef);
      if (old.exists()) {
        let data = old.data();
        const {
          creator,
          patreon,
          mmf,
          website,
          desc,
          title,
          thumb,
          images,
          tags,
          price,
        } = data;
        setState({
          ...state,
          patreon,
          mmf,
          website,
          desc,
          title,
          thumb,
          price,
        });
        setImages(images);
        setTags(tags);
        console.log("Document data:", old.data());
      } else {
        console.log("No such document!");
      }
    }
  };
  const retreiveBundleAppendPhotos = async () => {
    // if (nextYear) {
    //   year += 1;
    // }
    let ogImages = images;
    let bundleMonth = state.month.toString() + "_" + bundleYear.toString();
    console.log("bundllemonth", bundleMonth);
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";

    let docId = creatorUnderscored + "_" + bundleMonth;
    let bundleRef = doc(db, "creators", creatorUnderscored, "bundles", docId);
    const docSnap = await getDoc(bundleRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const {
        creator,
        patreon,
        mmf,
        website,
        desc,
        title,
        thumb,
        images,
        tags,
        price,
        video,
      } = data;
      setState({
        ...state,
        patreon,
        mmf,
        website: website ? website : "",
        desc,
        title,
        thumb,
        video: video || "",
        price,
      });
      setImages([...images, ...ogImages]);
      setTags(tags);
      console.log("Document data:", docSnap.data());
    } else {
      console.log("No such document!");
    }
  };
  const retreiveBundlePrependPhotos = async () => {
    // if (nextYear) {
    //   year += 1;
    // }
    let ogImages = images;
    let bundleMonth = state.month.toString() + "_" + bundleYear.toString();
    console.log("bundllemonth", bundleMonth);
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";

    let docId = creatorUnderscored + "_" + bundleMonth;
    let bundleRef = doc(db, "creators", creatorUnderscored, "bundles", docId);
    const docSnap = await getDoc(bundleRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const {
        creator,
        patreon,
        mmf,
        website,
        desc,
        title,

        images,
        tags,
        price,
        video,
      } = data;
      setState({
        ...state,
        patreon,
        mmf,
        website: website ? website : "",
        desc,
        title,

        video: video || "",
        price,
      });
      setImages([...ogImages, ...images]);
      setTags(tags);
      console.log("Document data:", docSnap.data());
    } else {
      console.log("No such document!");
    }
  };
  const getBundleTags = async () => {
    // if (nextYear) {
    //   year += 1;
    // }
    let bundleMonth = state.month.toString() + "_" + bundleYear.toString();
    console.log("bundllemonth", bundleMonth);
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";

    let docId = creatorUnderscored + "_" + bundleMonth;
    let bundleRef = doc(db, "creators", creatorUnderscored, "bundles", docId);
    const docSnap = await getDoc(bundleRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const {
        creator,
        patreon,
        mmf,
        website,
        desc,
        price,
        title,
        video,
        tags,
      } = data;
      setState({
        ...state,
        patreon,
        mmf,
        website: website ? website : "",
        desc,
        price,
        title,

        video: video || "",
      });
      setTags(tags);
      console.log("Document data:", docSnap.data());
    } else {
      console.log("No such document!");
    }
  };
  const retreiveBundleRequest = async () => {
    let bundleMonth = state.month.toString() + "_" + year.toString();

    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";

    let docId;

    if (state.wp) {
      docId = creatorUnderscored + "_welcome_pack";
    } else {
      docId = creatorUnderscored + "_" + bundleMonth;
    }

    let bundleRef = doc(
      db,
      "creator_bundle_requests",
      creatorUnderscored,
      "bundle_requests",
      docId
    );
    const docSnap = await getDoc(bundleRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const {
        creator,
        patreon,
        mmf,
        website,
        desc,
        title,
        thumb,
        images,
        tags,
        price,
      } = data;

      setState({
        ...state,
        patreon,
        mmf,
        website: website ? website : "",
        desc,
        title,
        thumb,
        price,
      });
      setImages(images);
      setTags(tags);
      console.log("Document data:", docSnap.data());
    } else {
    }
  };
  axios.defaults.baseURL = "http://localhost:6543";
  const getWelcomePack = async () => {
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";

    let docId = creatorUnderscored + "_welcome_pack";
    let bundleRef = doc(
      db,
      "creators",
      creatorUnderscored,
      "welcome_packs",
      docId
    );
    const docSnap = await getDoc(bundleRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const {
        creator,
        patreon,
        mmf,
        website,
        desc,
        title,
        thumb,
        images,
        tags,
        date,
        price,
      } = data;
      setState({
        ...state,
        patreon,
        mmf,
        website,
        desc,
        title,
        date: date,
        thumb,
        price,
        wp: true,
      });
      setImages(images);
      setTags(tags);
      console.log("Document data:", docSnap.data());
    } else {
      console.log("No such document!");
    }
  };
  console.log("by", bundleYear);
  const getCreatorBundles = async () => {
    let creatorUnderscored = state.creator.replaceAll(" ", "_").toLowerCase();
    let bundlesRef = collection(db, "creators", creatorUnderscored, "bundles");
    let snapShot = await getDocs(bundlesRef);
    let bundleArr = [];
    snapShot.forEach((doc) => {
      bundleArr.push(doc.data());
    });
    let wpRef = collection(db, "creators", creatorUnderscored, "welcome_packs");
    let wSnapShot = await getDocs(wpRef);
    let wpBundleArr = [];
    wSnapShot.forEach((doc) => {
      wpBundleArr.push(doc.data());
    });
    setBundles([...bundleArr, ...wpBundleArr]);
  };
  const getCampaign = () => {
    axios.post("/scraper/campaign/url", { url: state.patreon }).then((res) => {
      setState({ ...state, campaign: res.data.campaign });
    });
  };
  const getTribeID = () => {
    axios.post("/scraper/tribeID", { url: state.mmf }).then((res) => {
      setState({ ...state, tribeID: res.data.tribeID });
    });
  };
  return (
    <>
      <PatreonScrapeForm
        setPatreon={setPatreon}
        addImages={addImages}
        setAddImages={setAddImages}
        creator={state.creator}
        url={props.bundle ? props.bundle.url : ""}
      />
      <Form
        onSubmit={addBundle}
        className="bg-dark p-3 text-light mt-2 rounded-3"
      >
        <h4>Bundle Preview</h4>
        <Row className="d-flex justify-content-center">
          <Col xs="12" md="8" lg="6">
            <BundleCard
              previewMode={true}
              thisBundle={{ ...state, images, tags }}
            />
          </Col>
        </Row>
        <h5>Add a Bundle</h5>
        <Form.Group>
          <Form.Label>Creator</Form.Label>
          <InputGroup>
            <Form.Control
              onChange={handleInput}
              value={state.creator}
              name="creator"
              type="text"
              placeholder="Capitalize First Letters"
              required
            />
            <InputGroup.Text>
              <Button onClick={() => loadCreator(state.creator)}>Check</Button>
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Row>
          <Form.Group as={Col} xs="6">
            <Form.Label>Price</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                onChange={handleInput}
                value={state.price}
                name="price"
                type="text"
                min="0"
                placeholder="0 or more."
              />
            </InputGroup>
          </Form.Group>
        </Row>
        <Row>
          <Col className=" border border-light rounded-3 p-2 my-3 mx-2">
            <Form.Group>
              <Form.Label>Patreon URL</Form.Label>
              <Form.Control
                onChange={handleInput}
                value={state.patreon}
                name="patreon"
                type="text"
                placeholder="Must start with https://"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>My Mini Factory URL</Form.Label>
              <Form.Control
                onChange={handleInput}
                value={state.mmf}
                name="mmf"
                type="text"
                placeholder="Must start with https://"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Website or Other Useful Link URL</Form.Label>
              <Form.Control
                onChange={handleInput}
                value={state.website}
                name="website"
                type="text"
                placeholder="Must start with https://"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Campaign</Form.Label>
              <InputGroup>
                <Form.Control
                  onChange={handleInput}
                  value={state.campaign}
                  name="campaign"
                  type="text"
                  placeholder="000000"
                  // required
                />
                <InputGroup.Text>
                  <Button className="btn-orange" onClick={() => getCampaign()}>
                    Get Campaign
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>TribeID</Form.Label>
              <InputGroup>
                <Form.Control
                  onChange={handleInput}
                  value={state.tribeID}
                  name="tribeID"
                  type="text"
                  placeholder="000"
                  // required
                />
                <InputGroup.Text>
                  <Button className="btn-mmf" onClick={() => getTribeID()}>
                    Get TribeID
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group>
          <Form.Label>Welcome Pack {state.wp ? " - Yes" : " - No"}</Form.Label>
          <Form.Check
            name="wp"
            value={state.wp}
            checked={state.wp}
            onChange={() => setState({ ...state, wp: !state.wp })}
            placeholder=""
          />
        </Form.Group>
        <Row>
          <Form.Group as={Col} xs="6">
            <Form.Label className={state.wp ? "text-secondary" : ""}>
              Bundle Release Month
            </Form.Label>
            <Form.Select
              disabled={state.wp}
              onChange={handleInput}
              value={state.wp ? "--" : state.month}
              name="month"
              className={state.wp ? "bg-secondary border-secondary" : ""}
            >
              {months.map((m, i) => {
                return <option value={i + 1}>{m}</option>;
              })}
            </Form.Select>
          </Form.Group>
        </Row>
        <Form.Group>
          <Form.Label
            className={`my-1 me-3 ${state.wp ? "text-secondary" : ""}`}
          >
            Bundle Year
          </Form.Label>
          <Form.Check
            label="2021"
            disabled={state.wp}
            inline
            value={2021}
            className={state.wp ? "text-secondary" : ""}
            type="radio"
            checked={bundleYear === 2021 ? true : false}
            onClick={() => setBundleYear(2021)}
          />
          <Form.Check
            disabled={state.wp}
            label="2022"
            inline
            className={state.wp ? "text-secondary" : ""}
            value={2022}
            type="radio"
            checked={bundleYear === 2022 ? true : false}
            onClick={() => setBundleYear(2022)}
          />
          <Form.Check
            label="2023"
            disabled={state.wp}
            className={state.wp ? "text-secondary" : ""}
            inline
            value={2023}
            type="radio"
            checked={bundleYear === 2023 ? true : false}
            onClick={() => setBundleYear(2023)}
          />
        </Form.Group>
        {bundles.length > 0 ? (
          <Row>
            {bundles.map((b) => {
              return (
                <Col>
                  <Badge bg="warning" className="text-dark">
                    {b.bundleID}
                  </Badge>
                </Col>
              );
            })}
          </Row>
        ) : null}
        <Button className="m-1 btn-sm" onClick={() => retreiveBundle()}>
          Get Bundle
        </Button>
        <Button
          variant="warning"
          className="m-1 btn-sm"
          onClick={() => getWelcomePack()}
        >
          Get Welcome Pack
        </Button>
        <Button
          variant="secondary"
          className="m-1 btn-sm"
          onClick={() => retreiveBundleRequest()}
        >
          Get Bundle Request
        </Button>

        <Button
          variant="success"
          className="m-1 btn-sm"
          onClick={() => retreiveBundleAppendPhotos()}
        >
          Get Bundle and Append Photos
        </Button>
        <Button
          variant="info"
          className="m-1 btn-sm"
          onClick={() => retreiveBundlePrependPhotos()}
        >
          Get Bundle and Prepend these Photos
        </Button>
        <Button
          variant="danger"
          className="m-1 btn-sm"
          onClick={() => getBundleTags()}
        >
          Get Bundle No Photos
        </Button>
        <Button
          variant="primary"
          className="m-1 btn-sm"
          onClick={() => getCreatorBundles()}
        >
          See Creator Bundles
        </Button>

        <Form.Group className="border border-light rounded-3 p-1 ">
          <Form.Group>
            <Form.Label>Thumbnail</Form.Label>

            <Form.Control
              onChange={handleInput}
              className="my-1"
              type="text"
              value={state.thumb}
              name="thumb"
              required
              placeholder="https://img.url/here "
            />
          </Form.Group>
          <p>or</p>
          <Upload setUrl={setThumbUrl} />
        </Form.Group>
        <Form.Group className="border border-light rounded-3 p-1 my-1 ">
          <Form.Label>Images</Form.Label>

          {images.map((img, i) => {
            return (
              <Form.Control
                onChange={(e) => handleImageInput(e, i)}
                className="my-1"
                key={i}
                type="text"
                value={img}
                name={i}
                placeholder="https://img.url/here"
              />
            );
          })}
          <Button
            variant="warning"
            className="btn-sm my-1"
            onClick={() => setImages([...images, ""])}
          >
            Add Another Image
          </Button>

          <p>or</p>
          <Form.Group>
            <Form.Label>Upload Images</Form.Label>
            <UploadMultiple
              creator={state.creator}
              month={state.month}
              setImages={setImages}
              images={images}
              setUrl={addUploadedImage}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Video</Form.Label>
            <Form.Control
              type="text"
              name="video"
              value={state.video}
              onChange={handleInput}
              placeholder="Video URL"
            />
          </Form.Group>
        </Form.Group>

        <Form.Group className="border border-light rounded-3 p-1 my-1 ">
          <Form.Label>Tags</Form.Label>

          <Form.Group>
            <Form.Label className="me-3">Fantasy or Scifi: </Form.Label>
            <Form.Check
              label="Fantasy"
              inline
              value="2021"
              type="checkbox"
              checked={tags.includes("fantasy")}
              onClick={() => {
                if (!tags.includes("fantasy")) {
                  setTags(["fantasy", ...tags]);
                } else {
                  let tagArr = [...tags];
                  let index = tagArr.indexOf("fantasy");
                  tagArr.splice(index, 1);
                  setTags(tagArr);
                }
              }}
            />
            <Form.Check
              label="Sci-Fi"
              inline
              value="2022"
              type="checkbox"
              checked={tags.includes("scifi")}
              onClick={() => {
                if (!tags.includes("scifi")) {
                  setTags(["scifi", ...tags]);
                } else {
                  let tagArr = [...tags];
                  let index = tagArr.indexOf("scifi");
                  tagArr.splice(index, 1);
                  setTags(tagArr);
                }
              }}
            />
          </Form.Group>
          <ReactTagInput tags={tags} onChange={(newTags) => setTags(newTags)} />
          {/* <Form.Label>Tags - one per line</Form.Label>
        {tags.map((tag, i) => {
          return (
            <Form.Control
              onChange={(e) => handleTagInput(e, i)}
              className="my-1"
              key={i}
              type="text"
              value={tag}
              name={i}
              placeholder="plural form of word ex.'humans'"
            />
          );
        })}
        <Button
          variant="warning"
          className="btn-sm my-1"
          onClick={() => setTags([...tags, ""])}
        >
          Add Tag
        </Button> */}
        </Form.Group>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            onChange={handleInput}
            value={state.title}
            name="title"
            type="text"
            required
            placeholder="Title of Bundle Here"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            onChange={handleInput}
            value={state.desc}
            name="desc"
            as="textarea"
            placeholder="Optional Description"
            rows="2"
          />
        </Form.Group>
        <Button type="submit" className="m-2">
          Submit
        </Button>
        <Button onClick={() => clearForm()} variant="danger" className="m-2">
          Clear
        </Button>
      </Form>
    </>
  );
}

export default NewBundleForm;
