import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import CreatorBundles from "./CreatorBundles";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import MMFPosts from "../Admin/MMFPosts";
import PatreonPosts from "../Admin/PatreonPosts";
import { useLocation } from "react-router";
import ReactMarkdown from "react-markdown";
import ReactHtmlParser from "react-html-parser";

function CreatorPage() {
  let navigate = useNavigate();
  let location = useLocation();
  const params = useParams();
  const [creatorInfo, setCreatorInfo] = useState();
  const [expandPatreon, setExpandPatreon] = useState(false)
  useEffect(() => {
    loadCreator();
  }, []);
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  const loadCreator = async () => {
    let creatorUnderscored = params.creator
      ? params.creator.toLowerCase()
      : "default";
    if (params.creator === "Cyber-Forge-Miniatures") {
      creatorUnderscored = "cyber-forge_miniatures";
    }
    let creatorRef = doc(db, "creators", creatorUnderscored);
    const docSnap = await getDoc(creatorRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const { creator, patreon, mmf, website, price } = data;
      setCreatorInfo(data);
    } else {
      console.log("No such creator.");
    }
  };

  return (
    <Container>
      <Row>
        {/* <Col>
          <Button
            className="my-2"
            onClick={() => {
              navigate(-1);
            }}
            variant="secondary"
          >
            Back
          </Button>
        </Col> */}
        <h1 className="text-center">{params.creator.replaceAll("_", " ").toUpperCase()}</h1>
      </Row>
      <Row className="d-flex justify-content-center">
    
    {creatorInfo &&
      creatorInfo.patreonData &&
      creatorInfo.patreonData.attributes && (
          <Col xs="12" md="6">
            <Container style={{maxHeight: "80vh", overflow: "scroll", overflowX: "hidden"}}>
              <h3 className="text-orange">Patreon Intro</h3>
              <p className='patreon-post'>{ReactHtmlParser(creatorInfo.patreonData.attributes.summary)}</p>
            
            </Container>
          </Col>
          )}
            {creatorInfo && creatorInfo.tribeData && (
        <Col xs="12" md="6">
          <Container>
            <h3 className="text-mmf">Tribe Intro</h3>
            <ReactMarkdown>{creatorInfo.tribeData.about}</ReactMarkdown>
          </Container>
        </Col>
    )}
        </Row>
        <h2 className="text-center my-5">Current and Past Bundles</h2>
      <Row>
        <Col>
          {creatorInfo ? <CreatorBundles creator={creatorInfo} /> : null}
        </Col>
      </Row>
      
    
      <Row id="posts" className="d-flex justify-content-center">
        {/* {creatorInfo && creatorInfo.campaign ? (
          <Col xs="12" md="6">
            <PatreonPosts campaign={creatorInfo.campaign} />
          </Col>
        ) : null} */}
        {creatorInfo && creatorInfo.tribeID ? (
          <Col xs="12" md="6">
            <MMFPosts tribeID={creatorInfo.tribeID} />
          </Col>
        ) : null}
      </Row>
    </Container>
  );
}

export default CreatorPage;
