import React, { useEffect, useState } from "react";
import {
  Accordion,
  Container,
  Row,
  Col,
  Button,
  Navbar,
  Alert,
} from "react-bootstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";
import CreatorBundles from "../Creators/CreatorBundles";
import { isCompositeComponentWithType } from "react-dom/cjs/react-dom-test-utils.development";
import { Link } from "react-router-dom";

function TrackerBundles(props) {
  const [bundles, setBundles] = useState();
  const [loaded, setLoaded] = useState(false);

  const { creator, wp, setWP, setThisMonth, setNextMonth, nM,tM } = props;
  useEffect(() => {
    loadBundles();
  }, []);
  console.log('bundles no creator', bundles.map(b => !b.creator))
  const d = new Date();
  let thisMonth = d.getMonth() + 1;
  let nextMonth = d.getMonth() + 2;
  let year = d.getFullYear();

  thisMonth = thisMonth.toString() + "_" + year.toString();
  nextMonth = nextMonth.toString() + "_" + year.toString();

  const loadBundles = async () => {
    setLoaded(false);
    let creatorUnderscored = creator.creator.replaceAll(" ", "_").toLowerCase();
    let bundlesRef = collection(db, "creators", creatorUnderscored, "bundles");
    let snapShot = await getDocs(bundlesRef);
    let bundleArr = [];
    snapShot.forEach((doc) => {
      bundleArr.push(doc.data());
    });
    let wpRef = collection(db, "creators", creatorUnderscored, "welcome_packs");
    let wSnapShot = await getDocs(wpRef);
    let wpBundleArr = [];
    wSnapShot.forEach((doc) => {
      wpBundleArr.push(doc.data());
    });
    setBundles([...bundleArr, ...wpBundleArr]);
    setLoaded(true);
  };


  const WelcomePack = () => {
    if(bundles.some(
      (b) => b.bundleID === creator.creator?.toLowerCase().replaceAll(" ", "_") + "_welcome_pack")) {
        return (
          <td><Link  to={`/creator/${creator.creator?.replaceAll(" ", "_")}`}><span className="text-primary">Yes</span></Link></td>
        )
      } else {
        return (<span className="text-danger">No</span>)
      }
  }
  const ThisMonth = () => {
    if(bundles.some(
      (b) => b.bundleID === creator.creator?.toLowerCase()?.replaceAll(" ", "_") + "_" + thisMonth)) {
        return (
          <td><Link  to={`/creator/${creator.creator?.replaceAll(" ", "_")}`}><span className="text-primary">Yes</span></Link></td>
        )
      } else {
        return (<span className="text-danger">No</span>)
      }
  }
  const NextMonth = () => {
    if(bundles.some(
      (b) => b.bundleID === creator.creator.toLowerCase().replaceAll(" ", "_") + "_" + nextMonth)) {
       
        return (
          <td><Link  to={`/creator/${creator.creator.replaceAll(" ", "_")}`}><span className="text-primary">Yes</span></Link></td>
        )
      } else {
        return (<span className="text-danger">No</span>)
      }
  }
  return bundles ? (
    <tr>
      
      <td>
        <a target="_blank" rel='noreferrer' href={creator.patreon ? creator.patreon : creator.mmf ? creator.mmf : creator.website ? creator.website : ''}>{creator.creator}</a></td>
      <td>
       <WelcomePack />
      </td>
      <td>
       <ThisMonth />
      </td>
      <td>
        <NextMonth />
      </td>
    </tr>
  ) : null;
}

export default TrackerBundles;
