import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
function Donate() {
  return (
    <div>
        <p>If you would like to support this website and offset some of the costs, you can use the donate button to do so.</p>
      <a
        href="https://www.paypal.com/donate/?hosted_button_id=Z7GDQRPX3SYFE"
        target="_blank"
        rel="noreferrer"
      >
        <Badge bg="warning" pill className="btn text-dark ">
          Donate
        </Badge>
      </a>
    </div>
  );
}

export default Donate;
