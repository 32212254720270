import { useState, useEffect } from "react";
import { storage } from "../../firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import ProgressMultiple from "./ProgressMultiple";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import useStorage from "../../hooks/useStorage";

function UploadMultiple(props) {
  const { images, setImages, creator, month } = props;

  const [percentage, setPercentage] = useState();

  // const [file, setFile] = useState(null);
  // const { url, progress } = useStorage();
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);

  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    if (url) {
      if(images[0] === ""){
        setImages([url])
      } else {
        setImages([...images, url]);
      }
      // resetFile()
    }
  }, [url]);
  const resetFiles = (e) => {
    setFiles([]);
  };
  const handleUpload = (e) => {
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        console.log("files[i]", files[i]);
        upload(files[i]);
        
      }
    } else {
      resetFiles();
    }
    resetFiles()
  };

  const upload = (f) => {

    const storageRef = ref(storage, `/uploads/${creator}${month}/${Math.floor(Math.random() * 1000)}${f.name}`);
    const uploadTask = uploadBytesResumable(storageRef, f);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        //this is for progress of upload
        let percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPercentage(percent);
        // setProgress(percentage);
      },
      (err) => {
        setError(err);
      },
      async () => {
        //gets the url of the uploaded f
        await getDownloadURL(uploadTask.snapshot.ref)
          .then(async (url) => {
            setUrl(url);
            console.log("url returned", url);
          })
          .catch((e) => console.error(e));
      }
    );
  };

  console.log("percentage", percentage);
  return (
    <>
    <InputGroup>
      <Form.Control

        // disabled={props.isDisabled}
        type="file"
        onChange={(e) => setFiles(e.target.files)}
        multiple
        />
        <InputGroup.Text className='p-0'>
      <Button onClick={handleUpload} className="bg-primary m-0">
        UPLOAD
      </Button>
        </InputGroup.Text>
        </InputGroup>
      <div className="output">
        {error && <div className="error">{error}</div>}
        {/* {file && <div className="file">{file.name}</div>} */}
        {files.length > 0 && percentage !== 100 ? (
          <div>Uploading</div>
        ) : null}
      </div>
    </>
  );
}

export default UploadMultiple;
