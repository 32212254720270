import React from "react";

import { Routes, Route } from "react-router-dom";
import Landing from "./Components/Landing/Landing";
import ThisMonth from "./Components/ThisMonth/ThisMonth";
import NextMonth from "./Components/NextMonth/NextMonth";
import LastMonth from "./Components/LastMonth/LastMonth";
import Creators from "./Components/Creators/Creators";
import Creator from "./Components/Creators/Creator";
import Suggest from "./Components/Creators/Suggest";
import Admin from "./Components/Admin/Admin";
import WelcomePacks from "./Components/WelcomePacks/WelcomePacks";
import Account from "./Components/Account/Account";
import About from "./Components/About/About";
import SearchPage from "./Components/SearchPage/SearchPage";
import RecentAdditions from "./Components/Recents/RecentAdditions";
import TrendingPage from "./Components/Trending/TrendingPage";
import CreatorPage from "./Components/Creators/CreatorPage";
import TrackerPage from "./Components/Tracker/TrackerPage";
import Stats from "./Components/Creators/Stats";
import Privacy from "./Components/Privacy/Privacy";
import ToS from "./Components/Privacy/ToS";
import Facebook from "./Components/Login/Facebook";

// import MyMiniFactoryScraper from "./Components/Admin/MyMiniFactoryScraper";

export default (
  <Routes>
    <Route exact path="/" element={<Landing />} />
    <Route exact path="/this-month" element={<ThisMonth />} />
    <Route exact path="/next-month" element={<NextMonth />} />
    <Route exact path="/last-month" element={<LastMonth />} />
    <Route exact path="/welcome-packs" element={<WelcomePacks />} />
    <Route exact path="/admin" element={<Admin />} />
    <Route exact path="/creators" element={<Creators />} />
    <Route exact path="/creator" element={<Creator />} />
    <Route exact path="/account" element={<Account />} />
    <Route exact path="/suggest" element={<Suggest />} />
    <Route exact path="/about" element={<About />} />
    {/* <Route exact path="/search" element={<SearchPage />} /> */}
    <Route exact path="/trending" element={<TrendingPage />} />
    <Route exact path="/tracker" element={<TrackerPage />} />
    <Route exact path="/stats" element={<Stats />} />
    <Route exact path="/recents" element={<RecentAdditions />} />
    <Route exact path="/privacy" element={<Privacy />} />
    <Route exact path="/tos" element={<ToS />} />
    <Route exact path="/facebook" element={<Facebook />} />
    <Route exact path="/creator/:creator" element={<CreatorPage />} />
    {/* <Route exact path="/mmf" element={<MyMiniFactoryScraper />} /> */}
  </Routes>
);
