import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  doc,
  setDoc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import axios from "axios";
import dateFormat from "dateformat";
import {
  Badge,
  Button,
  Container,
  InputGroup,
  ListGroup,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import Swal from "sweetalert2";
import GetCampaigns from "./GetCampaigns";
import { Link } from "react-router-dom";
function Crawler() {
  const [state, setState] = useState({
    creator: "",
  });
  const [creators, setCreators] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedThumb, setSelectedThumb] = useState("");
  const [loaded, setLoaded] = useState(false);

  const loadCreators = async (info) => {
    setLoaded(false);
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];
    snapShot.forEach((doc) => {
      creatorArr.push(doc.data());
    });

    let campaignsOnly = creatorArr.filter(
      (c) => c.patreon && c.patreon.length > 0 && c.campaign
    );
    let noCampaigns = creatorArr.filter((c) => {
      return c.patreon && c.patreon.length && !c.campaign;
    });
    // console.log("creators", creatorArr);
    console.log(
      creatorArr.length,
      "patreon no campaign",
      noCampaigns.length,
      "patreon with campaign: ",
      campaignsOnly.length
    );
    console.log("Creators without campaign: ", noCampaigns);

    // setCreators(patreonsOnly);
    if (info) {
      for (let i = 0; i < campaignsOnly.length; i++) {
        console.log('i', i)
        getCampaignInfo(campaignsOnly[i]);
      }
    } else {
      // getAllPosts(noCampaigns);
      campaignsOnly.map((c) => {
        getPosts(c.campaign, c);
      });
      // setLoaded(true);
    }
  };
  const addCreatorID = async () => {
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];
    snapShot.forEach((doc) => {
      creatorArr.push(doc.data());
    });
    creatorArr.map((creator) => {
      setDoc(
        doc(db, "creators", creator.creator.toLowerCase().replaceAll(" ", "_")),
        { creatorID: creator.creator.toLowerCase().replaceAll(" ", "_") },
        { merge: true }
      );
      console.log("Saved", creator.creator, " id");
    });
  };
  const getCampaignInfo = (creator) => {
    let aliases = {
      dmstash: "dm_stash",
      valkiries_playground: "valkirie's_playground",
      ork_king_studio: "orcking",
      vesna_sculpts: "vesna",
    };

    axios
      .post("/scraper/campaign/info", { campaign: creator.campaign })
      .then(async (res) => {
        console.log("res.data", creator.creator, res.data);
        if (res.data.attributes) {
          console.log(res.data.attributes.name);
        }
        if (res.data.data) {
          console.log("patrons", creator.creator, res.data.data.attributes);
          let id = creator.creatorID;
          if (!id) {
            id = creator.creator.toLowerCase().replaceAll(" ", "_");
          }
          await setDoc(
            doc(db, "creators", id),
            {
              creatorID: id,
              patreonData: res.data.data,
              docID: creator.creator.toLowerCase().replaceAll(" ", "_"),
              patrons: res.data.data.attributes.paid_member_count || "",
            },
            { merge: true }
          );
        }
        console.log("Saved ", creator.creator, res.data);
      })
      .catch((err) => console.log("err", err));
  };
  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const getAllPosts = (arr) => {
    arr.map((c) => {
      getCampaign(c);
    });
  };
  const getCampaign = (creator) => {
    axios
      .post("/scraper/campaign", { creator: creator.creator })
      .then(async (res) => {
        if (res.data.data[0] && res.data.data[0].id) {
          let campaign = res.data.data[0].id.slice(9);
          console.log(campaign);
          await setDoc(
            doc(db, "creator_campaign_check", creator.creatorID),
            {
              creatorID: creator.creatorID,
              creator: creator.creator,
              docID: creator.creator.toLowerCase().replaceAll(" ", "_"),
              campaign: campaign,
            },
            { merge: true }
          );
          getPosts(campaign, creator);
        }
      });
  };
  // let today = new Date();
  const getPosts = (campaign, creator) => {
    axios.post("/scraper/posts", { campaign }).then((res) => {
      //   console.log("posts", res.data);

      // const months = [
      //   "january",
      //   "february",
      //   "march",
      //   "april",
      //   "may",
      //   "june",
      //   "july",
      //   "august",
      //   "september",
      //   "october",
      //   "november",
      //   "december",
      // ];
      // let month = new Date();
      // let thisMonth = months[month.getMonth()];
      // let nextMonth;
      // if (thisMonth === "december") {
      //   nextMonth = "january";
      // } else {
      //   nextMonth = months[month.getMonth() + 1];
      // }
      // console.log(
      //   "month: ",
      //   month,
      //   "thisMonth: ",
      //   thisMonth,
      //   "nextMonth:",
      //   nextMonth
      // );

      // let searchTerms = [
      //   thisMonth,
      //   nextMonth,
      //   "release",
      //   "preview",
      //   "sneek",
      //   "peek",
      //   "teaser",
      // ];
      // let scrapePosts = [];
      let posts = res.data;
      if (creator.lastScrapedPost) {
        posts = posts.filter((p) => p.id > creator.lastScrapedPost);
      }
      // posts.filter((p) => {
      //   if (scrapePosts.findIndex((post) => p.id === post.id) < 0) {
      //     searchTerms.some((t) => {
      //       if (
      //         (p.attributes.content &&
      //           p.attributes.content.toLowerCase().includes(t)) ||
      //         (p.attributes.title.toLowerCase().includes(t) &&
      //           p.attributes.current_user_can_view &&
      //           p.attributes.post_type === "image_file")
      //       ) {
      //         let published = new Date(p.attributes.published_at);
      //         // console.log('published', published)
      //         if (published.getMonth() > today.getMonth() - 2) {
      //           scrapePosts.push(p);
      //           return true;
      //         }
      //         //   console.log("p", p);
      //       }
      //     });
      //   }
      // });
      let d = new Date();
      let date = dateFormat(d, "paddedShortDate");

      let trimmed = posts.map((sp) => {
        let thumb = "";
        if (sp.attributes.image && sp.attributes.image.thumb_url) {
          thumb = sp.attributes.image.thumb_url;
        }
        let imageCount = 0;
        if (sp.relationships.images && sp.relationships.images.data) {
          imageCount = sp.relationships.images.data.length;
        } else if (thumb) {
          imageCount = 1;
        }
        // console.log('sp', sp)
        return {
          creator: creator.creator,
          post_url: sp.attributes.patreon_url,
          title: sp.attributes.title,
          scrape: false,
          rejected: false,
          date: date,
          published: dateFormat(sp.attributes.published_at, "paddedShortDate"),
          thumb: thumb,
          post_id: +sp.id,
          private: !sp.attributes.current_user_can_view,
          imageCount: imageCount,
          postData: sp,
        };
      });

      savePosts(creator, trimmed);
    });
  };
  const showThumb = (thumb) => {
    setSelectedThumb(thumb);
    setShowModal(true);
  };
  const savePosts = async (creator, posts) => {
    let creatorUnderscored = creator.creator.toLowerCase().replaceAll(" ", "_");
    let postsRef = doc(db, "scraping", creatorUnderscored);
    let docID = creatorUnderscored;
    let docData = {
      creator: creator.creator,
      posts: posts.sort((a, b) => b.post_id - a.post_id),
      id: docID,
    };
    await setDoc(postsRef, docData).then(() =>
      console.log("Saved posts for", creator.creator)
    );
  };
  const getScrapeUrls = async () => {
    setLoaded(false);
    let scrapeRef = collection(db, "scraping");
    let snapShot = await getDocs(scrapeRef);
    let scrapeArr = [];
    snapShot.forEach((doc) => {
      scrapeArr.push(doc.data());
    });
    console.log("scrapeArr", scrapeArr);
    setCreators(scrapeArr);

    setLoaded(true);
  };
  const markScrape = async (post) => {
    const scrapeRef = collection(db, "scrape_urls");
    let docData = {
      creator: post.creator,
      title: post.title,
      url: post.post_url,
      scraped: false,
      post_id: post.post_id,
    };
    await addDoc(scrapeRef, docData)
      .then((res) => {
        let docRef = doc(db, "scrape_urls", res.id);
        setDoc(docRef, { id: res.id }, { merge: true });
        Swal.fire({
          icon: "success",
          timer: 400,
          showConfirmButton: false,
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          timer: 200,
          showConfirmButton: false,
        });
      });
    await setDoc(
      doc(db, "creators", post.creator.toLowerCase().replaceAll(" ", "_")),
      { lastScrapedPost: +post.post_id },
      { merge: true }
    );
  };
  const markAsScraped = async (post) => {
    await setDoc(
      doc(db, "creators", post.creator.toLowerCase().replaceAll(" ", "_")),
      { lastScrapedPost: +post.post_id },
      { merge: true }
    ).then(() =>
      Swal.fire({ icon: "success", timer: 400, showConfirmButton: false })
    );
  };
  const removeCreator = async (c) => {
    let creatorUnderscored = c.creator.replaceAll(" ", "_").toLowerCase();
    await deleteDoc(doc(db, "scraping", creatorUnderscored))
      .then(() => {
        Swal.fire({
          icon: "success",
          timer: 400,
          showConfirmButton: false,
        });
        let creatorList = [...creators];
        let index = creatorList.findIndex((cl) => cl.creator === c.creator);
        creatorList.splice(index, 1);
        setCreators(creatorList);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          timer: 200,
          showConfirmButton: false,
        });
      });

    await setDoc(
      doc(db, "creators", c.creator.toLowerCase().replaceAll(" ", "_")),
      { lastScrapedPost: +c.posts[0].post_id },
      { merge: true }
    ).then(() =>
      Swal.fire({ icon: "success", timer: 400, showConfirmButton: false })
    );
  };
  const getFEPosts = () => {
    let campaign = "3488552";
    let url = `https://www.patreon.com/api/posts?filter[campaign_id]=${campaign}`;
    axios
      .get(url)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <GetCampaigns />
      <h2>Crawl</h2>
      <Form>
        <Form.Group>
          <Form.Label>Creator</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              name="creator"
              value={state.creator}
              onChange={handleInput}
              placeholder=""
            />
            <InputGroup.Text>
              <Button
                variant="success"
                onClick={() => getCampaign({ creator: state.creator })}
              >
                Get Posts
              </Button>
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Button
          variant="warning"
          className="my-2 mx-2"
          onClick={() => addCreatorID(false)}
        >
          Add Creator ID's
        </Button>
        <Button
          variant="warning"
          className="my-2 mx-2"
          onClick={() => loadCreators(false)}
        >
          Scrape
        </Button>
        <Button
          variant="warning"
          className="my-2 mx-2"
          onClick={() => getFEPosts()}
        >
          Scrape FRont end
        </Button>
        <Button className="my-2" onClick={() => getScrapeUrls()}>
          Get Scraped Posts
        </Button>
        <Button
          variant="warning"
          className="my-2 mx-2"
          onClick={() => loadCreators(true)}
        >
          Get Creator Info
        </Button>
      </Form>
      <Container fluid className="my-3 p-0">
        <ListGroup>
          {loaded &&
            creators.map((c) => {
              if (c.posts.length > 0) {
                return (
                  <ListGroup.Item
                    key={c.creator}
                    className="d-flex flex-column"
                  >
                    <a
                      href={`/creator/${c.creator.replaceAll(" ", "-")}`}
                      target="_blank"
                    >
                      {" "}
                      {c.creator}
                    </a>
                    <div>
                      <Button
                        variant="dark"
                        onClick={() => removeCreator(c)}
                        className="btn-sm my-2"
                      >
                        Remove Creator
                      </Button>
                    </div>
                    <div>
                      {c.posts.map((p, i) => {
                        if (p.private || !p.thumb) {
                          return null;
                        }
                        return (
                          <Row
                            key={p.post_url}
                            className=" d-flex jutify-content-between align-items-center rounded-3 text-center border p-3"
                          >
                            <Col>
                              <h6>{p.published}</h6>
                              {p.private ? (
                                <Badge bg="danger">Private</Badge>
                              ) : null}
                              {p.thumb ? (
                                <Badge
                                  bg="secondary"
                                  className="btn btn-sm m-3"
                                  onClick={() => showThumb(p.thumb)}
                                >
                                  See thumb
                                </Badge>
                              ) : (
                                <Badge bg="danger">No pictures</Badge>
                              )}
                              <p>Image Count: {p.imageCount}</p>
                            </Col>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={p.post_url}
                            >
                              {p.title}
                            </a>
                            <Col>
                              <Button
                                className=" m-3 btn-sm"
                                onClick={() => markScrape(p)}
                              >
                                Add Scrape Url
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                className=" m-3 btn-sm"
                                variant="success"
                                onClick={() => markAsScraped(p)}
                              >
                                Mark Scraped
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  </ListGroup.Item>
                );
              }
            })}
        </ListGroup>
      </Container>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="xl"
        centered
      >
        <Modal.Body>
          <img src={selectedThumb} className="w-100" alt="" />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Crawler;
