import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  doc,
  setDoc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
  collectionGroup,
  query,
  where,
} from "firebase/firestore";

import { db } from "../../firebase/config";
import NoTribeCreator from "./NoTribeCreator";
function SocialMedia() {
  const [creators, setCreators] = useState([]);
  const [instaPosts, setInstaPosts] = useState([]);
  // const [thisMonth, setThisMonth] = useState([])
  const loadCreators = async () => {
    const d = new Date();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    let queryMonth = month.toString() + "_" + year.toString();

    const loadBundles = async () => {
      const thisMonth = query(
        collectionGroup(db, "bundles"),
        where("month", "==", queryMonth)
      );

      const querySnapshot = await getDocs(thisMonth);
      let bundleArr = [];
      querySnapshot.forEach((doc) => {
        bundleArr.push(doc.data());
      });
      let creatorsOnly = bundleArr.map(b => b.creator?.toLowerCase().replaceAll(" ",''))
      console.log('creators only', creatorsOnly)
      return creatorsOnly;
    };
    let thisMonthCreators = await loadBundles();
    // let thisMonthCreators = thisMonthBundles.map(b => b.creatorID)

    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];

    snapShot.forEach((doc) => {
      creatorArr.push(doc.data());
    });
    console.log("creatorarrlength", creatorArr.length);
    let noTribe = creatorArr.filter((c) => !c.tribeID);
    // setCreators(noTribe);

    let needPreview = noTribe.filter((t) => {
      return !thisMonthCreators.includes(t.creator?.toLowerCase().replaceAll(' ',''));
    });
    setCreators(needPreview);
    // mmfOnly.map(c => {
    //   getSocials(c)
    // })
    // for (let i = 0; mmfOnly.length - 1; i++) {
    //   let c = mmfOnly[i];
    //   console.log("Getting socials for:", c.creator);
    //   await axios
    //     .post("/scraper/mmf/creator", { url: c.mmf })
    //     .then((res) => {
    //       console.log(res.data);
    //       let docRef = doc(db, "social_media", c.creatorID);
    //       setDoc(
    //         docRef,
    //         { social_media_links: res.data },
    //         { merge: true }
    //       ).then((res2) =>
    //         console.log("Saved socials for", c.creator, res.data)
    //       );
    //     })
    //     .catch((err) => console.log(err));
    // }
  };

  console.log("creatorslength", creators.length);

  const getSocials = async (c) => {
    console.log("Getting socials for:", c.creator);
    await axios
      .post("/scraper/mmf/creator", { url: c.mmf })
      .then((res) => {
        console.log(res.data);
        let docRef = doc(db, "creators", c.creatorID);
        setDoc(docRef, { social_media_links: res.data }, { merge: true }).then(
          (res2) => console.log("Saved socials for", c.creator, res.data)
        );
      })
      .catch((err) => console.log(err));
  };
  const getInstaPosts = (url) => {
    axios
      .post("/scraper/ig/posts", {
        url: url,
      })
      .then((res) => {
        if (res.data.length > 0) {
          setInstaPosts(res.data);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.toJSON());
        // console.log(err.response.data);
      });
  };

  // const d = new Date();
  // let month = d.getMonth() + 1;
  // let year = d.getFullYear();
  // let queryMonth = month.toString() + "_" + year.toString();

  // useEffect(() => {
  //   loadBundles();
  //   // logEvent(analytics, "screen_view", {
  //   //   firebase_screen: "this-month",
  //   // });
  //   // loadPromoted();
  // }, []);

  // const loadBundles = async () => {
  //   const thisMonth = query(
  //     collectionGroup(db, "bundles"),
  //     where("month", "==", queryMonth)
  //   );

  //   const querySnapshot = await getDocs(thisMonth);
  //   let bundleArr = [];
  //   querySnapshot.forEach((doc) => {
  //     bundleArr.push(doc.data());
  //   });
  //   // extractTags(bundleArr);
  //   let creator_names = bundleArr.map((b) => b.creator);
  //   // setThisMonth(creator_names);
  //   console.log("Number of Bundles: ", bundleArr.length);

  //   // filterByTag();

  //   // setLoaded(true);
  // };

  // const getTweets = (url) => {
  //   // get handle
  //   //get id
  //   // save id to the twitter link object for future
  //   //get tweets
  // };
  return (
    <Container>
      <h3>Social Medias</h3>
      <Button onClick={() => loadCreators()}>
        No Tribe Creators with no bundle this month
      </Button>
      {/* <Button
        onClick={() => getInstaPosts("https://www.instagram.com/3d_art_guy")}
      >
        Get Insta Posts
      </Button> */}
      {/* {instaPosts.length > 0 &&
        instaPosts.map((post, index) => {
          return (
            <Row>
              <Col xs="12">
                
              </Col>
              <Col xs='12'><p>{post.caption}</p> </Col>
            </Row>
          );
        })} */}
      {creators.length > 0 &&
        creators
          .filter((c) => c.mmf)
          .map((c) => {
            return (
              <Row key={c.creatorID} className="mt-2 pointer">
                {/* <NoTribeCreator creator={c}/> */}
                <Col>
                  <a
                    href={c.patreon + "/posts"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {c.creator}
                  </a>
                </Col>
                {/* {c.social_media_links &&
                  c.social_media_links.find((l) => l.title === "instagram") && (
                    <Col>
                      <Button
                        variant="warning"
                        onClick={() => {
                          let insta = c.social_media_links.find(
                            (l) => l.title === "instagram"
                          );
                          let url = insta.link;
                          if (url) {
                            getInstaPosts(url);
                          } else {
                            console.log("no instagram", c.social_media_links);
                          }
                        }}
                      >
                        Get Instagram Posts
                      </Button>
                    </Col>
                  )} */}
                {/* {c.social_media_links &&
                  c.social_media_links.find((l) => l.title === "twitter") && (
                    <Col>
                      <Button
                        onClick={() => {
                          let twitter = c.social_media_links.find(
                            (l) => l.title === "twitter"
                          );
                          let url = twitter.link;
                          if (url) {
                            
                            getTweets(url);
                          } else {
                            console.log("no twitter", c.social_media_links);
                          }
                        }}
                      >
                        Get Tweets
                      </Button>
                      <a target="_blank" href={c.social_media_links.find(l => l.title ==='twitter').link}>Twitter</a>
                    </Col>
                  )} */}
              </Row>
            );
          })}
      <Col></Col>
      <Col></Col>
    </Container>
  );
}

export default SocialMedia;
