import { useState, useEffect } from "react";
import { storage } from "../firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


const useStorage = (file) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    //references (gives the file this name when in the bucket)
    const storageRef = ref(storage, `/images/${file.name}`);
    ///change this to a variable from props for documents/insurance and whatever else.
    // const collectionRef = 'images'
    // let userId = 20
    //uploads to the bucket with name
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        //this is for progress of upload
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(percentage);
      },
      (err) => {
        setError(err);
      },
      async () => {
        //gets the url of the uploaded file
        await getDownloadURL(uploadTask.snapshot.ref).then(
          async (url) => {
            setUrl(url);
            console.log("url returned", url);
            // await axios.put('/api/company/profile/image',{id: userId, imgUrl: url}).then(res => {
            //     console.log('img url updated', res.data)
            // }).catch(err => console.log(err))
          }
        ).catch(e => console.error(e));
      }
    );
  }, [file]);
  return { progress, url, error };
};

export default useStorage;
