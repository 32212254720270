import React, { useEffect, useState } from "react";
import { collection, getDocs, doc } from "firebase/firestore";
import { Container, Row, Col, Button } from "react-bootstrap";
import ItemsCarousel from "react-items-carousel";
import { db } from "../../firebase/config";

import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import NewBundleCard from "../Bundle/NewBundleCard";
function CreatorBundles(props) {
  const [bundles, setBundles] = useState();
  const [loaded, setLoaded] = useState(false);


  const { creator } = props;
  useEffect(() => {
    loadBundles();
  }, []);
  
 
  let location = useLocation();
  const loadBundles = async () => {
    setLoaded(false);

    let creatorUnderscored = creator.creator.replaceAll(" ", "_").toLowerCase();

    let creatorRef = doc(db, "creators", creator.creatorID);
    let bundlesRef = collection(creatorRef, "bundles");
    let snapShot = await getDocs(bundlesRef);
    let bundleArr = [];
    snapShot.forEach((doc) => {
      bundleArr.push(doc.data());
    });
    let wpRef = collection(db, "creators", creator.creatorID, "welcome_packs");
    let wSnapShot = await getDocs(wpRef);
    let wpBundleArr = [];
    wSnapShot.forEach((doc) => {
      wpBundleArr.push(doc.data());
    });
    let sorted = bundleArr.sort((a,b) => b.updated - a.updated)
    setBundles([...sorted, ...wpBundleArr]);
    setLoaded(true);
  };


  return (
    <Container>
      <Row className="border-top py-1 ps-3 d-flex flex-row justify-content-start">
        <Col xs="12" md="5" lg="4" className="p-0 m-0  ">
          {creator.patreon ? (
            <a target="_blank" href={creator.patreon} className="mx-1">
              <Button className="btn-sm  btn-orange ">Patreon</Button>
            </a>
          ) : null}
          {creator.mmf ? (
            <a target="_blank" href={creator.mmf} className="mx-1">
              <Button className="btn-sm btn-mmf ">My Mini Factory</Button>
            </a>
          ) : null}
          {creator.website ? (
            <a target="_blank" href={creator.website} className="mx-1">
              <Button className="btn-sm btn-website ">Website</Button>
            </a>
          ) : null}
        </Col>
        {creator.price && creator.price > -1 ? (
          <Col className="" xs="12">

            <h5 className="py-1 m-0">Starting at ${creator.price}</h5>
          </Col>
        ) : null}
        {location.pathname === "/creators" &&
        creator.tribeID && (
          <Col xs="12">
            <h4>
              <u className="text-primary">
                <Link
                  to={`/creator/${creator.creatorID}/#posts`}
                >
                  See their recent{" "}
                  Tribes
                  posts here!
                </Link>
              </u>
            </h4>
          </Col>
        )}
      </Row>
      <Row>
        {loaded && bundles[0]
          ? bundles.map((b, i) => {
              return (
                <Col key={b.bundleID} lg="4">
                  <NewBundleCard creatorMode={true} thisBundle={b} />
                </Col>
              );
            })
          : null}
        {/* </ItemsCarousel> */}
      </Row>
    </Container>
  );
}

export default CreatorBundles;
