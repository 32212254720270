import React, {useEffect} from "react";
import useStorage from "../../hooks/useStorage";
import ProgressBar from "react-bootstrap/ProgressBar";



const Progress = ({ file,setUrl,resetFile }) => {
 


  const { url, progress } = useStorage(file);
 
  useEffect(() => {
    if (url) {
      setUrl(url)
      resetFile()  

    }
  }, [url]);
  return (
    <ProgressBar animated now={progress}  />
  );
};

export default Progress;
