import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";
function Stats() {
  const [coolStats, setCoolStats] = useState();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    loadCoolStats();
  }, []);
  const loadCoolStats = async () => {
    const docSnap = await getDoc(doc(db, "cool_stats", "cool_stats"));
    if (docSnap.exists()) {
      let data = docSnap.data();
      setCoolStats(data);
    } else {
      console.log("No such document!");
    }
    setLoaded(true);
  };
  console.log(coolStats);
  return (
    <Container>
      <h1 className="text-center">Stats</h1>
      {loaded && coolStats && (
        <Row>
          <Col xs="12">
            <h3>
              <b className="text-success">
                ${Math.floor(coolStats.market_cap).toLocaleString()}
              </b>
              + spent monthly on{" "}
              <b className="text-primary">
                {coolStats.sub_count.toLocaleString()}
              </b>{" "}
              3D printable miniature subscriptions. subscriptions split between
              the <b className="text-orange">{coolStats.creator_count}</b>{" "}
              creators recorded.
            </h3>
          </Col>
          <Col>
            <Row>
              <Col><b>Creators</b></Col>
              <Col><b>Market Share</b></Col>
              <Col><b>Percent of Subscribers</b></Col>
             
            </Row>
            {coolStats.creatorShareArr.map((c) => {
              return (
                <div key={c.creator}>
                  <Row>
                    <Col>
                      <h6>{c.creator}</h6>
                    </Col>
                    <Col>
                      <span>
                        {(c.market_share * 100).toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        %{"  "}
                      </span>
                      <b className='mx-2 text-success'>                         {c.revenue.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 0,
                        })}
                      </b>
                    </Col>
                    <Col>
                      {(c.sub_share * 100).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                      %
                    </Col>
                    
                  </Row>
                </div>
              );
            })}
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default Stats;
