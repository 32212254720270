import React, { useState, useEffect } from "react";
import "./NewBundleCard.css";
import Container from "react-bootstrap/Container";
import ImageViewer from "react-simple-image-viewer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { doc, setDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useUser } from "../../UserContext";
import Swal from "sweetalert2";
import { getAnalytics, logEvent } from "firebase/analytics";
import mmfLogo from "../../assets/mmf_logo.svg";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
let analytics = getAnalytics();
function NewBundleCard(props) {
  let user = useUser();
  const { thisBundle, creatorMode, searchMode, promoMode } = props;
  useEffect(() => {
    if (user.currentUser.saved) {
      if (user.currentUser.saved.includes(thisBundle.bundleID)) {
        setSaved(true);
      }
    }
    if (props.previewMode) {
      setLoaded(true);
    }
  }, []);
  //   const [descClicked, setDescClicked] = useState(false);
  const [viewer, setViewer] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [bundleSaves, setBundleSaves] = useState(thisBundle.saves || 0);
  const [bundlePClicks, setBundlePClicks] = useState(
    thisBundle.patreon_clicks || 0
  );
  const [bundleMClicks, setBundleMClicks] = useState(thisBundle.m_clicks || 0);
  const [bundleWClicks, setBundleWClicks] = useState(thisBundle.w_clicks || 0);
  const [bundlePreviewClicks, setBundlePreviewClicks] = useState(
    thisBundle.w_clicks || 0
  );
  const [saved, setSaved] = useState(false);
  let viewerImages = [];
if(!thisBundle.date){
  console.log("NO date", thisBundle)
}
  thisBundle.images?.map((img) => {
    viewerImages.push({ src: img });
  });

  const saveBundle = async () => {
    if (user.currentUser.saved) {
      if (user.currentUser.saved.length < 1) {
        Swal.fire({
          position: "top-end",
          text: 'See your saved bundles by clicking "Saved Bundles".',
          timer: 2000,
          showConfirmButton: false,
        });
      }
      setSaved(true);
      const usersRef = collection(db, "users");
      let savedArr = user.currentUser.saved;
      savedArr = [...savedArr, thisBundle.bundleID];

      await setDoc(doc(usersRef, user.currentUser.uid), {
        ...user.currentUser,
        saved: savedArr,
      })
        .then(() => {
          user.setCurrentUser({ ...user.currentUser, saved: savedArr });
        })
        .catch((err) => {
          console.log(err);
          setSaved(false);
        });
      addSave();
    } else {
      Swal.fire({
        position: "top-end",
        text: "Sign in to save bundles for later.",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };
  const addSave = async () => {
    logEvent(analytics, "bundle_save", {
      creator: thisBundle.creator,
      bundleID: thisBundle.bundleID,
    });
    let creatorUnderscored = thisBundle.creator
      ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let saves = bundleSaves;
    saves += 1;
    let subCol = thisBundle.welcomePack ? "welcome_packs" : "bundles";

    const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
    const bundleData = {
      saves,
    };
    //
    await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
      merge: true,
    });
    setBundleSaves(saves);
  };

  const unSaveBundle = async () => {
    if (user.currentUser.saved) {
      setSaved(false);
      const usersRef = collection(db, "users");
      let savedArr = user.currentUser.saved;
      savedArr = savedArr.filter((b) => b !== thisBundle.bundleID);

      await setDoc(doc(usersRef, user.currentUser.uid), {
        ...user.currentUser,
        saved: savedArr,
      })
        .then(() => {
          user.setCurrentUser({ ...user.currentUser, saved: savedArr });
        })
        .catch((err) => {
          setSaved(true);
          console.log(err);
        });
      subtractSave();
    } else {
      Swal.fire({
        position: "top-end",
        text: "Sign in to save bundles for later.",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };
  const subtractSave = async () => {
    let creatorUnderscored = thisBundle.creator
      ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let saves = bundleSaves;
    if (saves != 0) {
      saves -= 1;
    }
    let subCol = thisBundle.welcomePack ? "welcome_packs" : "bundles";

    const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
    const bundleData = {
      saves,
    };
    setBundleSaves(saves);

    //
    await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
      merge: true,
    });
  };
  const addLinkClick = (site) => {
    logEvent(analytics, "link_click", {
      creator: thisBundle.creator,
      site: site,
      month: thisBundle.month,
      bundleID: thisBundle.bundleID,
    });
  };

  const addPClick = async () => {
    let creatorUnderscored = thisBundle.creator
      ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let patreon_clicks = bundlePClicks;
    patreon_clicks += 1;
    let subCol =
      thisBundle.welcomePack || thisBundle.wp ? "welcome_packs" : "bundles";

    const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
    const bundleData = {
      patreon_clicks,
    };
    //
    await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
      merge: true,
    });
    setBundlePClicks(patreon_clicks);
  };
  const addMClick = async () => {
    let creatorUnderscored = thisBundle.creator
      ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let m_clicks = bundleMClicks;
    m_clicks += 1;
    let subCol =
      thisBundle.welcomePack || thisBundle.wp ? "welcome_packs" : "bundles";

    const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
    const bundleData = {
      m_clicks,
    };
    //
    await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
      merge: true,
    });
    setBundleMClicks(m_clicks);
  };
  const addWClick = async () => {
    let creatorUnderscored = thisBundle.creator
      ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let w_clicks = bundleWClicks;
    w_clicks += 1;
    let subCol =
      thisBundle.welcomePack || thisBundle.wp ? "welcome_packs" : "bundles";

    const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
    const bundleData = {
      w_clicks,
    };
    //
    await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
      merge: true,
    });
    setBundleWClicks(w_clicks);
  };
  const previewClicked = async () => {
    if (!props.previewMode) {
      let creatorUnderscored = thisBundle.creator
        ? thisBundle.creator.replaceAll(" ", "_").toLowerCase()
        : false;
      if (!creatorUnderscored) {
        return;
      }

      let preview_clicks = bundlePreviewClicks;
      preview_clicks += 1;
      let subCol =
        thisBundle.welcomePack || thisBundle.wp ? "welcome_packs" : "bundles";

      const bundlesRef = collection(db, "creators", creatorUnderscored, subCol);
      const bundleData = {
        preview_clicks,
      };
      logEvent(analytics, "preview_click", {
        creator: thisBundle.creator,
        month: thisBundle.month,
        bundleID: thisBundle.bundleID,
        date: new Date(),
      });
      //
      await setDoc(doc(bundlesRef, thisBundle.bundleID), bundleData, {
        merge: true,
      });
      setBundlePreviewClicks(preview_clicks);
    }
  };

  return (
    <div className="m-2 bg-light rounded-3 new-bundle-card">
      <div className="card-content-box ">
        <span className="card-date">
          {(thisBundle.wp || thisBundle.bundleID?.includes("welcome")) && (
            <span>Welcome Pack as of </span>
          )}
          {thisBundle.month?.replace("_", "/").replace("20", "") ||
            dateFormat(thisBundle.updated?.toDate(), "m/yy")}
        </span>
        {thisBundle.promoted && promoMode && (
          // <div className="ribbon ribbon-top-left">
          <em className="absolute-promoted text-orange">Promoted</em>
        )}
        <img
          onClick={() => {
            previewClicked();
            setViewer(true);
          }}
          className={`new-carousel-img ${!loaded ? "invisible" : ""} rounded-3`}
          src={thisBundle.thumb}
          onLoad={() => setLoaded(true)}
          loading="lazy"
          alt=""
          height="300px"
          width="300px"
        />{" "}
        <span
          onClick={() => {
            previewClicked();
            setViewer(true);
          }}
          className="pointer expand-icon text-light"
        >
          {thisBundle.images?.length >= 10
            ? "10+ "
            : thisBundle.images?.length || 1}{" "}
          <i className="fa sfa-regular fa-image mx-2"></i>{" "}
          <i className="bi bi-arrows-angle-expand pointer"></i>
        </span>
        <div className="card-info-box rounded-3">
          <div className="card-bottom-row d-flex flex-column rounded-3 py-0">
            <div style={{ width: "100%" }} className="text-center ">
              <span className="card-creator-name me-2 ">
                <strong className="">
                  {" "}
                  <Link
                    className="creator-link "
                    to={`/creator/${
                      thisBundle.creatorID
                        ? thisBundle.creatorID
                        : thisBundle.creator?.replaceAll(" ", "_") ||
                          console.log("NO CREATOR", thisBundle)
                    }`}
                  >
                    {thisBundle.creator}
                  </Link>
                </strong>
              </span>
            </div>
            {
              <div
                style={{ width: "100%" }}
                className=" d-flex justify-content-between"
              >
                {thisBundle.price && (
                  <span className="card-price">${thisBundle.price}</span>
                )}
                {thisBundle.patreon && (
                  <a
                    onClick={() => {
                      addPClick();
                      addLinkClick("patreon");
                    }}
                    className="mx-3"
                    rel="noreferrer"
                    target="_blank"
                    href={thisBundle.patreon}
                    style={{ textDecoration: "none" }}
                  >
                    <i className="text-orange fa-brands fa-patreon no-text-decoration"></i>
                  </a>
                )}
                {thisBundle.mmf && (
                  <a
                    onClick={() => {
                      addMClick();
                      addLinkClick("mmf");
                    }}
                    rel="noreferrer"
                    target="_blank"
                    href={thisBundle.mmf}
                    className="mx-3"
                  >
                    <img
                      className="mmf-logo-sm"
                      src={mmfLogo}
                      alt="mmf"
                      height="16"
                      width="16"
                    />
                  </a>
                )}
                {thisBundle.website && (
                  <a
                    onClick={() => {
                      addWClick();
                      addLinkClick("website");
                    }}
                    rel="noreferrer"
                    target="_blank"
                    classname=" mx-3s"
                    style={{ textDecoration: "none" }}
                    href={thisBundle.website}
                  >
                    <i className="text-light fa-solid fa-link  "></i>
                  </a>
                )}
                {thisBundle.video && (
                  <a
                    href={thisBundle.video}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#FF1A1A" }}
                  >
                    <i className="fa fa-brands fa-youtube"></i>
                  </a>
                )}
                {!props.previewMode &&
                  !thisBundle.wp &&
                  (saved ? (
                    <span className="float-end ms-3">
                      <i
                        onClick={() => unSaveBundle()}
                        className="bi bi-bookmark-star-fill   new-save-button "
                      ></i>
                    </span>
                  ) : (
                    !thisBundle.wp && (
                      <span className="float-end ms-3">
                        <i
                          onClick={() => saveBundle()}
                          className="bi bi-bookmark-star  new-save-button"
                        ></i>
                      </span>
                    )
                  ))}
              </div>
            }
          </div>
        </div>
      </div>
      {!loaded && <div className="skeleton-1 rounded-3 new-skeleton-img"></div>}
      <>
        {viewer && (
          <ImageViewer
            className="image-viewer"
            src={thisBundle.images}
            closeOnClickOutside={true}
            onClose={() => setViewer(false)}
          />
        )}
      </>
    </div>
  );
}

export default NewBundleCard;
