import React, {useEffect} from 'react'
import  Container  from 'react-bootstrap/Container'
import Recents from './Recents'
import { getAnalytics, logEvent } from "firebase/analytics";

function RecentAdditions() {
    let analytics = getAnalytics()
    useEffect(() => {
        
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'recents'
          });
    }, [])
    return (
        <Container>
        <h2 className="text-center">Recent Additions</h2>
            <Recents limitAmount={100} />
        </Container>
    )
}

export default RecentAdditions
