import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Banner.css";

function ThumbAds() {
  return (
    <Row className="d-flex  my-3 justify-content-center">
      {/* <Col
        xs="11"
        sm="9"
        md="4"
        className="d-flex my-2 banner-col flex-column justify-content-center"
      >
        <a
          href="https://www.instagram.com/stl_bundles/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="btn bg-insta banner-img py-3">
            <i
              className="fa fa-brands fa-instagram"
              style={{ fontSize: "30px" }}
            ></i>
            <h3>
              <b> Follow @stl_bundles on instagram!</b>
            </h3>
          </div>
        </a>
      </Col> */}
      {/* <Col
        xs="11"
        sm="9"
        md="4"
        className="d-flex my-2 banner-col flex-column justify-content-center"
      >
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=Z7GDQRPX3SYFE"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="btn bg-warning banner-img py-4">
            <h3>
              <b> Support STLBundles</b>
            </h3>
          </div>
        </a>
      </Col> */}
      <Col
        xs="11"
        sm="9"
        md="4"
        className="d-flex my-2 banner-col flex-column justify-content-center"
      >
        {/* <p className="cross-promo m-0 p-0 text-center">CROSS-PROMO</p> */}
        <a
          href="https://printyourgames.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="banner-img rounded-3"
            src="/images/pyglogo.jpg"
            alt=""
          />
          {/* <p className="text-center m-0">
            <em>Check out this great podcast about printing minis!</em>
          </p> */}
        </a>
      </Col>
    </Row>
  );
}

export default ThumbAds;
