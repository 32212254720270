import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Carousel from "react-bootstrap/Carousel";
import Table from "react-bootstrap/Table";
// import Form from "react-bootstrap/Form";
import axios from "axios";
import {
  addDoc,
  deleteDoc,
  collection,
  doc,
  getDocs,
  setDoc,
  query,
  collectionGroup,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import Container from "react-bootstrap/Container";
import Swal from "sweetalert2";
import dateFormat from "dateformat";
// import CreatorBundles from "../Creators/CreatorBundles";

function TribesCrawler(props) {
  const [state, setState] = useState({});
  const [links, setLinks] = useState([]);
  const [posts, setPosts] = useState([]);
  const [selectedThumb, setSelectedThumb] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showBody, setShowBody] = useState(false);
  const [validated, setValidated] = useState(false);
  const [thisMonth, setThisMonth] = useState([]);
  const [nextMonth, setNextMonth] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      getLinks();
    }
  };
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  // let queryMonth = month.toString() + "_" + year.toString();
  if (year === 2021) {
    year = 2022;
  }
  if (month > 13) {
    month = 2;
  } else if (month > 12) {
    month = 1;
  }
  const loadBundles = async () => {
    // let queryMonth = month.toString() + "_" + year.toString();

    const thisMonthQ = query(
      collectionGroup(db, "bundles"),
      where("month", "==", month.toString() + "_" + year.toString())
    );
    const tmSnapshot = await getDocs(thisMonthQ);
    let bundleArr = [];
    tmSnapshot.forEach((doc) => {
      bundleArr.push(doc.data());
    });
    setThisMonth(bundleArr);
    const nextMonthQ = query(
      collectionGroup(db, "bundles"),
      where("month", "==", (month + 1).toString() + "_" + year.toString())
    );
    const nmSnapshot = await getDocs(nextMonthQ);
    let nmbundleArr = [];
    nmSnapshot.forEach((doc) => {
      nmbundleArr.push(doc.data());
    });
    setNextMonth(nmbundleArr);
    console.log("Number of Bundles: ", bundleArr.length, nmbundleArr.length);
  };
  const getLinks = async () => {
    console.log("get links");
    let tLRef = collection(db, "tribes_links");
    let snapShot = await getDocs(tLRef);
    let linkArr = [];
    snapShot.forEach((doc) => {
      linkArr.push(doc.data());
    });
    setLinks(linkArr);
    savePosts(linkArr);
  };
  const getTribesPosts = async () => {
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];
    snapShot.forEach((doc) => {
      creatorArr.push(doc.data());
    });

    let tribesOnly = creatorArr.filter((c) => c.tribeID);
    console.log("Tribes count: ", tribesOnly.length);
    savePosts(tribesOnly);
  };
  const CreatorBundles = (props) => {
    const { creator } = props;
    console.log("creator", creator);
    let thisMonthBundle = thisMonth.find((b) => {
      return b.bundleID === creator + "_" + month + "_" + year;
    });
    let hasThisMonth = false;
    if (thisMonthBundle) {
      hasThisMonth = true;
    }
    let nextMonthBundle = nextMonth.find(
      (b) => b.bundleID === creator + "_" + (month + 1) + "_" + year
    );
    let hasNextMonth = false;
    if (nextMonthBundle) {
      hasNextMonth = true;
    }
    console.log("thismonh", thisMonthBundle, "nextmonth", nextMonthBundle);
    return (
      <div>
        {hasThisMonth && <><Badge>THIS MONTH</Badge></>}
        {hasNextMonth && <Badge>NEXT MONTH</Badge>}
      </div>
    );
  };

  // console.log("creators", creatorArr);

  const savePosts = async (tribes) => {
    for (let i = 0; i < tribes.length; i++) {
      let tribe = tribes[i];
      console.log("saving", tribe.creator);
      await axios
        .get(`/scraper/tribe/posts/${tribe.tribeID}`)
        .then(async (res) => {
          // await axios.get(`https://myminifactory.com/tribes/${tribe.tribeID}/posts`).then(async (res) => {
          let docRef = doc(db, "new_tribe_posts", tribe.creatorID);
          let posts = res.data;

          posts = posts
            .sort((a, b) => b.id - a.id)
            .filter((p) => {
              return p.id > (tribe?.lastMMFPostScraped || 0);
            });

          if (posts.length > 5) {
            posts = posts.slice(0, 4);
            console.log(posts, posts.slice(0, 4));
          }
          posts = posts.map((p) => {
            return { ...p, creator: tribe.creator, creatorID: tribe.creatorID };
          });
          let docData = {
            id: tribe.creator,
            posts: posts,
            dateScraped: new Date(),
            tribeID: tribe.tribeID,
            creatorID: tribe.creatorID,
          };

          console.log("docData", docData);
          await setDoc(docRef, docData).then(() =>
            console.log("saved: ", tribe.creator)
          );
        });
    }
  };
  const showThumb = (thumbArr) => {
    setSelectedThumb(thumbArr);
    setShowModal(true);
  };
  const getSavedPosts = async () => {
    let postsRef = collection(db, "new_tribe_posts");
    let snapShot = await getDocs(postsRef);
    let arr = [];
    snapShot.forEach((doc) => {
      arr.push(doc.data());
    });
    setPosts(arr);
  };
  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const markScrape = async (post) => {
    console.log("post", post);
    const scrapeRef = doc(db, "mmf_scrape_posts", `${post.id}`);
    let docData = {
      creator: post.creator,
      // id: post.id ,
      title: post.title,
      post: post.direct_link,
      scraped: false,
      ...post,
    };
    await setDoc(scrapeRef, docData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          timer: 400,
          showConfirmButton: false,
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          timer: 200,
          showConfirmButton: false,
        });
      });
    let linkRef = doc(db, "creators", post.creatorID);

    await setDoc(linkRef, { lastMMFPostScraped: post.id }, { merge: true });
  };
  const markScraped = async (post) => {
    console.log("post", post);
    let linkRef = doc(db, "creators", post.creatorID);

    await setDoc(linkRef, { lastMMFPostScraped: post.id }, { merge: true });
    Swal.fire({
      icon: "success",
      title: "",
      text: "",
      timer: 500,
      showConfirmButton: false,
    });
  };
  const removeCreator = async (c) => {
    await deleteDoc(doc(db, "tribe_posts", c.id))
      .then(() => {
        Swal.fire({
          icon: "success",
          timer: 400,
          showConfirmButton: false,
        });
        let postList = [...posts];
        let index = postList.findIndex((cl) => cl.id === c.id);
        postList.splice(index, 1);
        setPosts(postList);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          timer: 200,
          showConfirmButton: false,
        });
      });
  };
  const prepend = async (creatorID, post, tm) => {
    let postMonth = tm ? month : month + 1;
    let bundleID = tm
      ? creatorID + "_" + month + "_" + year
      : creatorID + "_" + (month + 1) + "_" + year;

    let bundleRef = doc(db, "creators", creatorID, "bundles", bundleID);
    let oldBundle;
    const docSnap = await getDoc(bundleRef);
    if (docSnap.exists()) {
      oldBundle = docSnap.data();
    }

    console.log("month ", month);
    let images = post.extras.gallery.map((img) => img.url);
    let title = post.title;
    let thumb = `https://images.myminifactory.com/image/height=500,v=3/${post.extras.gallery[0].url}`;
    let updated = new Date();
    // if (tm) {
    //   oldBundle = thisMonth.find(
    //     (b) => b.bundleID === creatorID + "_" + month + "_" + year
    //   );
    // } else {
    //   oldBundle = nextMonth.find(
    //     (b) => b.bundleID === creatorID + "_" + (month + 1) + "_" + year
    //   );
    // }
    // let oldBundle

    let monthString = postMonth + "_" + year;
    if (oldBundle) {
      oldBundle.thumb = thumb;
      oldBundle.title = title;
      oldBundle.images = [...images, ...oldBundle.images];
      oldBundle.updated = updated;

      setDoc(bundleRef, oldBundle, { merge: true }).then((res) =>
        Swal.fire({ icon: "success", timer: 1000, showConfirmButton: false })
      );
      // markScraped(post);
    } else {
      let creatorRef = doc(db, "creators", creatorID);
      const docSnap = await getDoc(creatorRef);
      if (docSnap.exists()) {
        let data = docSnap.data();
        const { creator, patreon, mmf, website, price, tags } = data;
        let bundleData = {
          patreon: patreon || "",
          creator,
          creatorID,
          mmf: mmf || "",
          website: website || "",
          price: price || "",
          images,
          thumb,
          bundleID,
          month: monthString,
          tags,
          title,
          updated,
          desc: "",
        };

        setDoc(bundleRef, bundleData, { merge: true }).then(() =>
          Swal.fire({
            icon: "success",
            title: "",
            text: "",
            timer: 1000,
            showConfirmButton: false,
          })
        );

        // setCreatorInfo(data);
      } else {
        Swal.fire({
          icon: "Error",
          title: "No bundle to add",
          text: "Maybe check the front page.",
          timer: 1000,
        });
      }
    }
    markScraped(post);

  };
  return (
    <Container>
      <Form noValidate>
        <Button onClick={() => loadBundles()}>Load up bundles first</Button>

        <Button onClick={() => getTribesPosts()}>
          Get Tribes Posts from Creator List
        </Button>
        <Button
          className="mx-1"
          variant="warning"
          onClick={() => getSavedPosts()}
        >
          {" "}
          Get Scraped Posts
        </Button>
      </Form>
      {posts.length > 0 ? (
        <>
          <Table striped bordered responsive size="sm" className="mt-3 mb-5">
            <tbody>
              {posts.map((c) => {
                return (
                  <tr key={c.id} className="d-flex flex-column">
                    {c.id}
                    <CreatorBundles creator={c.creatorID} />
                    <td>
                      <Button
                        variant="dark"
                        onClick={() => removeCreator(c)}
                        className="btn-sm my-2"
                      >
                        Remove Creator
                      </Button>
                    </td>
                    <div>
                      {c.posts.map((p, i) => {
                        if (
                          (p.extras && !p.extras.gallery) ||
                          (p.extras &&
                            p.extras.gallery &&
                            p.extras.gallery.length < 1) ||
                          !p.extras
                        ) {
                          return null;
                        }
                        return (
                          <>
                            <tr
                              key={p.id}
                              className=" d-flex justify-content-between align-items-center rounded-3 text-center border p-3"
                            >
                              <td>
                                {p.extras &&
                                p.extras.gallery &&
                                p.extras.gallery.length > 0 ? (
                                  <Badge
                                    bg="secondary"
                                    className="btn btn-sm m-3"
                                    onClick={() => showThumb(p.extras.gallery)}
                                  >
                                    See Pics
                                  </Badge>
                                ) : (
                                  <Badge bg="danger">No Pics</Badge>
                                )}

                                <p>
                                  {dateFormat(p.createdAt, "paddedShortDate")}
                                </p>
                              </td>

                              <td>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={p.direct_link}
                                >
                                  {p.title}: {p.id}
                                </a>
                              </td>

                              <td>
                                {p.body && p.body.length > 0 ? (
                                  <div>
                                    <Badge
                                      bg="warning"
                                      className="text-dark btn btn-sm"
                                      onClick={() => setShowBody(!showBody)}
                                    >
                                      {showBody ? "Hide Body" : "Show Body"}{" "}
                                    </Badge>
                                  </div>
                                ) : null}
                              </td>

                              <Badge
                                className=" m-3 btn btn-sm "
                                onClick={() =>
                                  markScrape({ ...p, creator: c.id })
                                }
                              >
                                Add Scrape Url
                              </Badge>
                              <Badge
                                bg="success"
                                className=" btn btn-sm"
                                onClick={() => markScraped(p)}
                              >
                                Mark Scraped
                              </Badge>
                              <Badge
                                bg="success"
                                className=" btn btn-sm"
                                onClick={() => prepend(c.creatorID, p, true)}
                              >
                                Prepend this month.
                              </Badge>
                              <Badge
                                bg="success"
                                className=" btn btn-sm"
                                onClick={() => prepend(c.creatorID, p, false)}
                              >
                                Prepend next month.
                              </Badge>
                            </tr>
                            {showBody ? (
                              <Container className="pbody-cont">
                                <p className=" pbody">{p.body}</p>
                              </Container>
                            ) : null}
                          </>
                        );
                      })}
                    </div>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size="xl"
            centered
          >
            <Modal.Body>
              <Carousel>
                {selectedThumb.map((i) => {
                  return (
                    <Carousel.Item>
                      <img className="d-block w-100" src={i.url} alt="" />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </Container>
  );
}

export default TribesCrawler;
