import React from 'react'
import Container from 'react-bootstrap/Container'
import Tracker from './Tracker'
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect } from 'react';
import PageBanner from '../Banner/PageBanner';
function TrackerPage() {
let analytics = getAnalytics()
 useEffect(() => {
   
     logEvent(analytics, 'screen_view', {
         firebase_screen: 'tracker-page'
       });
  }, [])
    return (
        <Container>
           <PageBanner bannerNum={1} />
            <h2 className='text-center'>See which releases are on this site and which ones have yet to be added.</h2>
            <h4 className='text-center'>Clicking on the creator's name will take you to where they post.</h4>
            <Tracker publicMode={true}/>
        </Container>
    )
}

export default TrackerPage
