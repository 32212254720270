import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TribesPosts from "./TribesID";
import axios from "axios";
import {
  doc,
  setDoc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { Button } from "react-bootstrap";
import MMFPosts from "./MMFPosts";
import GetAllTribes from "./GetAllTribes";

function MMFCrawler() {
  const [loaded, setLoaded] = useState(false);
  const [creators, setCreators] = useState();
  useEffect(() => {
    //    loadCreators()
  }, []);
  const loadCreators = async () => {
    setLoaded(false);
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];
    snapShot.forEach((doc) => {
      creatorArr.push(doc.data());
    });
    let mmfOnly = creatorArr.filter((c) => c.mmf && c.mmf.length > 0);
    console.log("creators", creatorArr);
    console.log(creatorArr.length, "only mmf", mmfOnly.length);
    setCreators(mmfOnly);

    setLoaded(true);
  };

  const scanTribesURLS = () => {
    for (let i = 500; i < 1000; i++) {
      console.log("testing: ", i);
      getPosts(i);
    }
  };
  const getPosts = (tribeID) => {
    let tribePostsURL = `https://www.myminifactory.com/tribes/${tribeID}/posts`;
    axios
      .get(tribePostsURL)
      .then((res) => {
        console.log(res.data);
        if (res.data[0]) {
          recordLink(res.data[0], tribeID, tribePostsURL);
        }
      })
      .catch((err) => {
        console.log("No tribe for: ", tribeID);
      });
  };
  const recordLink = async (link, tribeID, tribePostsURL) => {
    let url = link.direct_link;
    let chopped = url.slice(37);
    let index = chopped.indexOf("/");
    let final = chopped.slice(0, index);
    console.log("steps", url, chopped, index, final);
    const linkRef = doc(db, "tribes_links", final);
    await setDoc(
      linkRef,
      {
        id: final,
        creator: final,
        url: url,
        tribeID: tribeID,
        tribePostsURL,
      },
      { merge: true }
    );
  };
  return (
    <Container>
      <Row>
        <Col>
          <GetAllTribes />
        </Col>
      </Row>

   
    </Container>
  );
}

export default MMFCrawler;
