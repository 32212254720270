import React, { useEffect} from "react";
import { ListGroup, Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {Link} from 'react-router-dom'
import { getAnalytics, logEvent } from "firebase/analytics";
import Donate from "./Donate";

function About() {
  const analytics = getAnalytics();
  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'about'
    });
   
  }, [])
  return (
    <Container>
      <Row>
        <Col>
          <hr />
          <h2 className="text-center text-orange">Discover New Bundles & Creators</h2>
          <hr />

          <p>
            
              STL Bundles was created to help people find current and future 3D
              miniature releases. Most releases are found on Patreon or
              MyMiniFactory Tribes. Users can come here to browse, save, and
              search for specific bundles available.{" "}
            
          </p>
          <p>
            <b>
              Our goal is to help you find the bundles you want when they are
              available at their discounted price.
            </b>
          </p>
          <p>You can also compare <Link to='/welcome-packs'>Welcome Packs</Link> from many creators too.</p>
          <p>
          
              {" "}
              Creators can enjoy the fact that they won't be lost amongst the
              crowd. They won't need to rely on users to follow them on social
              media, Patreon, MyMiniFactory, or other websites. They are given
              the opportunity to{" "}
              <Link to='/creator'> upload their own bundles </Link> so their
              information is updated faster than normal.<b> After all, who doesn't
              like free advertising and publicity?
            </b>
          </p>

          <p>
            STL Bundles is a one-man crew and worked on in my spare time. Thank you
            for your patience as I try to bring you a tool you can use
            regularly.
          </p>
          <Donate />

          <h5 className="mt-5">
            <b>
              Questions or suggestions are welcomed and can be sent to{" "}
              <a  href="mailto:contact@stlbundles.com">
                contact@stlbundles.com.
              </a>{" "}
            </b>
          </h5>
          <p>
            Please send an email to{" "}
            <a  href="mailto:contact@stlbundles.com">contact@stlbundles.com.</a>{" "}
            if you do not want your bundles on this website.
          </p>
        </Col>
      </Row>

      {/* <hr />
      <h3 className="text-center text-orange">STL Bundles Plans and Goals</h3>
      <hr />
      <h5 className='text-center'><em>New bundles are being added daily as fast as possible.</em></h5>
      <Row className="border rounded-3 p-3">
        <Col xs="12" md="6" className="my-3">
          <ListGroup>
            <h6>
              <b>Goals in order of intended implementation:</b>
            </h6>
            <ListGroup.Item>
             Filters such as Sci-Fi/Fantasy on each page that contains bundles.
            </ListGroup.Item>
            <ListGroup.Item>
             Work on Creator access and accounts.
            </ListGroup.Item>
            <ListGroup.Item>December Fantasy Bundles Entered</ListGroup.Item>

            <ListGroup.Item>
              December/January SciFi Bundles Entered
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs="12" md="6" className="my-3">
          <ListGroup>
            <h6>
              <b>Recent developments to check out:</b>
            </h6>
            <ListGroup.Item>
              <Link to=''> href="/#/creator">
                Creator accounts to manage their own bundles if desired.
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link to=''> href="/#/search"> Bundle filter by tags.</a>
            </ListGroup.Item>
            <ListGroup.Item>
              Trending, Recent Additions, Creator Specific, and Last Month Pages
              added.
            </ListGroup.Item>
            <ListGroup.Item>
              Users can create an account with email or Google.
            </ListGroup.Item>

            <ListGroup.Item>
              Users can save bundles and view them later after creating an
              account.
            </ListGroup.Item>
            <ListGroup.Item>
              Creators can be added to a mailing list to be notified of the
              upcoming Creator Portal.
            </ListGroup.Item>
            <ListGroup.Item>
              Logged in users can suggest certain creators.
            </ListGroup.Item>
            <ListGroup.Item>More fantasy bundles were added.</ListGroup.Item>
            <ListGroup.Item>
              First posts shared with printing community for feedback. Great
              suggestions.
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row> */}
    </Container>
  );
}

export default About;
