import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import NewBundleForm from "./NewBundleForm";
import {
  doc,
  setDoc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import axios from "axios";
import Swal from "sweetalert2";

function BatchScraper() {
  const [bundles, setBundles] = useState([]);
  const [mBundles, setMBundles] = useState([]);
  const scrape = async () => {
    let scrapeRef = collection(db, "scrape_urls");
    let snapShot = await getDocs(scrapeRef);
    let scrapeArr = [];
    snapShot.forEach((doc) => {
      scrapeArr.push(doc.data());
    });
  
    let promises = [];
    axios.defaults.baseURL = "http://localhost:6543";
    scrapeArr.map(async (s) => {
      let promise = await axios
        .post("/scraper/patreon/", { url: s.url })
        .catch((err) => alert(`${err.status + " " + err.statusText}`));

      promises.push(promise);
    });
    await Promise.allSettled(promises).then((values) => {
      const run = () => {
        console.log("values", values);
      };
      run();
    });
  };
  const getScrapeUrls = async () => {
    let scrapeRef = collection(db, "scrape_urls");
    let snapShot = await getDocs(scrapeRef);
    let scrapeArr = [];
    snapShot.forEach((doc) => {
      scrapeArr.push(doc.data());
    });
    console.log(scrapeArr.sort((a, b) => a.creator.localeCompare(b.creator)));
    let sorted = scrapeArr.sort((a, b) => a.creator.localeCompare(b.creator));
    setBundles(sorted);
  };
  const getMMFScrapePosts = async () => {
    let scrapeRef = collection(db, "mmf_scrape_posts");
    let snapShot = await getDocs(scrapeRef);
    let scrapeArr = [];
    snapShot.forEach((doc) => {
      scrapeArr.push(doc.data());
    });
    let sorted = scrapeArr.sort((a, b) => a.creator.localeCompare(b.creator));
    setMBundles(sorted);
  }
  const removeUrl = async (b) => {
    console.log("removing", b);
    let docRef = doc(db, "scrape_urls", b.id);
    await deleteDoc(docRef)
      .then(() => {
        let bArr = [...bundles];
        let index = bundles.findIndex((doc) => doc.id === b.id);
        console.log("index", index);
        bArr.splice(index, 1);
        Swal.fire({
          icon: "success",
          timer: 400,
          showConfirmButton: false,
        });
        getScrapeUrls();
      })
      .catch((err) => console.log(err));
  };
  const removeMMFPost = async (b) => {
    console.log("removing", b);
    let docRef = doc(db, "mmf_scrape_posts", `${b.id}`);
    await deleteDoc(docRef)
      .then(() => {
        let bArr = [...mBundles];
        let index = mBundles.findIndex((doc) => doc.id === b.id);
        console.log("index", index);
        bArr.splice(index, 1);
        Swal.fire({
          icon: "success",
          timer: 400,
          showConfirmButton: false,
        });
        console.log(bArr)
        setMBundles(bArr)
        // getMMFScrapePosts();
      })
      .catch((err) => console.log(err));
  };
console.log('mbundles', mBundles.length)


  return (
    <div>
      <Button onClick={() => getScrapeUrls()} className="btn-orange">Get Patreon Scraped Posts</Button>
      <Button className='btn-mmf mx-1' onClick={() => getMMFScrapePosts()}>Get MMF Scraped Posts</Button>

      {bundles &&
        bundles.map((b) => {
          return (
            <Row key={b.id}>
              <div>
                <Button
                  className="my-3"
                  variant="danger"
                  onClick={() => removeUrl(b)}
                >
                  Discard
                </Button>
              </div>
              <NewBundleForm removeUrl={removeUrl} bundle={b} callScrape={true} />;
            </Row>
          );
        })}
        {mBundles &&
        mBundles.map((b) => {
          return (
            <Row key={b.id}>
              <div>
                <Button
                  className="my-3"
                  variant="danger"
                  onClick={() => removeMMFPost(b)}
                >
                  Discard
                </Button>
              </div>
              <NewBundleForm removeUrl={removeMMFPost} mBundle={b} />;
            </Row>
          );
        })}
    </div>
  );
}

export default BatchScraper;
