import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ScraperForm from "./ScraperForm";
import TribesCrawler from "./TribesCrawler";
function MyMiniFactoryScraper() {
  const [scrapeURL, setScrapeURL] = useState("");
  const [data, setData] = useState();
  const getData = () => {
    axios.get(scrapeURL).then((res) => {
      console.log("resdata", res.data);
      let filtered = res.data.filter(
        (d) =>
          (d.tags.includes("Gallery") || d.tags.includes("Image") || (d.extras && d.extras.gallery))  &&
          d.firewallData.length < 1
      );
      console.log("filtered", filtered);
      setData(filtered);
    });
  };
  const prepData = (i) => {
    if (data) {
      const title = data[i].title;
      const desc = data[i].body;
      const month = `${new Date(data[i].updatedAt).getMonth() + 1}`;
      let thumb = "";
      const images = [];
      console.log("asdf", data[i].tags.includes("Gallery"));
      thumb = data[i].extras.gallery[0].url;
      data[i].extras.gallery
        .sort((a, b) => a.position - b.position)
        .map((img) => images.push(img.url));

      let bundleData = { title, desc, month, thumb, images };
      console.log("bundleData", bundleData);
      return <ScraperForm scrapedBundle={bundleData} />;
    }
  };

  return (
    <Container>
      <TribesCrawler />
      <Form>
        
        <Form.Group>
          <Form.Label>Scrape Url</Form.Label>
          <Form.Control
            type="text"
            name="scrapeURL"
            value={scrapeURL}
            onChange={(e) => setScrapeURL(e.target.value)}
            placeholder=""
          />
        </Form.Group>
      </Form>
      <Button onClick={getData}>Scrape</Button>
      {data &&
        data.map((d, i) => {
          return prepData(i);
        })}
    </Container>
  );
}

export default MyMiniFactoryScraper;
