import React from "react";

function Facebook() {
  const login = () => {
    window.FB?.login(function (response) {
      // handle the response
      console.log("fb response:");
    });
  };
  const log = () => {
    window.FB.getLoginStatus(({ authResponse }) => {
      console.log("authresponse", authResponse);
    });
  };
  const logout = () => {
    window.FB.logout(({ authResponse }) => {
        console.log("logout", authResponse);
      });
    };
  
  return (
    <div>
      <button onClick={() => login()}>login</button>
      <button onClick={() => log()}>log</button>
      <button onClick={() => logout()}>logout</button>
    </div>
  );
}

export default Facebook;
