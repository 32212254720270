import axios from "axios";
import React, { useState } from "react";
import {
  getDoc,
  doc,
  deleteDoc,
  setDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import Button from "react-bootstrap/Button";

function GetAllTribes() {
  const [aliases, setAliases] = useState();
  axios.defaults.baseURL = "http://localhost:6543";
  const [creators, setCreators] = useState([])
  const getCreators = async () => {
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];
    snapShot.forEach((doc) => {
      creatorArr.push(doc.data());
    });
    setCreators(creatorArr);
  };
  console.log("creators", creators);
  const getTribesJSON = () => {
    let existingTribes = creators.filter((c) => c.tribeID);
    let existingIds = existingTribes.map((e) => e.tribeID);
    console.log(existingIds)
    // getAliases();
    axios.post("/scraper/tribes", {existingIds }).then((res) => {
      console.log("resdata", res.data);
      saveInfo(res.data);
    });
  };
  const fixCreators = async () => {
    let baddies = [
      "admiral_apocalypse",
      "aspan_lohia",
      "fantastic2dminis",
      "christine_van_patten_-_the_rainbow_sculptor",
      "colossalminiatures",
      "dm-stash",
      "goon_master",
      "flesh_of_gods",
      "heriberto_valle_martínez",
      "heroes_&_beasts",
      "jeremy_gosser",
      "legendbuilds",
      "leonardo_escovar",
      "printyourmonsters",
      "props&beyond",
      "realsteone_miniatures",
      "rocket_pig_games_inc",
      "the_dragon_trappers_lodge",
      "orc_king_studio",
      "filippo_giovannini",
      "the_lion's_tower_adventurers_guild",
      "titan_forge_miniatures",
      "valkyries_playground",
      "velrock_art",
      "vesna_sculpts",
      "warbladestudio",
      "world_forge_minis",
      "tagged_events",
      "taras_skorobruh",
    ];

    baddies.map((b) => {
      console.log("deleting", b);
      deleteDoc(doc(db, "creators", b));
    });
  };
  const fixAliases = async () => {
    let aliasRef = collection(db, "creator_aliases");
    let snapShot = await getDocs(aliasRef);
    let alArr = [];
    snapShot.forEach((doc) => {
      alArr.push(doc.data());
    });

    let alias = {};
    alArr.forEach((a, i) => {
      alias = {
        ...alias,
        [a.aliases[2]]: a.realID,
      };
    });
    console.log("alias", alias);
    await setDoc(doc(db, "alias_obj", "aliases"), alias);
    // console.log("creators", creatorArr);
  };
  const getAliases = async () => {
    let aliasObj = await getDoc(doc(db, "alias_obj", "aliases"));
    if (aliasObj.exists()) {
      let data = aliasObj.data();
      setAliases(data);
    } else {
      console.log("No such document!");
    }
  };

  console.log("aliases", aliases);
  const saveInfo = (tribes) => {

    let banned = ["bahabbalam", "darksoundscapes"];
    tribes.map(async (t, i) => {
      if (banned.includes(t.designer.username)) {
        return;
      }
      
      let key = t.designer.name.toLowerCase().replaceAll(" ", "_");
      if(key === 'davale_games_©'){
        key = 'davale_games'
      }
      let creatorID = aliases[key] || key;
      console.log("aliases[key]", aliases[key]);
      let docRef = doc(db, "creators", creatorID);
      let docData = {
        mmf: `https://www.myminifactory.com${t.url}`,
        tribeID: t.id,
        creatorID: creatorID,
        // creator: t.designer.name,
        mmfUsername: t.designer.username,
        smallLogo: t.designer.profilePicUrl,
        tribeData: t.tribeData,
        tribe: true,
      };
      await setDoc(docRef, docData, { merge: true });
      console.log("Saved: ", t.designer.name);
    });
  };
  const fixCGF = async () => {
    let oldCGF;
    let docRef = doc(db, "creators", "crippled_gοd_foundry");
    let old = await getDoc(docRef);
    if (old.exists()) {
      let data = old.data();
      oldCGF = data;
    } else {
      console.log("No such document!");
    }
    console.log("old", oldCGF);

    await setDoc(
      doc(db, "creators", "crippled_god_foundry"),
      {
        ...oldCGF,
        creator: "Crippled God Foundry",
        creatorID: "crippled_god_foundry",
        docID: "crippled_god_foundry",
      },
      { merge: true }
    );

    let creatorRef = doc(db, "creators", "crippled_gοd_foundry");
    let bundlesRef = collection(creatorRef, "bundles");
    let snapShot = await getDocs(bundlesRef);
    snapShot.forEach((d) => {
      let bundle = d.data();
      setDoc(
        doc(
          db,
          "creators",
          "crippled_god_foundry",
          "bundles",
          bundle.bundleID.replace("ο", "o")
        ),
        bundle
      );
    });

    let wpRef = collection(
      db,
      "creators",
      "crippled_gοd_foundry",
      "welcome_packs"
    );
    let wSnapShot = await getDocs(wpRef);
    wSnapShot.forEach((d) => {
      let bundle = d.data();
      setDoc(
        doc(
          db,
          "creators",
          "crippled_god_foundry",
          "welcome_packs",
          bundle.bundleID.replace("ο", "o")
        ),
        bundle
      );
    });
  };

  const startAlias = () => {
    axios.get("/scraper/tribes").then((res) => {
      let tribes = res.data;
      tribes.map(async (t, i) => {
        let docRef = doc(
          db,
          "creator_aliases",
          t.designer.name.toLowerCase().replaceAll(" ", "_")
        );
        let docData = {
          aliases: [
            t.designer.name,
            t.designer.username,
            t.designer.name.toLowerCase().replaceAll(" ", "_"),
            t.designer.name.toLowerCase(),
            t.designer.username.toLowerCase(),
          ],
          realID: t.designer.name.toLowerCase().replaceAll(" ", "_"),
        };
        await setDoc(docRef, docData, { merge: true });
        console.log("Saved: ", t.designer.name);
      });
    });
  };

  return (
    <>
      <Button className="m-1" onClick={() => getAliases()}>
        Always click this: Get Aliases
      </Button>
      <Button onClick={() => getCreators()}>Click before "Get All Tribes" to only get new tribes.</Button>
      <Button disabled={!aliases} className="m-1" onClick={() => getTribesJSON()}>
        Get All Tribes
      </Button>
      <Button onClick={() => fixCreators()}>
        Get Rid of Known Trash Creators
      </Button>
      {/* <Button  className='m-1' onClick={() => fixAliases()}>Fix Alias</Button>
      <Button  className='m-1' onClick={() => fixCGF()}>Fix CCG</Button> */}
    </>
  );
}

export default GetAllTribes;
