import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Badge, Button } from "react-bootstrap";
import {
  collection,
  doc,
  collectionGroup,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import BundleCard from "../Bundle/BundleCard";
import { db } from "../../firebase/config";
import { getAnalytics, logEvent } from "firebase/analytics";

import "./SearchPage.css";

function SearchPage() {
  const [tags, setTags] = useState();
  const [selectedTags, setSelectedTags] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredBundles, setFilteredBundles] = useState([]);
  let analytics = getAnalytics()
  useEffect(() => {
    loadBundles();
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'search'
    });
  }, []);
  useEffect(() => {
    if (filteredBundles.length > 0) {
      extractTags(filteredBundles);
    } else {
      extractTags(bundles);
    }
  }, [filteredBundles.length]);
  let firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      filterByTag();
    }
  }, [selectedTags.length, bundles]);

  const loadBundles = async () => {
    setLoaded(false);
    const normalBundles = query(collectionGroup(db, "bundles"));
    const wpBundles = query(collectionGroup(db, "welcome_packs"));
    const normalQuerySnapshot = await getDocs(normalBundles);
    const wpQuerySnapshot = await getDocs(wpBundles);
    let bundleArr = [];
    normalQuerySnapshot.forEach((doc) => {
      bundleArr.push(doc.data());
    });
    wpQuerySnapshot.forEach((doc) => {
      bundleArr.push(doc.data());
    });

    extractTags(bundleArr);
    setBundles(bundleArr);

    filterByTag();
    setLoaded(true);
  };
  const extractTags = (arr) => {
    let tagArr = [];
    arr.map((b, i) => {
      if(b.tags){

        b.tags.map((t) => {
          if (!tagArr.includes(t.toUpperCase())) {
            tagArr.push(t.toUpperCase());
          }
        });
      }
    });
    let sorted = tagArr.sort((a, b) => a.localeCompare(b));

    setTags(sorted);
  };

  const filterByTag = () => {
    if (selectedTags.length > 0 && bundles) {
      let filtered = bundles.filter((b) => {
        return selectedTags.every((t) => {
          return b.tags.includes(t.toLowerCase());
        });
      });
      setFilteredBundles(filtered);
    } else {
      //   setFilteredBundles(bundles);
      setFilteredBundles([]);
    }
  };

  const handleTagClick = (tag) => {
    if (!selectedTags.includes(tag.toUpperCase())) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      let removed = selectedTags.filter((st) => st !== tag.toUpperCase());
      setSelectedTags(removed);
    }
  };
  const SkeletonArr = () => {
    let skelArr = [];
    for (let index = 0; index < 100; index++) {
      skelArr.push(
        <div
          className={` m-0 skeleton-badge-${
            Math.floor(Math.random() * 100) % 2 > 0 ? "1" : "2"
          } m-1`}
        >
          {" "}
        </div>
      );
    }
    return skelArr;
  };

  return (
    <Container>
      <Row>
        <h5 className="text-center my-3">
          <em className="text-orange ">
            Search all bundles that have been added.
          </em>
        </h5>
      </Row>
      <Row>
        <Col className="text-end">
          <Button
            className={` btn ${
              selectedTags.includes("FANTASY")
                ? "bg-primary text-light"
                : "bg-light text-dark"
            }  border m-1  px-5`}
            onClick={() => handleTagClick("FANTASY")}
          >
            FANTASY
          </Button>
        </Col>
        <Col>
          <Button
            className={` btn ${
              selectedTags.includes("SCIFI")
                ? "bg-primary text-light"
                : "bg-light text-dark"
            }  border m-1 px-5`}
            onClick={() => handleTagClick("SCIFI")}
          >
            SCI-FI
          </Button>
        </Col>
      </Row>
      <Row>
        {loaded ? (
          <Col>
            {tags &&
              tags.map((t) => {
                return (
                  <Badge
                    className={` btn ${
                      selectedTags.includes(t.toUpperCase())
                        ? "bg-primary text-light"
                        : "bg-light text-dark"
                    }  border m-1`}
                    key={t}
                    onClick={() => handleTagClick(t)}
                  >
                    {t}
                  </Badge>
                );
              })}
          </Col>
        ) : (
          <Col className="d-inline-flex flex-wrap align-content-start">
            <SkeletonArr />
          </Col>
        )}
      </Row>
      <Row>
        {bundles &&
          filteredBundles &&
          filteredBundles.map((b, i) => {
            return (
              <Col key={b.bundleID} xs="12" md="6" xl="4">
                <BundleCard searchMode={true} thisBundle={b} />
              </Col>
            );
          })}
      </Row>
    </Container>
  );
}

export default SearchPage;
