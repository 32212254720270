import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import routes from "./routes";
import UserProvider from "./UserContext";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";

function App() {
  const [show, setShow] = useState(false);
  
  return (
    <UserProvider>
      <div className="App">
        <Header />
        {show ? (
          <Container>
            <Alert variant="danger" dismissible onClose={() => setShow(false)}>
              <Alert.Heading>
                <h2><b>Development Speed Bump</b></h2>
              </Alert.Heading>
              <p>
               Unfortunately, Patreon has been cracking down on my attempts to get the creator's information programatically (<em>which allows me to post previews quickly</em>). For the creators that are <b>not</b> on MyMiniFactory Tribes, you can expect a delay as to when I will be able to get their bundle previews up. These bundles will also not feature as many photos.
              </p>
              <p>Sorry for the inconvenience, I'm working on a solution. </p>
            
            </Alert>
          </Container>
        ) : null}
        <main className="pb-5 ">{routes}</main>
        <footer className="bg-dark text-light footer pt-3">
          <h6 className="text-center">STL Bundles</h6>
        </footer>
      </div>
    </UserProvider>
  );
}

export default App;
