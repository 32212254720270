import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { doc, setDoc, collection, getDoc } from "firebase/firestore";
import { db } from "../../firebase/config";

import Swal from "sweetalert2";

function Creator() {
  const [state, setState] = useState({
    email: "",
    creator: "",
    message: '',
  });

  const clearForm = () => {
    setState({ email: "", creator: "" });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    
    let creatorEmailsRef = collection(db, "creator_emails");
    await setDoc(doc(creatorEmailsRef, creatorUnderscored), state).then(() => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Updates will be sent from 'contact@stlbundles.com'. Feel free to reach out at that email with questions.",
      });
      clearForm();
    });
  };
  return (
    <Container>
      <Row >
        <Col xs="12" md="6" className='py-3'>
          <h4>Fill out this form if you would like your bundle featured on the front page and/or talk about advertising prices.</h4>
          <hr/>
          <h5 className="my-2">
            If you are a creator and want to have control over the bundles we
            display and what images are shown, you can do so after a short verification process.
          </h5>
          
          <h5>I look forward to working with you.</h5>
          <h6>
            <b>
              You must be signed in to submit this form (this helps prevent spam
              bots).
            </b>
          </h6>
        </Col>
        <Col xs="12" md="6">
          <Form
            onSubmit={handleSubmit}
            className="bg-secondary p-3 rounded-3 my-3 text-light"
          >
            <Form.Group>
              <Form.Label>Name of Patreon/Tribes</Form.Label>
              <Form.Control
                required
                value={state.creator}
                onChange={(e) =>
                  setState({ ...state, creator: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control
                
                as='textarea'
                value={state.message}
                onChange={(e) => setState({ ...state, message: e.target.value })}
              />
            </Form.Group>
            <Button className="my-2" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Creator;
