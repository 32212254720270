import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { doc, setDoc, collection, getDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import Swal from "sweetalert2";

function Suggest() {
    const [state, setState] = useState({
        creator:''
    })

    
      const clearForm =  () => {
        setState({email: '', creator: ''})
      }
      const handleSubmit = async  (e) => {
          e.preventDefault()
          let creatorUnderscored = state.creator
          ? state.creator.replaceAll(" ", "_").toLowerCase()
          : "default";
    
        
        let lowercase = state.creator.toLowerCase()
        
        
        
        let suggestionsRef = collection(db, "creator_suggestions");
        await setDoc(doc(suggestionsRef, creatorUnderscored), {creator: lowercase}).then(() => {
            Swal.fire({ icon: "success", text: "Suggestion recorded. Thank you!", timer: 2000, showConfirmButton: false});
            clearForm();
          }).catch(err => {

          })
      }
 
  return (
    <Container>
      <Row className='my-4'>
        <Col xs="12" md="6" className='my-3'>
          <h5>
            Being that this is a work in progress, suggestions of creators that
            have yet to be included are welcomed.
          </h5>
          <h5>Please fill out this form and submit it, I will try to get them added as soon as possible.</h5>
          <h5><b>You must be logged in to submit suggestions.</b></h5>
          <h6>(This helps prevents spam bots.)</h6>
          <h5>Thank you!</h5>
        </Col>
        <Col xs="12" md="6">
          <Form
           
            onSubmit={handleSubmit}
            className="bg-secondary p-3 rounded-3 my-3 text-light"
          >
            <Form.Group>
              <Form.Label>Name of Patreon</Form.Label>
              <Form.Control
              pattern=".{5,}"
                required
                value={state.creator}
                onChange={(e) =>
                  setState({ creator: e.target.value })
                }
              />
            </Form.Group>
            <Button className="my-2" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Suggest;
