import React, { useEffect, useState, lazy, Suspense } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useUser } from "../../UserContext";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./Header.css";
import Nav from "react-bootstrap/Nav";
import {
  getAuth,
  // signInWithPopup,
  // GoogleAuthProvider,
  // setPersistence,
  // browserLocalPersistence,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {
  doc,
  getDoc,
  //  setDoc,
  collection,
} from "firebase/firestore";
import { db } from "../../firebase/config";
const Login = lazy(() => import("../Login/Login"));

function Header(props) {
  let user = useUser();
  let navigate = useNavigate();
  let location = useLocation();
  const [userRole, setUserRole] = useState("user");
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  
  useEffect(() => {
    checkUser();
  }, []);

  const auth = getAuth();

  const getUserInfo = async (uid) => {
    const usersRef = collection(db, "users");
    const userRef = doc(usersRef, uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      user.setCurrentUser(userSnap.data());
    } else {
      console.log("No user found.");
    }
  };

  const logout = () => {
    signOut(auth)
      .then((res) => {
        // console.log('logged out', res.data)
        navigate("/");
      })
      .catch((err) => console.log(err));
    user.setCurrentUser({});
    setLoggedIn(false);

    // user.setCurrentUser({});
    // props.history.push("/");
  };
  const checkUser = () => {
    onAuthStateChanged(auth, async (userData) => {
      if (!userData) {
        setLoggedIn(false);
        setUserRole("user");
      } else {
        if (userData.uid === "2KPar4PblhcxNbLa7pzsnW9wsds2") {
          setUserRole("admin");
        } else {
          getUserInfo(userData.uid);
        }
        setLoggedIn(true);
      }
    });
  };
  const routeToAccount = () => {
    if (userRole === "admin") {
      navigate("admin");
    } else {
      setUserRole("user");
      navigate("account");
    }
  };
  return (
    <Navbar collapseOnSelect expand="lg" className='bg-header' style={{fontWeight: 'bold', fontSize: 'large'}} variant="dark">
      <Container>
        <Link className="header-links text-light" to="/">
          <Navbar.Brand>
            <img height="100" src="/images/bluelogo.png" alt="" />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              className={
                location.pathname === "/this-month" ? "" : "header-link"
              }
            >
              <Link className="header-links text-light" to="/this-month">
                This Month{" "}
              </Link>
            </Nav.Link>

            <Nav.Link>
              <Link className="header-links text-light" to="/next-month">
                Next Month{" "}
              </Link>
            </Nav.Link>

            <Nav.Link>
              {" "}
              <Link className="header-links text-light" to="/creators">
                Creators
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link className="header-links text-light" to="/welcome-packs">
                Welcome Packs
              </Link>
            </Nav.Link>
          

            <NavDropdown title="More">
              {/* <Link className='header-links text-dark' to="/search"><div className='ps-2 mb-1' className='ps-2 mb-1'>Search All</div> </Link> */}

              <Link className="header-links text-dark" to="/last-month">
                <div className="ps-2 mb-2">Last Month </div>
              </Link>
              {/* <Link className="header-links text-dark" to="/tracker">
                <div className="ps-2 mb-2">Release Tracker</div>
              </Link> */}
              <Link className="header-links text-dark" to="/recents">
                <div className="ps-2 mb-2">Recent Additions </div>
              </Link>

              <Link className="header-links text-dark" to="/trending">
                <div className="ps-2 mb-2">Trending Bundles </div>
              </Link>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link>
              <Link className="header-links text-light" to="/about">
                About
              </Link>
            </Nav.Link>

            {loggedIn ? (
              <>
                <Nav.Link
                  className="text-light header-links"
                  onClick={() => routeToAccount()}
                >
                  Your Account
                </Nav.Link>
                <Nav.Link
                  className="text-light header-links"
                  onClick={() => logout()}
                >
                  Sign Out
                </Nav.Link>
              </>
            ) : (
              <Nav.Link
                className="text-light header-links"
                onClick={() => setLoginModal(true)}
              >
                Sign In
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Modal
        show={loginModal}
        onHide={() => setLoginModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Suspense fallback={<div></div>}>
          <Login
            setLoggedIn={setLoggedIn}
            setLoginModal={setLoginModal}
            loginModal={loginModal}
          />
        </Suspense>
      </Modal>
    </Navbar>
  );
}

export default Header;
