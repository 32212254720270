import React, { useState, useEffect } from "react";
import { Form, Button, InputGroup, Row,Col } from "react-bootstrap";
import { doc, setDoc, collection, getDoc, getDocs} from "firebase/firestore";
import { db } from "../../firebase/config";
import Upload from "../Upload/Upload";
import Swal from "sweetalert2";
import BundleCard from "../Bundle/BundleCard";
function ScraperForm(props) {
    useEffect(()=> {
        if(props.scrapedBundle){
            setState({...state, ...props.scrapedBundle})
            setImages(props.scrapedBundle.images)
        }
    },[])
  let d = new Date();
  const [images, setImages] = useState([""]);
  const [tags, setTags] = useState([""]);
  const [nextYear, setNextYear] = useState(false);
  const [state, setState] = useState({
    creator: "",
    title: "",
    desc: "",
    thumb: "",
    patreon: "",
    mmf: "",
    website: "",
    price: "",
    month: d.getMonth() + 1,
  });

  const loadCreator = async () => {
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";
    let creatorRef = doc(db, "creators", creatorUnderscored);
    const docSnap = await getDoc(creatorRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const { patreon, mmf, website, price } = data;
      setState({ ...state, patreon, mmf, website, price });
      console.log("Document data:", docSnap.data());
    } else {
     
      let oldUnderscore = state.creator.replace(" ", "_").toLowerCase()
      console.log(oldUnderscore)
      let oldDocRef = doc(db, "creators", oldUnderscore) 
      const old = await getDoc(oldDocRef);
      if (old.exists()) {
        let data = old.data();
        const { patreon, mmf, website,price } = data;
        setState({ ...state, patreon, mmf, website, price });
        
      } else {
        console.log("No such document!");
      }
    }
  };

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleImageInput = (e, i) => {
    let imgArr = [...images];
    let img = e.target.value;
    imgArr[i] = img;
    setImages([...imgArr, ""]);
  };
  const handleTagInput = (e, i) => {
    let tagArr = [...tags];
    let tag = e.target.value;
    tagArr[i] = tag;
    setTags(tagArr);
  };
  const deleteImage = (i) => {
    let imgArr = [...images];
    imgArr.splice(i, 1);
    setImages(imgArr);
  };
  let year = d.getFullYear();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  console.log("state", state, "images", images);
  const addBundle = async (e) => {
    e.preventDefault();
    if (nextYear) {
      year += 1;
    }
    let bundleMonth = state.month.toString() + "_" + year.toString();
    // let creatorNoSpaces = state.creator.replace(" ", "");
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";

    let docId = creatorUnderscored + "_" + bundleMonth;
    const bundlesRef = collection(
      db,
      "creators",
      creatorUnderscored,
      "bundles"
    );

    let cleanedImgs = images.filter((i) => i !== "");
    let cleanedTags = tags.filter((i) => i !== "");
    const bundleData = {
      ...state,
      month: bundleMonth,
      images: cleanedImgs,
      tags: cleanedTags,
      bundleID: docId,
      updated: new Date()
    };
    let creatorsRef = collection(db, "creators");
    let creatorInfo = {
      creator: state.creator,
      patreon: state.patreon,
      mmf: state.mmf,
      website: state.website,
      price: state.price
    };
    await setDoc(doc(creatorsRef, creatorUnderscored), creatorInfo);
    // console.log(bundleMonth, creatorNoSpaces, docId);
    await setDoc(doc(bundlesRef, docId), bundleData).then(() => {
      Swal.fire({ icon: "success", timer: 1000, showConfirmButton: false });
      clearForm();
    });
  };
  const addUploadedImage = (url) => {
    if (images.length === 1) {
      setImages([url, ""]);
    } else {
      setImages([...images, url]);
    }
  };
  const clearForm = () => {
    setState({
      creator: "",
      title: "",
      desc: "",
      thumb: "",
      patreon: "",
      mmf: "",
      website: "",
      price: '',
      month: d.getMonth() + 1,
    });
    setImages([""]);
    setTags([""]);
  };
  const setThumbUrl = (url) => {
    setState({ ...state, thumb: url });
  };
  const retreiveBundle = async () => {
    let bundleMonth = state.month.toString() + "_" + year.toString();
    
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";

    let docId = creatorUnderscored + "_" + bundleMonth;
    let bundleRef = doc(db, "creators", creatorUnderscored, "bundles", docId);
    const docSnap = await getDoc(bundleRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const {
        creator,
        patreon,
        mmf,
        website,
        desc,
        title,
        thumb,
        images,
        tags,
        price
      } = data;
      setState({ ...state, patreon, mmf, website: website ? website : '', desc, title, thumb,price });
      setImages(images);
      setTags(tags);
      console.log("Document data:", docSnap.data());
    } else {
      let oldCreatorUnderscored =  state.creator.replace(" ", "_").toLowerCase()
      let oldDocId = oldCreatorUnderscored + "_" + bundleMonth;
      let oldDocRef = doc(db, "creators",oldCreatorUnderscored,"bundles", oldDocId) 
      const old = await getDoc(oldDocRef);
      if (old.exists()) {
        let data = old.data();
        const { creator,
          patreon,
          mmf,
          website,
          desc,
          title,
          thumb,
          images,
          tags, price } = data;
        setState({  ...state, patreon, mmf, website, desc, title, thumb,price });
        setImages(images);
      setTags(tags);
        console.log("Document data:", old.data());
      } else {
        console.log("No such document!");
      }
    }
  };
   const retreiveBundleRequest =async  () => {
    let bundleMonth = state.month.toString() + "_" + year.toString();
    
    let creatorUnderscored = state.creator
      ? state.creator.replaceAll(" ", "_").toLowerCase()
      : "default";

    let docId = creatorUnderscored + "_" + bundleMonth;
    let bundleRef = doc(db, "creator_bundle_requests", creatorUnderscored, "bundle_requests", docId);
    const docSnap = await getDoc(bundleRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      const {
        creator,
        patreon,
        mmf,
        website,
        desc,
        title,
        thumb,
        images,
        tags,
        price
      } = data;
      setState({ ...state, patreon, mmf, website: website ? website : '', desc, title, thumb,price });
      setImages(images);
      setTags(tags);
      console.log("Document data:", docSnap.data());
    } else {
      console.log('No such document!')
    }
  }
  return (
    <Form
      onSubmit={addBundle}
      className="bg-dark p-3 text-light mt-2 rounded-3"
    >
        <h4>Bundle Preview</h4>
        <Row className='d-flex justify-content-center'><Col xs='12' md='8' lg='6'>
        <BundleCard previewMode={true} thisBundle={{...state,images, tags}} />
        </Col></Row>
      <h5>Add a Bundle</h5>
      <Form.Group>
        <Form.Label>Creator</Form.Label>
        <InputGroup>
          <Form.Control
            onChange={handleInput}
            value={state.creator}
            name="creator"
            type="text"
            placeholder="Capitalize First Letters"
            required
          />
          <InputGroup.Text>
            <Button onClick={() => loadCreator()}>Check</Button>
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.Label>Price</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.price}
          name="price"
          type="number"
          placeholder="0"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Patreon</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.patreon}
          name="patreon"
          type="text"
          placeholder="URL"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>My Mini Factory</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.mmf}
          name="mmf"
          type="text"
          placeholder="URL"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Website</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.website}
          name="website"
          type="text"
          placeholder="URL"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Month</Form.Label>

        <Form.Select
          onChange={handleInput}
          value={state.month}
          name="month"
          type="text"
        >
          {months.map((m, i) => {
            return <option value={i + 1}>{m}</option>;
          })}
        </Form.Select>
      </Form.Group>

      <Button
        className="m-1 btn-sm"
        onClick={() => setNextYear(!nextYear)}
        variant={nextYear ? "danger" : "info"}
      >
        {nextYear ? "Next Year" : "This Year"}
      </Button>
      <Button
        className="m-1 btn-sm"
        onClick={() => retreiveBundle()}
        variant="success"
      >
        Get Bundle
      </Button>
      <Button
        className="m-1 btn-sm"
        onClick={() => retreiveBundleRequest()}
        variant="warning"
      >
        Get Bundle Request
      </Button>
      <Form.Group className="border border-light rounded-3 p-1 ">
        <Form.Group>
          <Form.Label>Thumbnail</Form.Label>
          <Form.Control
            onChange={handleInput}
            className="my-1"
            type="text"
            value={state.thumb}
            name="thumb"
            required
            placeholder="img.url/here"
          />
          <p>or</p>
          <Upload setUrl={setThumbUrl} />
        </Form.Group>
      </Form.Group>
      <Form.Group className="border border-light rounded-3 p-1 my-1 ">
        <Form.Label>Images</Form.Label>
        {images.map((img, i) => {
          return (
            <Form.Control
              onChange={(e) => handleImageInput(e, i)}
              className="my-1"
              key={i}
              type="text"
              value={img}
              name={i}
              placeholder="img.url/here"
            />
          );
        })}
        <Button
          variant="warning"
          className="btn-sm my-1"
          onClick={() => setImages([...images, ""])}
        >
          Add Image
        </Button>
        <Form.Group>
          <Form.Label>Upload Images</Form.Label>
          <Upload setUrl={addUploadedImage} />
        </Form.Group>
      </Form.Group>
      <Form.Group className="border border-light rounded-3 p-1 my-1 ">
        <Form.Label>Tags</Form.Label>
        {tags.map((tag, i) => {
          return (
            <Form.Control
              onChange={(e) => handleTagInput(e, i)}
              className="my-1"
              key={i}
              type="text"
              value={tag}
              name={i}
              placeholder="tag"
            />
          );
        })}
        <Button
          variant="warning"
          className="btn-sm my-1"
          onClick={() => setTags([...tags, ""])}
        >
          Add Tag
        </Button>
      </Form.Group>
      <Form.Group>
        <Form.Label>Title</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.title}
          name="title"
          type="text"
          required
          placeholder="Title of Bundle Here"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={state.desc}
          name="desc"
          as="textarea"
          placeholder="Description"
          rows="2"
        />
      </Form.Group>
      <Button type="submit" className="m-2">
        Add Bundle
      </Button>
      <Button onClick={() => clearForm()} variant="danger" className="m-2">
        Clear
      </Button>
    </Form>
  );
}

export default ScraperForm;
