import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { collection, getDocs, doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/config";

function CoolStats() {
  const [prevStats, setPrevStats] = useState();
  useEffect(() => {
    loadPrevStats();
  }, []);
  const loadPrevStats = async () => {
    const docSnap = await getDoc(doc(db, "cool_stats", "cool_stats"));
    if (docSnap.exists()) {
      let data = docSnap.data();
      setPrevStats(data);
    } else {
      console.log("No such document!");
    }
  };
  const getMarketCap = async () => {
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];
    snapShot.forEach((doc) => {
      let c = doc.data();
      let subs = 1;

      if (c.patrons) {
        subs = c.patrons;
        if (c.tribeData && c.tribeData.total_tribe_subscriptions) {
          subs += c.tribeData.total_tribe_subscriptions;
        }
      } else if (c.tribeData && c.tribeData.total_tribe_subscriptions) {
        subs = c.tribeData.total_tribe_subscriptions;
      }
      let price;
      if (!c.price || c.price < 1) {
        price = 1;
      } else {
        price = c.price;
      }
      if (c.creator === "Loot Studios") {
        subs = 5000;
      }
      if (subs < 1) {
        subs = 1;
      }
      let rev = parseFloat(subs) * parseFloat(price);

      creatorArr.push({ ...c, subs: subs, price: price, rev });
    });
    let total = creatorArr.reduce((prev, curr) => {
      return prev + parseFloat(curr.price) * parseFloat(curr.subs);
    }, 0);
    let subCount = creatorArr.reduce((prev, curr) => {
      return prev + parseInt(curr.subs);
    }, 0);


    let creatorShareArr = [];
    let creatorStats = () => {
      creatorArr.forEach((c) => {
        console.log("c", c);
        let share = parseFloat(c.rev) / parseFloat(total);
        let subShare = parseFloat(c.subs) / parseFloat(subCount);
        console.log(
          "saving",
          c.creator,
          ":",
          "rev: ",
          c.rev,
          "share: ",
          share,
          "subshare: ",
          subShare
        );
        // creatorShareObj = {
        //   ...creatorShareObj,
        //   [c.creatorID]: {
        //     creator: c.creator,
        //     market_share: share,
        //     sub_share: subShare,
        //     revenue: c.rev,
        //     subs: c.subs,
        //   },
        // };
        creatorShareArr.push({
          creator: c.creator,
          market_share: share,
          sub_share: subShare,
          revenue: c.rev,
          subs: c.subs,
        });
        // setDoc(
        //   doc(db, "creators", c.creatorID),
        //   {
        //     creator: c.creator,
        //     stats: [
        //       ...c.stats || "",
        //       {
        //         market_share: share,
        //         sub_share: subShare,
        //         revenue: c.rev,
        //         subs: c.subs,
        //         recorded: new Date(),
        //       },
        //     ],
        //     market_share: share,
        //     sub_share: subShare,
        //     revenue: c.rev,
        //     subs: c.subs,
        //   },
        //   { merge: true }
        // );
      });
      
    };

    console.log("total", total, subCount);
    creatorStats();

    await setDoc(
      doc(db, "cool_stats", "cool_stats"),
      {
        creatorShareArr ,
        history: [
          ...prevStats.history || "",
          {

            market_cap: total,
            sub_count: subCount,
            creator_count: creatorArr.length,
            updated: new Date(),
          },
        ],
        market_cap: total,
        sub_count: subCount,
        creator_count: creatorArr.length,
        updated: new Date(),
      },
      { merge: true }
    );

  };
  const clearCreatorStats = async () => {
    let creatorsRef = collection(db, "creators");
    let snapShot = await getDocs(creatorsRef);
    let creatorArr = [];
    snapShot.forEach((doc) => {
      let data = doc.data()
      creatorArr.push(data)
    })
    
    creatorArr.forEach(c => {
      setDoc(doc(db, "creators", c.creatorID), {stats: []}, {merge: true})
    })
  }
  return (
    <Container>
      <Button onClick={() => getMarketCap()}>Get Market Cap</Button>
      <Button variant='danger' className='mx-2' onClick={() => clearCreatorStats()}>Clear Creator Stats</Button>
    </Container>
  );
}

export default CoolStats;
