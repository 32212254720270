import { getAnalytics, logEvent } from 'firebase/analytics';
import React from 'react'
import { useEffect } from 'react';
import { Container } from 'react-bootstrap'
import Trending from './Trending'
function TrendingPage() {
    let analytics = getAnalytics()
     useEffect(() => {
         logEvent(analytics, 'screen_view', {
             firebase_screen: 'trending'
            });
       
      }, [])
    return (
        <Container>
            <hr />
            <h1 className='text-center'>Trending Bundles this Month</h1>
            <h3 className='text-center'>Bundles are ranked from the amount of saves they have received.</h3>
            <hr />
            <Trending limitAmount={50} />
        </Container>
    )
}

export default TrendingPage
