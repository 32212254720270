import React, { useState, useEffect,lazy } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BundleCard from "../Bundle/BundleCard";

import {
    limit,
    collectionGroup,
    query,
    orderBy,
    getDocs,
    where
} from "firebase/firestore";
import { db } from "../../firebase/config";
import NewBundleCard from "../Bundle/NewBundleCard";

function Trending(props) {
  const d = new Date();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  let queryMonth = month.toString() + "_" + year.toString();
  const {limitAmount} = props
  const [trending, setTrending] = useState();
  console.log('queryMonth', queryMonth)
  useEffect(() => {
    loadTrending();
    
  }, []);
  
 
 
  const loadTrending = async () => {
    const trend = query(
      collectionGroup(db, "bundles"),
      where("month", "==", queryMonth),
      orderBy("saves", "desc"),
      limit(limitAmount))

    const querySnapshot = await getDocs(trend);
    let bundleArr = [];
    querySnapshot.forEach((doc) => {
      bundleArr.push(doc.data());
    });
    setTrending(bundleArr);
  };
  return (
    <Row>
      {trending &&
        trending.map((b) => {
          return (
            <Col key={b.bundleID} xs="12" md="6" xl="4">
              <NewBundleCard thisBundle={b} />
            </Col>
          );
        })}
    </Row>
  );
}

export default Trending;
