import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import PageBanner from "../Banner/PageBanner";
import {

  collectionGroup,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import BundleCard from "../Bundle/BundleCard";
import { getAnalytics, logEvent } from "firebase/analytics";
import NewBundleCard from "../Bundle/NewBundleCard";

let monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const d = new Date();
let month = d.getMonth() + 2;
let year = d.getFullYear();
if(month === 13){
  year += 1
  month = 1
}
let nextMonthName= monthNames[month-1]
function NextMonth() {
  const [bundles, setBundles] = useState([]);
  const [tags, setTags] = useState();
  const [selectedTags, setSelectedTags] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredBundles, setFilteredBundles] = useState([]);
  const [sortMethod, setSortMethod] = useState("newest");
  // const [monthName, setMonthName] = useState(nextMonthName)
  useEffect(() => {
    if (filteredBundles.length > 0) {
      sort();
    }
  }, [sortMethod, filteredBundles.length]);
  const sort = () => {
    console.log(sortMethod);
    let sortArr = [...filteredBundles];
    switch (sortMethod) {
      case "newest":
        sortArr = sortArr.sort(
          (a, b) => b.updated.toDate() - a.updated.toDate()
        );

        setFilteredBundles(sortArr);
        break;
      case "oldest":
        sortArr = sortArr.sort(
          (a, b) => a.updated.toDate() - b.updated.toDate()
        );

        setFilteredBundles(sortArr);
        break;
      case "a":
        sortArr = sortArr.sort((a, b) => a.creator.localeCompare(b.creator));

        setFilteredBundles(sortArr);
        break;
      case "z":
        sortArr = sortArr.sort((a, b) => b.creator.localeCompare(a.creator));

        setFilteredBundles(sortArr);
        break;
      case "low":
        sortArr = sortArr.sort(
          (a, b) => parseFloat(a.price) - parseFloat(b.price)
        );

        setFilteredBundles(sortArr);
        break;
      case "high":
        sortArr = sortArr.sort(
          (a, b) => parseFloat(b.price) - parseFloat(a.price)
        );

        setFilteredBundles(sortArr);
        break;

      default:
        sortArr = sortArr.sort(
          (a, b) => b.updated.toDate() - a.updated.toDate()
        );

        setFilteredBundles(sortArr);
        break;
    }
  };
  let analytics = getAnalytics();
  let firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      filterByTag();
    }
  }, [selectedTags.length, bundles]);

  
  useEffect(() => {
    if (filteredBundles.length > 0) {
      extractTags(filteredBundles);
    } else {
      extractTags(bundles);
    }
  }, [filteredBundles.length]);


 

  useEffect(() => {
    loadBundles();
    logEvent(analytics, "screen_view", {
      firebase_screen: "next-month",
    });
 
  }, []);

  const loadBundles = async () => {
    if (year === 2021) {
      year = 2022;
    }
    if (month > 13) {
      month = 2;
    } else if (month > 12) {
      month = 1;
    }
    let queryMonth = month.toString() + "_" + year.toString();

    const thisMonth = query(
      collectionGroup(db, "bundles"),
      where("month", "==", queryMonth)
    );
    const querySnapshot = await getDocs(thisMonth);
    let bundleArr = [];
    querySnapshot.forEach((doc) => {
      bundleArr.push(doc.data());
    });
    extractTags(bundleArr);
    setBundles(bundleArr);
    console.log("Number of Bundles: ", bundleArr.length);

    filterByTag();
    setLoaded(true);
  };
  const extractTags = (arr) => {
    let tagArr = [];
    arr.map((b, i) => {
      b.tags.map((t) => {
        if (!tagArr.includes(t.toUpperCase())) {
          tagArr.push(t.toUpperCase());
        }
      });
    });
    let sorted = tagArr.sort((a, b) => a.localeCompare(b));

    setTags(sorted);
  };
  const filterByTag = () => {
    if (selectedTags.length > 0 && bundles) {
      let filtered = bundles.filter((b) => {
        return selectedTags.every((t) => {
          return b.tags.includes(t.toLowerCase());
        });
      });
      setFilteredBundles(filtered);
    } else {
      setFilteredBundles(bundles);
      // setFilteredBundles([]);
    }
  };
  const handleTagClick = (tag) => {
    if (!selectedTags.includes(tag.toUpperCase())) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      let removed = selectedTags.filter((st) => st !== tag.toUpperCase());
      setSelectedTags(removed);
    }
  };
  const SkeletonArr = () => {
    let skelArr = [];
    for (let index = 0; index < 100; index++) {
      skelArr.push(
        <div
          className={` m-0 skeleton-badge-${
            Math.floor(Math.random() * 100) % 2 > 0 ? "1" : "2"
          } m-1`}
        >
          {" "}
        </div>
      );
    }
    return skelArr;
  };

  return (
    <Container>
      <PageBanner bannerNum={1} />
      <Row>
        {bundles.length > 0 ? (
          <h3 className=" text-center my-3">
            <b className="text-orange">{bundles.length} </b>bundle previews for
            next month!
          </h3>
        ) : null}
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs="12" sm="7" lg="3">
          <Form.Select onChange={(e) => setSortMethod(e.target.value)}>
            <option value="newest">Newest to Oldest</option>
            <option value="oldest">Oldest to Newest</option>
            <option value="a">A - Z</option>
            <option value="z">Z - A</option>
            <option value="low">$ Low to High</option>
            <option value="high">$ High to Low</option>
          </Form.Select>
        </Col>
        <Col xs="6" lg="4" className="text-end">
          <Button
            className={` btn ${
              selectedTags.includes("FANTASY")
                ? "bg-primary text-light"
                : "bg-light text-dark"
            }  border m-1  px-5`}
            onClick={() => handleTagClick("FANTASY")}
          >
            FANTASY
          </Button>
        </Col>
        <Col xs="6" lg="5">
          <Button
            className={` btn ${
              selectedTags.includes("SCIFI")
                ? "bg-primary text-light"
                : "bg-light text-dark"
            }  border m-1 px-5`}
            onClick={() => handleTagClick("SCIFI")}
          >
            SCI-FI
          </Button>
        </Col>
      </Row>
      {/* <Row>
        {loaded ? (
          <Col>
            {tags &&
              tags.map((t) => {
                return (
                  <Badge
                    className={` btn ${
                      selectedTags.includes(t.toUpperCase())
                        ? "bg-primary text-light"
                        : "bg-light text-dark"
                    }  border m-1`}
                    key={t}
                    onClick={() => handleTagClick(t)}
                  >
                    {t}
                  </Badge>
                );
              })}
          </Col>
        ) : (
          <Col className="d-inline-flex flex-wrap align-content-start">
            <SkeletonArr />
          </Col>
        )}
      </Row> */}
      <Row>
        {bundles && filteredBundles && filteredBundles.length > 0 ? (
          <>
            {filteredBundles.map((b) => {
              return (
                <Col key={b.creator} xs="12" md="6" xl="4">
                  <NewBundleCard thisBundle={b} />
                </Col>
              );
            })}
          </>
        ) : bundles && filteredBundles ? (
          <h2 className="text-center">
            No bundle previews are available for {nextMonthName}{" "}{year} yet.
          </h2>
        ) : (
          <Container className="main-content d-flex justify-content-center">
            <Spinner animation="grow" />
          </Container>
        )}
      </Row>
    </Container>
  );
}

export default NextMonth;
